/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { FILEURL, EPGREPORTTYPE, ENTITYNAME, OPERATORS, LOCALSTORAGE_KEY, MODULETYPES, SCHEDULETYPE, REPORT_TYPE } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";


export const ExportEPGReport = (props) => {

    const defaultDates = utility.getFirstAndLastDateOfMonth();
    const blankDataItem = { FromDate: defaultDates.FirstDate, ToDate: defaultDates.LastDate, ModuleType: {}, ReportType: EPGREPORTTYPE[0], dateWiseSheet: false}
    const [dataItem, setDataItem] = useState(blankDataItem);
    const navigate = useNavigate();
    const [exportReport, setExportReportName] = useState([]);
    const [filterGrid, setFilterGrid] = useState([]);
    const [dataColumns, setDataColumns] = useState([]);
    const [channel, setChannel] = useState([]);
    const [filterInputType, setFilterInputType] = useState('input');
    const [footerMessage, setFooterMessage] = useState('');
    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var exportReportRes = await API.getData(ENTITYNAME.ExportTemplate, { sort: { Name: 1 }, query: ['ModuleType.SID', '=', MODULETYPES[6].SID] });
        console.log(exportReportRes);
        setExportReportName(exportReportRes.data);
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
    }

    const isValidforExport = () => {

        if (dataItem.ReportType == undefined || Object.keys(dataItem.ReportType).length == 0) {
            toast.error(`${lang.please_select_the_value_in_format_first_export_report_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.ReportType.TemplateReq && (dataItem.ExportReport == undefined || Object.keys(dataItem.ExportReport).length == 0)) {
            toast.error(`${lang.please_select_the_value_in_template_name_first_export_report_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }


        if (dataItem.FromDate == undefined || dataItem.FromDate.length == 0) {
            toast.error(`${lang.please_select_from_date}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.ToDate == undefined || dataItem.ToDate.length == 0) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if(dataItem.FromDate >= dataItem.ToDate){
            toast.error(`${lang.to_date_should_be_greater_than_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.Channel == undefined || Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const loadAndExportData = async () => {

        const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
        const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
        const offsetTime = offSetHours * 3600000;

        var company = utility.getValue('company')
        var fromDate = new Date(dataItem.FromDate).getTime() + offsetTime;
        var toDate = new Date(dataItem.ToDate).getTime() + offsetTime + ((24 * 3600000)-1); // adding 1 day extra in to date to cover complete date


        if (isValidforExport()) {
            setFooterMessage(lang.please_wait_export_report_message);
            var payload = dataItem.ReportType.TemplateReq
                ? {
                    reportType: dataItem.ReportType.Description,
                    param: {
                        sort: { SlotDateTime: 1 },
                        tableName: dataItem.ExportReport.ModuleType.SaveEntityName,
                        query: [['SlotDateTime', '>=', fromDate], ['SlotDateTime', '<=', toDate], ['Channel.SID', '=', dataItem.Channel.SID], ['Type', '=', SCHEDULETYPE.Header]],
                        customColumns: dataItem.ExportReport.Columns,
                        companyTimeZone : company?.TimeZone,
                        dateWiseSheet: dataItem.dateWiseSheet ?? false
                    }
                }
                : {
                    param: {
                        query: {
                            fromDate: fromDate,
                            toDate: toDate,
                            channelSID: dataItem.Channel.SID
                        }
                    }
                }
            console.log(payload);

            // Switching based on fixed types and generic format
            var res = {};
            switch (dataItem.ReportType.Description) {
                case "SAMSUNG":
                    res = await API.exportSamsungEPG(payload);
                    break;
                case "TOOBER":
                    res = await API.exportTooberEPG(payload);
                    break;

                default:
                    res = await API.getReport(payload);
                    break;
            }

            console.log(res);

            if (res.success) {
                setTimeout(() => {
                    window.open(FILEURL.BASEURL + 'downloadReport/' + res.data);
                    toast.success(lang.report_is_exported_successfully_message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    setFooterMessage(lang.report_is_exported_successfully_message);
                    setFilterGrid([]);
                    setDataColumns([]);
                }, 5000);
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                setFooterMessage('');
            }
        }

    }

    const onChange = async (e) => {

        if (e.target.name == 'ExportReport') {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
            let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
            let columns = await API.getFormData(e.target.value.ModuleType.EntityName, currentUserId);
            setDataColumns(columns);
            setFilterGrid([]);
        } else if (e.target.name == 'Key') {
            setFilterInputType(e.target.value.type);
            setDataItem({ ...dataItem, [e.target.name]: e.target.value, Value: '' });
        } else if(e.target.name == "dateWiseSheet"){
            setDataItem({ ...dataItem, dateWiseSheet: !dataItem.dateWiseSheet });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };

    return (<>
        {<BossDialog
            title={lang.export_report_dialog_header}
            onClose={() => navigate('/home')}
            width={"650px"}
        >
            <div className="col-12" style={{ margin: "02px 0px 0px 0px" }}>
                <div className="row mt-2">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.template_name_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={exportReport}
                                name="ExportReport"
                                textField="Name"
                                dataItemKey="_id"
                                value={dataItem.ExportReport}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={dataItem.ReportType.Description == REPORT_TYPE.XLSX ? "col-8" : "col-12"}>
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.format_label}</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={EPGREPORTTYPE}
                                name="ReportType"
                                textField="Description"
                                dataItemKey="SID"
                                value={dataItem.ReportType}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    {dataItem.ReportType.Description == REPORT_TYPE.XLSX && <div className="col-4 mt-4">
                        <input name="dateWiseSheet" type="checkbox" value={dataItem.dateWiseSheet} checked={dataItem.dateWiseSheet} onChange={onChange} />
                        <label className="ml-1">{lang.date_wise_sheet_label}</label>
                    </div>}
                </div>

                <div className="row">
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.from_date_label} *</label>
                            <input className="form-control form-control-sm" type={'date'} name={"FromDate"} value={dataItem.FromDate} onChange={onChange} />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.to_date_label} *</label>
                            <input className="form-control form-control-sm" type={'date'} name={"ToDate"} value={dataItem.ToDate} onChange={onChange} />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.channel_label} *</label>
                            <DropDownList
                                data={channel}
                                name={"Channel"}
                                textField="FullChannelName"
                                dataItemKey="_id"
                                value={dataItem.Channel}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row" style={{ marginTop: "05px" }}>
                    <div className="col-12">
                        <ActionButton title={lang.export_button_tooltip} name={lang.export_button_text} onClick={loadAndExportData} style={{ margin: '0px', borderRadius: '5px' }} />
                    </div>
                </div>
            </div>
            <div className='text-right mt-3' style={{ position: 'fixed', height: '30px', width: '652px', marginLeft: '-17px', padding: '5px', background: "#3A3633" }}>
                <span style={{ color: 'white' }}>{footerMessage} </span>
            </div>
        </BossDialog>}
    </>)
}
export default ExportEPGReport;