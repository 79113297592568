import { useState, useEffect } from "react";
import * as API from '../../../framework/API/api'
import { ENTITYNAME } from "../../../framework/constant/constant";
import { toast } from "react-toastify";
import MetaDataTab from "./component/metaDataTab";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import OttAssesTab from "./component/ottAssesTab";
import VersionTab from "./component/versionTab";
import PublishingTab from "./component/publishingTab";
import AttachMediaTab from "./component/attachMediaTab";
import { utility } from "../../../framework/utility/utilityProvider";
import { MediaEpisodeDetailForm } from "../MediaEpisodeDetailForm";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const ManageContentEditPanel = (props) => {

    const { transcodeStatus, mediaCategory = {}, entityName, gridData = [], setGridData, selectedItem = {}, setSelectedItem } = props;

    const [showMediaEdit, setShowMediaEdit] = useState(false);
    const [tabNumber, setTabNumber] = useState(mediaCategory?.isLong ? "1" : "4");
    const isHideVersion = entityName == ENTITYNAME.Series || entityName == ENTITYNAME.Season || !mediaCategory?.isLong;

    const handleTabChange = (event, newValue) => {
        setTabNumber(newValue);
    };

    const onMediaEdit = () => {
        setShowMediaEdit(!showMediaEdit);
    }

    const handleSetMediaEpisodeSave = async (data) => {
        let saveData = { ...selectedItem, Title: data.Title, Description: data.Description, Genres: data.Genres, ImageUrl: data.ImageUrl, Duration: data.Duration, ReleaseDate: data.ReleaseDate, Archive: data.Archive };
        setSelectedItem(saveData);
        let mediaEpisodeSaveRes = await API.saveData(entityName, saveData);
        if (!mediaEpisodeSaveRes.success) {
            toast.error('failed to save media episode');
        }
    }

    const tabStyle = {
        overflow: 'auto',
        height: '40vh',
        padding: '0px 10px 0px 10px'
    }

    return (
        <div style={{ position: "absolute", top: "91px", right: 0, width: "500px", height: "76%", backgroundColor: "white", transition: 'all 0.3s linear' }} >
            <div style={{ padding: "10px" }}>
                <div style={{ display: "flex", marginBottom: "10px", marginTop: "10px", height: "180px" }}>
                    <img src={selectedItem?.ImageUrl} onError={
                        (e) => {
                            e.target.onerror = null;
                            e.target.src = 'https://media.comicbook.com/files/img/default-movie.png';
                        }
                    } style={{ height: "165px", minWidth: "180px", maxWidth: "180px", borderRadius: "5px", objectFit: "cover" }} className="card-img-top" alt={selectedItem?.Title} />
                    <div className="ml-3" style={{ height: "140px", width: "100%" }}>
                        <div className="flex-container">
                            <h1 style={{ marginTop: '0px' }}>{utility.subString(selectedItem?.Title, 15)}</h1>
                            <div className="flex-container-reverse flex-item-auto">
                                <RoundButton icon={"close"} title={"Close"} onClick={() => setSelectedItem({})} />
                                <RoundButton icon={'pen'} title={'Edit'} onClick={onMediaEdit} />
                            </div>
                        </div>
                        <p className="pl-1 mb-1">{selectedItem?.AssetId}</p>
                        <p className="pl-1 mb-1">{selectedItem?.Description?.length > 90 ? selectedItem?.Description?.substring(0, 90) + '...' : selectedItem?.Description}</p>
                        <p className="pl-1 mb-1">{utility.displayArrayInPipe(selectedItem?.Genres, 'Description')}</p>
                        {mediaCategory?.isLong && <><div style={{ display: "flex" }}>
                            <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="pl-1 mb-1 mr-3">
                                Transcode Status :
                            </p>
                            <p style={{ width: "auto", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: transcodeStatus == 'Complete' ? 'green' : "grey", color: "white", borderRadius: "5px", padding: "2px 5px" }} className="pl-1 mb-1">{transcodeStatus}</p>
                        </div>
                            <div style={{ display: "flex" }}>
                                <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="pl-1 mb-1 mr-3">
                                    Is Ott Asset :
                                </p>
                                <p style={{ width: "auto", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: selectedItem?.IsOttAsset ? 'green' : "Red", color: "white", borderRadius: "5px", padding: "2px 5px" }} className="pl-1 mb-1">{selectedItem?.IsOttAsset ? "Yes" : "No"}</p>
                            </div></>}
                    </div>
                </div>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabNumber}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList
                                onChange={handleTabChange}
                                aria-label="lab API tabs example"
                                variant="scrollable"
                            >
                                {mediaCategory?.isLong && <Tab label="Meta Data" value="1" />}
                                {mediaCategory?.isLong && <Tab label="OTT Assets" value="2" />}
                                {!isHideVersion && <Tab label="Version" value="3" />}
                                <Tab label="Publishing" value="4" />
                                {mediaCategory?.isLong &&<Tab label="Attach Media" value="5" />}
                            </TabList>
                        </Box>
                        {mediaCategory?.isLong && <TabPanel value="1" style={tabStyle}>
                            <MetaDataTab entityName={entityName} mediaEpisode={selectedItem} gridData={gridData} setGridData={setGridData} />
                        </TabPanel>}
                        {mediaCategory?.isLong && <TabPanel value="2" style={tabStyle}>
                            <OttAssesTab entityName={entityName} setMediaEpisode={setSelectedItem} mediaEpisode={selectedItem} gridData={gridData} setGridData={setGridData} />
                        </TabPanel>}
                        {!isHideVersion && <TabPanel value="3" style={tabStyle}>
                            <VersionTab mediaEpisode={selectedItem} />
                        </TabPanel>}
                        <TabPanel value="4" style={tabStyle}>
                            <PublishingTab mediaEpisode={selectedItem} mediaCategory={mediaCategory} />
                        </TabPanel>
                        {mediaCategory?.isLong && <TabPanel value="5" style={tabStyle}>
                            <AttachMediaTab mediaEpisode={selectedItem} />
                        </TabPanel>}
                    </TabContext>
                </Box>
            </div>
            {showMediaEdit && <MediaEpisodeDetailForm dataItem={selectedItem} SID={selectedItem?.SID} Title={selectedItem?.Title ?? 'Edit'} mediaCategory={selectedItem.MediaCategory} mediaCategoryType={selectedItem.MediaCategoryType} quickEdit={true} onSave={handleSetMediaEpisodeSave} onClose={onMediaEdit} />}
        </div>
    )
};

export default ManageContentEditPanel;