import { memo, useEffect, useState } from "react";
import CancelButton from "../../../../framework/forms/helpercomponents/buttons/CancelButton";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import * as API from '../../../../framework/API/api'
import { ENTITYNAME, PLATFORMTYPE } from "../../../../framework/constant/constant";
import { toast } from "react-toastify";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import ActionButton from "../../../../framework/forms/helpercomponents/buttons/ActionButton";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import BossDialog from "../../../../components/BossDialog";

const MediaDealRightEditForm = (props) => {

    const { isLinear } = props;

    const labelStyle = { fontWeight: "400", fontSize: "14px" }

    const inputStyle = { width: "220px", marginTop: "10px", fontSize: "14px", minHeight: "30px", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }

    const date = new Date();

    const [data, setData] = useState({
        Channel: [],
        Platform: [],
        Region: [],
        PublishStartDate: date,
        PublishEndDate: date,
        IsUnlimited: false,
        Exhibitions: 0,
        Repeats: 0,
        PlayTimeNumber: 0,
        PlayTime: []
    });
    const [selectedData, setSelectedData] = useState({
        Channel: [],
        Platform: [],
        Region: [],
        PublishStartDate: date,
        PublishEndDate: date,
        IsUnlimited: false,
        Exhibitions: 0,
        Repeats: 0,
        PlayTimeNumber: 0,
        PlayTime: null
    });

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {

        let dataForState = data;

        let channelRes = await API.getDataLookup(ENTITYNAME.Channel);
        if (channelRes.success) {
            dataForState = { ...dataForState, Channel: channelRes.data };
        }
        let platformRes = await API.getDataLookup(ENTITYNAME.Platform);
        if (platformRes.success) {
            dataForState = { ...dataForState, Platform: platformRes.data };
        }
        let regionRes = await API.getDataLookup(ENTITYNAME.Region);
        if (regionRes.success) {
            dataForState = { ...dataForState, Region: regionRes.data };
        }
        let playTimeRes = await API.getDataLookup(ENTITYNAME.PlayTime, { sort: { Description: 1 } });
        if (playTimeRes.success) {
            dataForState = { ...dataForState, PlayTime: playTimeRes.data };
        }

        setData(dataForState);
    }

    const onFieldValueChange = (e) => {
        setSelectedData({ ...selectedData, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        // common validation for linear and non-linear

        if (selectedData.PublishStartDate > selectedData.PublishEndDate) {
            toast.error("Start date should be less than end date");
            return false;
        }

        // linear validation
        if (isLinear) {
            if (selectedData.Channel.length === 0) {
                toast.error("Please select channel");
                return false;
            }
            if (!selectedData.IsUnlimited) {
                if (selectedData.PlayTime === null || selectedData.PlayTime === "") {
                    toast.error("Please select Play Time");
                    return false;
                }
                if (selectedData.Exhibitions < 1 || selectedData.Exhibitions === "") {
                    toast.error("Exhibition should be greater than Zero");
                    return false;
                }
                if (selectedData.Repeats < 1 || selectedData.Repeats === "") {
                    toast.error("Repeat should be greater than Zero");
                    return false;
                }
                if (selectedData.PlayTimeNumber < 1 || selectedData.PlayTimeNumber === "") {
                    toast.error("Play Time Number should be greater than Zero");
                    return false;
                }
            }

        }
        // non-linear validation
        else {
            if (selectedData.Platform.length === 0) {
                toast.error("Please select platform");
                return false;
            }
        }

        return true;
    }

    const onSave = () => {

        console.log(selectedData);

        if (isValid()) {
            if (isLinear) {
                let linearSaveData = {
                    _id: parseInt(Math.random() * 1000),
                    ...selectedData,
                    PlatformType: PLATFORMTYPE[0],
                    Channel: {_id: selectedData.Channel._id, SID: selectedData.Channel.SID, FullChannelName: selectedData.Channel.FullChannelName},
                    Region: {_id: selectedData.Region._id, SID: selectedData.Region.SID, Description: selectedData.Region.Description},
                    PublishingSource: ENTITYNAME.MediaEpisode,
                    PublishStartDate: selectedData.PublishStartDate.getTime(),
                    PublishEndDate: selectedData.PublishEndDate.getTime(),
                    PlayTime: selectedData.IsUnlimited ? null : {_id: selectedData.PlayTime._id, SID: selectedData.PlayTime.SID, Description: selectedData.PlayTime.Description},
                    Exhibitions: selectedData.IsUnlimited ? 0 : selectedData.Exhibitions,
                    Repeats: selectedData.IsUnlimited ? 0 : selectedData.Repeats,
                    PlayTimeNumber: selectedData.IsUnlimited ? 0 : selectedData.PlayTimeNumber,
                    IsUnlimited: selectedData.IsUnlimited,
                };
                console.log(linearSaveData);
                props.onSave(linearSaveData);
            }
            else {
                let nonLinearSaveData = {
                    _id: parseInt(Math.random() * 1000),
                    ...selectedData,
                    PlatformType: PLATFORMTYPE[1],
                    Platform: {_id: selectedData.Platform._id, SID: selectedData.Platform.SID, Description: selectedData.Platform.Description},
                    Region: {_id: selectedData.Region._id, SID: selectedData.Region.SID, Description: selectedData.Region.Description},
                    PublishingSource: ENTITYNAME.MediaEpisode,
                    PublishStartDate: selectedData.PublishStartDate.getTime(),
                    PublishEndDate: selectedData.PublishEndDate.getTime()
                };
                console.log(nonLinearSaveData);
                props.onSave(nonLinearSaveData);
            }

            props.onClose();
        }

    }

    return <BossDialog
        title={'Media Deal Rights'}
        onClose={props.onClose}
        width={"800px"}
        height={"auto"}
    >
        <ActionButton name={'Add'} title={'Add'} onClick={onSave} />
        <CancelButton onClick={props.onClose} />
        <div className="mx-2 my-3 ">
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginTop: "20px", padding: "10px 0" }}>
                <div>
                    <label style={labelStyle}>Platform Type *</label><br />
                    <input
                        disabled={true}
                        value={isLinear ? PLATFORMTYPE[0].Description : PLATFORMTYPE[1].Description}
                        style={{ ...inputStyle, color: "grey", cursor: "not-allowed" }}
                    />
                </div>
                {isLinear
                    ?
                    <div>
                        <label style={labelStyle}>Channel *</label><br />
                        <MultiSelect
                            data={data.Channel}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            onChange={onFieldValueChange}
                            style={{ ...inputStyle, maxHeight: "60px", overflow: "auto" }}
                        />
                    </div>
                    :
                    <div>
                        <label style={labelStyle}>Platform *</label><br />
                        <MultiSelect
                            data={data.Platform}
                            name="Platform"
                            textField="Description"
                            dataItemKey="_id"
                            onChange={onFieldValueChange}
                            style={{ ...inputStyle, maxHeight: "60px", overflow: "auto" }}
                        />
                    </div>
                }
                <div>
                    <label style={labelStyle}>Region</label><br />
                    <MultiSelect
                        data={data.Region}
                        name="Region"
                        textField="Description"
                        dataItemKey="_id"
                        onChange={onFieldValueChange}
                        style={{ ...inputStyle, maxHeight: "60px", overflow: "auto" }}

                    />
                </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginTop: "10px", padding: "10px 0" }}>
                <div>
                    <label style={{ ...labelStyle, marginBottom: "10px" }}>Start Date *</label><br />
                    <DatePicker
                        width={"220px"}
                        style={inputStyle}
                        format={"dd MMM yyyy"}
                        defaultValue={data.PublishStartDate}
                        name="PublishStartDate"
                        onChange={onFieldValueChange}
                    />
                </div>
                <div>
                    <label style={{ ...labelStyle, marginBottom: "10px" }}>End Date *</label><br />
                    <DatePicker
                        width={"220px"}
                        style={inputStyle}
                        format={"dd MMM yyyy"}
                        defaultValue={data.PublishEndDate}
                        name="PublishEndDate"
                        onChange={onFieldValueChange}
                    />
                </div>
                {isLinear && <div>
                    <label style={{ ...labelStyle, marginBottom: "10px" }}>Play Time *</label><br />
                    <DropDownList
                        data={data.PlayTime}
                        name="PlayTime"
                        textField="Description"
                        dataItemKey="_id"
                        onChange={onFieldValueChange}
                        style={inputStyle}
                        disabled={selectedData.IsUnlimited}
                    />
                </div>}
            </div>
            {isLinear && <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginTop: "10px", padding: "10px 0" }}>
                <div>
                    <label style={{ ...labelStyle, marginBottom: "10px" }}>Exhibition  *</label><br />
                    <Input
                        width={"220px"}
                        style={{ ...inputStyle, backgroundColor: selectedData.IsUnlimited ? "#e9ecef" : "white" }}
                        value={selectedData.Exhibitions}
                        name="Exhibitions"
                        onChange={onFieldValueChange}
                        type="number"
                        disabled={selectedData.IsUnlimited}
                    />
                </div>
                <div>
                    <label style={{ ...labelStyle, marginBottom: "10px" }}>Repeat  *</label><br />
                    <Input
                        width={"220px"}
                        style={{ ...inputStyle, backgroundColor: selectedData.IsUnlimited ? "#e9ecef" : "white" }}
                        value={selectedData.Repeats}
                        name="Repeats"
                        onChange={onFieldValueChange}
                        type="number"
                        disabled={selectedData.IsUnlimited}
                    />
                </div>
                <div>
                    <label style={{ ...labelStyle, marginBottom: "10px" }}>Play Time Number *</label><br />
                    <Input
                        width={"220px"}
                        style={{ ...inputStyle, backgroundColor: selectedData.IsUnlimited ? "#e9ecef" : "white" }}
                        value={selectedData.PlayTimeNumber}
                        name="PlayTimeNumber"
                        onChange={onFieldValueChange}
                        type="number"
                        disabled={selectedData.IsUnlimited}
                    />
                </div>
            </div>}
            {isLinear && <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginTop: "10px", padding: "10px 0" }}>
                <div>
                    <label style={{ ...labelStyle, marginBottom: "10px" }}>IsUnlimited</label><br />
                    <Checkbox value={selectedData.IsUnlimited} name="IsUnlimited" onChange={onFieldValueChange} />
                </div>
            </div>}
        </div>

    </BossDialog>
}

export default memo(MediaDealRightEditForm);