/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Form, FormElement } from '@progress/kendo-react-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as API from "../../framework/API/api"
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ENTITYNAME } from '../../framework/constant/constant';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { EditPageHeader } from '../../components/EditPageHeader';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { PaymentsEditForm } from './PaymentsEditForm';
import moment from 'moment';
import { utility } from '../../framework/utility/utilityProvider';
import OttFilterTemplate from '../../framework/OTT/OttFilterTemplate';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import MyMultiSelect from '../../components/MyMultiSelect';
import { DataHelper } from '../../framework/helper/DataHelper';

const PaymentPlanEditForm = (props) => {

  const [currency, setCurrency] = useState([]);
  const [ottPeriod, setOttPeriod] = useState([]);
  const [ottProducts, setOttProducts] = useState([]);
  const [features, setFeatures] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [payments, setPayments] = useState([]);
  const [tabNumber, setTabNumber] = React.useState("1");
  const [enableFilter, setEnableFilter] = useState(false);
  const [filterData, setFilterData] = useState(undefined);
  const [dataItem, setDataItem] = useState({
    StartDate: '',
    EndDate: '',
    Features: [],
    Platforms: [],
    MaxDevices: 1,
    EnableFixedTrial: false,
    EnableReminder: false,
    Archive: false
  });

  const navigate = useNavigate();
  const loc = useLocation();
  const { SID } = useParams();
  const lang = useTranslation();

  useEffect(() => {
    loadcombo();
    if (SID > 0) {
      loadEditData();
    }
  }, []);

  const loadcombo = async () => {
    var ottPeriod = await API.getDataLookup(ENTITYNAME.OttPeriod, { query: ["Archive", "=", false], sort: { Name: 1 } });
    setOttPeriod(ottPeriod.data);
    // var paymentType = await API.getDataLookup(ENTITYNAME.OttPeriodType, {query:["Archive", "=", false ], sort: { Name: 1 } });
    // setPaymentType(paymentType.data);    
    var currency = await API.getDataLookup(ENTITYNAME.Currency, { query: ["Archive", "=", false], sort: { Description: 1 } });
    setCurrency(currency.data);
    var product = await API.getDataLookup(ENTITYNAME.OttProduct, { query: ["Archive", "=", false], sort: { Title: 1 } });
    setOttProducts(product.data);
    var featuresRes = await API.getDataLookup(ENTITYNAME.PaymentPlanFeature, { query: ["Archive", "=", false], sort: { SID: 1 } });
    setFeatures(featuresRes.data);
    var platformRes = await API.getDataLookup(ENTITYNAME.Platform, { query: ["Archive", "=", false], sort: { SID: 1 } });
    setPlatforms(platformRes.data);
  }

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const onChange = (e) => {
    if (e.target.name == 'EnableFixedTrial') {
      setDataItem({ ...dataItem, EnableFixedTrial: !dataItem.EnableFixedTrial });
    } else if (e.target.name == 'EnableReminder') {
      setDataItem({ ...dataItem, EnableReminder: !dataItem.EnableReminder });
    } else if (e.target.name == 'Archive') {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  const loadEditData = async () => {
    var res = await API.getEntity(ENTITYNAME.PaymentPlan, parseInt(SID));

    if (res.success) {
      setDataItem({
        ...res.data,
        Platforms: res.data.Platforms?.length > 0 ? res.data.Platforms : [],
        Features: res.data.Features?.length > 0 ? res.data.Features : [],
        StartDate: res.data.FixedTrial.StartDate == 0 ? 'YYYY-MM-DD' : moment(new Date(res.data.FixedTrial.StartDate)).format('YYYY-MM-DD'),
        EndDate: res.data.FixedTrial.EndDate == 0 ? 'YYYY-MM-DD' : moment(new Date(res.data.FixedTrial.EndDate)).format('YYYY-MM-DD'),
        Description: res.data.Description,
      });
      setPayments(res.data.Payments ?? []);
      setFilterData(res.data.filterTemplate ?? undefined);
    }

  };

  const onCancelEdit = () => {
    navigate(-1);
    window.close();
  }

  const isValid = () => {
    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!(dataItem.OttPeriod)) {
      toast.error(`${lang.please_select_OttPeriod_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!(dataItem.Product)) {
      toast.error(`${lang.please_select_product_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (!(dataItem.Platforms) || dataItem.Platforms.length == 0) {
      toast.error(`${lang.please_select_platform_type_error_msg}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (!(dataItem.Currency)) {
      toast.error(`${lang.please_select_Currency_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (!(dataItem.Amount)) {
      toast.error(`${lang.please_select_amount_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (!(dataItem.MaxDevices) || dataItem.MaxDevices < 1) {
      toast.error(`${lang.maximun_device_should_not_be_less_than_one}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.EnableFixedTrial) {
      if (!utility.isValidDate(dataItem.StartDate)) {
        toast.error(`${lang.please_select_start_date_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if (!utility.isValidDate(dataItem.EndDate)) {
        toast.error(`${lang.please_select_end_date_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    }

    //ask for payments
    if (payments.length == 0) {
      toast.error(`${lang.please_select_PaymentType_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const onSaveData = async () => {
    if (isValid()) {
      const saveData = {
        // ...dataItem,
        SID: dataItem.SID ?? 0,
        Description: dataItem.Description,
        OttPeriod: dataItem.OttPeriod,
        Currency: dataItem.Currency,
        Amount: parseFloat(dataItem.Amount),
        Product: {
          _id: dataItem.Product._id,
          SID: dataItem.Product.SID,
          Title: dataItem.Product.Title,
          Segments: dataItem.Product.Segments.map((x) => { return { _id: x._id, Description: x.Description } })
        },
        EnableFixedTrial: dataItem.EnableFixedTrial ?? false,
        FixedTrial: {
          StartDate: dataItem.EnableFixedTrial ? new Date(dataItem.StartDate).getTime() : 0,
          EndDate: dataItem.EnableFixedTrial ? new Date(dataItem.EndDate).getTime() : 0
        },
        Payments: payments,
        Features: dataItem.Features.length > 0 ? dataItem.Features : [],
        Platforms: dataItem.Platforms.map((x) => { return { _id: x._id, SID: x.SID, Description: x.Description } }),
        MaxDevices: parseInt(dataItem.MaxDevices) ?? 1,
        filterTemplate: filterData ?? {},
        Archive: dataItem.Archive ?? false

      }

      dataItem._id ? saveData._id = dataItem._id : delete saveData._id

      console.log(saveData);

      const res = await API.saveData(ENTITYNAME.PaymentPlan, saveData);
      if (res.success) {
        toast.success('Saved Successfully !!', {
          position: toast.POSITION.TOP_RIGHT
        });
        navigate('/home/PaymentPlan');
        // onCancelEdit();
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  };

  const toGetFilteredData = (data) => {
    setFilterData(data);
  }
  return (
    <>
      <div className='row m-1'>
        <div className='col-12'>
          <EditPageHeader title={`${lang.paymentPlan_sub_menu} -> ` + (dataItem.Description ? dataItem.Description : `${lang.new_title_editpageheader}`)} onSubmit={onSaveData} onCancel={onCancelEdit} />
          <Form
            render={(formRenderProps) => (
              <FormElement>
                <div className='row mt-2'>
                  <div className='col-12' style={{ borderRight: "ridge" }}>

                    <div className='row'>
                      <div className="form-group col-3">
                        <label htmlFor="">{lang.description_label} *</label>
                        <input name="Description" type="text" className="form-control form-control-sm" value={dataItem.Description} onChange={onChange} required />
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="TabView">{lang.ott_period_label} *</label>
                          <DropDownList
                            style={{
                              backgroundColor: "white",
                            }}
                            data={ottPeriod}
                            name="OttPeriod"
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.OttPeriod}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="TabView">{lang.product_label} *</label>
                          <DropDownList
                            style={{
                              backgroundColor: "white",
                            }}
                            data={ottProducts}
                            name="Product"
                            textField="Title"
                            dataItemKey="_id"
                            value={dataItem.Product}
                            onChange={onChange}
                          />
                        </div>
                      </div>

                      <div className="col-3 form-group">
                        <label htmlFor="TabView">{lang.platform_label} *</label><br />
                        <MyMultiSelect
                          style={{ backgroundColor: "white" }}
                          data={platforms}
                          name="Platforms"
                          textField="Description"
                          dataItemKey="SID"
                          value={dataItem.Platforms}
                          onChange={onChange}
                        />
                      </div>

                    </div>
                    <div className='row'>

                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="TabView">{lang.currency_label} *</label>
                          <DropDownList
                            style={{
                              backgroundColor: "white",
                            }}
                            data={currency}
                            name="Currency"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.Currency}
                            onChange={onChange}
                          />
                        </div>
                      </div>

                      <div className="col-3">
                        <div className="form-group">
                          <label>{lang.amount_label} *</label>
                          <input type="number" className="form-control form-control-sm" name="Amount" value={dataItem.Amount} onChange={onChange} min={0} />
                        </div>
                      </div>

                      <div className="col-3">
                        <div className="form-group">
                          <label>{lang.maximum_devices_label}</label>
                          <input type="number" className="form-control form-control-sm" name="MaxDevices" value={dataItem.MaxDevices} onChange={onChange} min={1} />
                        </div>
                      </div>

                      <div className="col-3">
                        <div className="form-group">
                          <label>{lang.feature_label}</label>
                          <MyMultiSelect
                            style={{ backgroundColor: "white" }}
                            data={features}
                            name="Features"
                            textField="Description"
                            dataItemKey="SID"
                            value={dataItem.Features}
                            onChange={onChange}
                          />
                          {/* <input type="string" className="form-control form-control-sm" name="Features" value={dataItem.Features} onChange={onChange} />  */}
                        </div>
                      </div>

                    </div>

                    {/* fixed trial field */}
                    <div className='row p-1'>
                      <div className='col-6 d-flex mt-4'>
                        <label htmlFor="TabView" style={{ marginTop: "6px" }}>{lang.select_rule_label}</label>
                        <input
                          name="EnableFilter"
                          style={{width:"350px"}}
                          type="text"
                          className="form-control form-control-sm ml-1"
                          value={filterData?.Template?.Name ?? ''}
                          onChange={onChange}
                          disabled={true}
                        />
                        <ActionButton style={{ height: "30px" }} btnColor={"success"} title={lang.select_button_tooltip} name={"..."} onClick={() => setEnableFilter(true)} />
                        <ActionButton style={{ height: "30px" }} btnColor={"danger"} title={lang.select_button_tooltip} name={""} icon={"xmark"} onClick={() => setFilterData(undefined)} />
                      </div>
                      <div className='col-6 d-flex border' style={{height: '80px'}}>
                        <div style={{marginTop: '30px'}}>
                          <input name="EnableFixedTrial" type={"checkbox"} value={dataItem.EnableFixedTrial} checked={dataItem.EnableFixedTrial} onClick={onChange} onChange={() => { }} />
                          <label className='ml-1'>{lang.enable_fixed_trial_label}</label>
                        </div>
                        {dataItem.EnableFixedTrial && <div className='mt-2 ml-4'>
                          <label htmlFor="">{lang.start_date_label} *</label><br />
                          <input type={'date'} name={"StartDate"} value={dataItem.StartDate} onChange={onChange} max={dataItem.EndDate}
                            className="form-control form-control-sm" label={lang.start_date_label}
                          />
                        </div>}
                        {dataItem.EnableFixedTrial && <div className='mt-2 ml-4'>
                          <label htmlFor="">{lang.end_Date_label} *</label><br />
                          <input type={'date'} name={"EndDate"} onChange={onChange} value={dataItem.EndDate} min={dataItem.StartDate}
                            className="form-control form-control-sm" label={lang.end_Date_label}
                          />
                        </div>}
                      </div>
                    </div>
                    <div className='row mt-3 mb-2'>
                      <div className="col-6">
                        <label className='mr-2'>{lang.archive}</label>
                        <input name="Archive" type={"checkbox"} value={dataItem.Archive} checked={dataItem.Archive} onClick={onChange} onChange={() => { }} />  
                      </div>
                    </div>
                  </div>
                </div>
              </FormElement>
            )} />
        </div>

        <div className='col-12'>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabNumber}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleTabChange} aria-label="tabs example">
                  <Tab label={lang.payments_label} value="1" />
                </TabList>
              </Box>
              <TabPanel value={"1"}>
                <PaymentsEditForm data={payments} setPaymentsData={setPayments} />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      {enableFilter && (
        <OttFilterTemplate
          toGetFilterDataItem={toGetFilteredData}
          saveButtonName={"Save"}
          showDefaultButton={false}
          filterDataItem={filterData}
          setTemplate={setDataItem}
          onClose={setEnableFilter}
        />
      )}
    </>
  )
}

export default PaymentPlanEditForm