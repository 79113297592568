
import { useTranslation } from "../locale/useTranslation";
import BossDialog from "./BossDialog";



const ImagePreviewPage = (props) => {
  const lang = useTranslation ();

     return <BossDialog
        title={lang.preview_image_title_label}
        onClose={props.onClose}
        width={"25vw"}
      >
        <div style={{textAlign:"center"}}>
        <img src={props.image} width={"200px"} object-fit= "fit" />  
        </div>
          
    </BossDialog>
}

export default ImagePreviewPage;
