import { Switch } from '@mui/material';
import React, { useState } from 'react'
import * as API from '../../../API/api'



export const MyToggleButton = (props) => {

  // maintaining here so that toggle reflect on collection
    const [publish, setPublish] = useState(props.dataItem.Publish);
        
 // update function here, we will call api and updating UI with setPublish
   const onUpdatePublishStatus = async (e) => {

    const saveData = {      
      "_id":props.dataItem._id,
    }
    saveData[props.field] = e;
    console.log(e);
    var res = await API.saveData(props.entityName, saveData);
    if (res.success) {      
      setPublish(e);      
    }
    else
    {
      alert("Error Please try again");
    }
   };


  return (                         
            
            <td>



                <Switch onLabel={props.onLabel ?? ""}
                                offLabel={props.offLabel ?? ""}
                                //size={props.size ?? "midum"}
                                onChange={(e) => onUpdatePublishStatus(e.target.checked)}
                                checked={publish ? true : false}
                                color = "default"
                                />   
            </td>             

  )
}

