/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { useRef } from "react";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import BossDialog from "../../components/BossDialog";

const JobsShowForm = (props) => {

  const SID = props.item.SID;
  const lang = useTranslation();

  let blankDataItem = {
    SID: SID,
    AssetId: "",
    StartTime: "",
    FinishTime: "",
    ElapsedTime: "",
    FirstCheck: "",
    SecondCheck: "",
    ThirdCheck: "",
    FourthCheck: "",
    JobId: "",
    Archive: false
  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [gridData, setGridData] = useState([]);
  const [showAttachMedia, setShowAttachMedia] = useState(props.item.attachMedia);
  const jobDataItemRef = useRef()
  const setJobDataItemRef = (data) => {
    jobDataItemRef.current = data;
  }

  useEffect(() => {
    if (props.item.SID > 0) {
      loadEditData();
    }
  }, []);

  const loadEditData = async () => {

    setJobDataItemRef(props.item);
    setDataItem({
      ...props.item,
      AssetId: props.item.AssetId,
      StartTime: utility.convertMilisecondsToDateTimeString(props.item.Status[0].Time),
      FinishTime: utility.convertMilisecondsToDateTimeString(props.item.Status[props.item.Status.length - 1].Time),
      ElapsedTime: utility.convertMilisecondsToStringWithFrames(props.item.Status[props.item.Status.length - 1].Time - props.item.Status[0].Time),
      Status: props.item.Status,

    })
    setGridData(props.item.JobInputDetail);

  }

  const handleMediaData = async (data) => {
    if (data.length > 0) {
      setDataItem({
        ...dataItem,
        AssetId: data[0].AssetId
      })
    }

    var res = await API.linkMediaAndJobs(data[0],jobDataItemRef.current);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }else{
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  var fontFamilyStyle = {
    fontFamily: "Roboto, Times, serif"
  }

  return (
    <>
      <BossDialog
        title={`Jobs`}
        onClose={props.cancelEdit}
        width="80vw"
        height="95vh"
      >
        <div className="row">
          <div className="col-12">
            <h1 className="page-title txt-color-bludeDark" style={{ marginTop: '-5px' }}>
              <span className="float-left"> <BackRoundButton onClick={props.cancelEdit}></BackRoundButton></span>
              <span className="float-right mt-2">
                <i className="fa fa-table"></i>
                <span className="ml-1">{lang.jobs_title}</span>
              </span>
            </h1>
            <br />
            <div className="card">
              <div className="card-header">
                <div style={{ fontFamily: "Roboto, Times, serif", marginBottom: '-3px' }} className="card-title">
                  <span style={{ fontSize: '18px', fontWeight: '500' }} >{lang.asset_label} :</span>
                  <span style={{ fontSize: '18px', fontWeight: '400' }} className="ml-2">{dataItem.AssetId}</span>
                  {dataItem.AssetId.length == 0 && <div style={{ textAlign: 'right', marginTop:'-28px' }}><ActionButton name={lang.attach_media_button_text} onClick={() => { setShowAttachMedia(true) }} /></div>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <h3 style={fontFamilyStyle}>{lang.details_label}</h3>
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td>{lang.job_id_label} :</td>
                      <td>{dataItem.JobId}</td>
                    </tr>
                    <tr>
                      <td>{lang.job_started_label} :</td>
                      <td>{dataItem.StartTime}</td>
                    </tr>
                    <tr>
                      <td>{lang.job_finished_label} :</td>
                      <td>{dataItem.FinishTime}</td>
                    </tr>
                    <tr>
                      <td>{lang.elapsed_label} :</td>
                      <td>{dataItem.ElapsedTime}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card ">
              <div className="card-header">
                <div className="card-title" >
                  <div className="row">

                    {dataItem.Status?.map((item) => {
                      return (

                        <div className="col-2">
                          <div style={{ fontSize: '60px', color: 'LightSeaGreen', marginLeft: '32%' }} className="fa fa-check-circle-o"></div>
                          <div style={{ fontSize: '15px' }} className="mt-3 text-center">{item.Description?.replace('_', ' ')}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <h3 style={fontFamilyStyle}>{`${lang.job_input_file_label}`}</h3>
            </div>
            <Grid data={gridData} style={{ height: '13vh', margin: '5px 0px' }}>
              <GridColumn field="FileName" title={lang.file_name_column} editable={false} />
              <GridColumn field="Type" title={lang.media_type_column} editable={false} />
              <GridColumn field="File Location" title={lang.file_location_column} editable={false} />
            </Grid>
          </div>
        </div>
      </BossDialog>
      {showAttachMedia && <CollectionSelection title={lang.select_media_collection_header} entityname={ENTITYNAME.MediaEpisode} setDataList={handleMediaData} closeForm={() => setShowAttachMedia(false)} mode={'single'} width={"55vw"} height={"64vh"} />}
    </>
  )
}

export default JobsShowForm;
