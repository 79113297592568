/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { utility } from '../../framework/utility/utilityProvider';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import OttAssetCollectionSelection from '../../framework/OTT/OttAssetCollectionSelection';
import BossDialog from '../../components/BossDialog';

const ChannelEditForm = (props) => {

    const [automation, setAutomation] = useState([]);
    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        FullChannelName: props.item.copy ? "Copy of " + props.item.FullChannelName : props.item.FullChannelName ?? "",
        ShortChannelName: props.item.ShortChannelName ?? "",
        EPGCode: props.item.EPGCode ?? "",
        AsRunCode: props.item.AsRunCode ?? "",
        AutomationChannelName: props.item.AutomationChannelName ?? "",
        Color: props.item.Color ?? "",
        Automation: props.item.Automation ?? {},
        AsrunTimeDiff: utility.convertMilisecondsToStringWithFrames(props.item.AsrunTimeDiff) ?? '00:00:00:00',
        ExportBreaksOnly: props.item.ExportBreaksOnly ?? false,
        IsNegativeAsrunDiff: props.item.IsNegativeAsrunDiff ?? false,
        ottAsset: props.item.ottAsset ?? {},
        IsDefault: props.item.IsDefault ?? false,
        ChannelNo: props.item.ChannelNo ?? 0,
        MinGap : parseInt(props.item.MinGap) ?? 0,
        PlaylistImpBuff : props.item.PlaylistImpBuff ? utility.convertMilisecondsToStringWithFrames(props.item.PlaylistImpBuff):"00:00:00:00",
        IsNegativePlaylistImpBuff : props.item.IsNegativePlaylistImpBuff ?? false,
    }

    useEffect(() => {
        loadcombo();
    }, [])

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [showOttAssets, setShowOttAssets] = useState(false);

    const loadcombo = async () => {
        let AutomationRes = await API.getDataLookup(ENTITYNAME.Automation, { sort: { Name: 1 } });
        setAutomation(AutomationRes.data);
    }

    const onChangeForm = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if (dataItem.FullChannelName == "" || dataItem.FullChannelName == undefined) {
            toast.error(`${lang.please_enter_full_Channel_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ShortChannelName == "" || dataItem.ShortChannelName == undefined) {
            toast.error(`${lang.please_enter_short_Channel_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.EPGCode == "" || dataItem.EPGCode == undefined) {
            toast.error(`${lang.please_enter_epg_code_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.AutomationChannelName == "" || dataItem.AutomationChannelName == undefined) {
            toast.error(`${lang.please_enter_automation_channel_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Color == "" || dataItem.Color == undefined) {
            toast.error(`${lang.please_enter_color_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Automation).length == 0) {
            toast.error(`${lang.please_select_automation_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if(dataItem.MinGap == undefined || isNaN(parseInt(dataItem.MinGap))){
            toast.error(`${lang.min_gap_invalid_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (parseInt(dataItem.MinGap) < 0 ) {
            toast.error(`${lang.min_gap_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true;
    }

    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                _id: dataItem._id,
                SID: dataItem.SID ?? 0,
                ShortChannelName: dataItem.ShortChannelName,
                FullChannelName: dataItem.FullChannelName,
                EPGCode: dataItem.EPGCode,
                AsRunCode: dataItem.AsRunCode,
                AutomationChannelName: dataItem.AutomationChannelName,
                Color: dataItem.Color,
                Automation: {
                    _id: dataItem.Automation._id,
                    SID: dataItem.Automation.SID,
                    Name: dataItem.Automation.Name
                },
                isCheckListMandatory: dataItem.isCheckListMandatory ?? false,
                ExportBreaksOnly: dataItem.ExportBreaksOnly ?? false,
                AsrunTimeDiff: utility.convertStringWithFramesToMilliseconds(dataItem.AsrunTimeDiff),
                MinGap : dataItem.MinGap ?? 0,
                IsNegativeAsrunDiff: dataItem.IsNegativeAsrunDiff ?? false,
                PlaylistImpBuff: utility.convertStringWithFramesToMilliseconds(dataItem.PlaylistImpBuff),
                IsNegativePlaylistImpBuff: dataItem.IsNegativePlaylistImpBuff ?? false,
                ottAsset: {
                    _id: dataItem.ottAsset._id,
                    SID: dataItem.ottAsset.SID,
                    Title: dataItem.ottAsset.Title
                },
                IsOttEnabled: dataItem.IsOttEnabled ?? false,
                Archive: dataItem.Archive ?? false,
                IsDefault: dataItem.IsDefault ?? false,
                ChannelNo: parseInt(dataItem.ChannelNo) ?? 0,
                checkDuplicate: true,
                query: [["FullChannelName", "=", dataItem.FullChannelName]]
            }
            console.log(saveData);
            let res = await API.saveData(ENTITYNAME.Channel, saveData);
            console.log(res);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.CHANNEL, data: res.data, message: res.message };
                API.SaveLogs(logData);
                props.refresh();
                props.cancelEdit();
                utility.deleteLocalStorageItem(ENTITYNAME.Channel);
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const cancelEdit = () => {
        props.cancelEdit();
    }

    const handleSelectedAssets = (data) => {
        console.log(data[0]);
        setDataItem({ ...dataItem, ottAsset: data[0] });
        setShowOttAssets(false);
    }

    return (
        <BossDialog title={props.item.FullChannelName} onClose={cancelEdit} width="43vw">
            <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
            <Form
                onSubmit={handleSubmit}
                initialValues={dataItem}
                render={(formRenderProps) => (
                    <FormElement>
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <label>{lang.full_channel_name_label} *</label>
                                        <input
                                            name={"FullChannelName"}
                                            type="text"
                                            className='form-control form-control-sm'
                                            onChange={onChangeForm}
                                            value={dataItem.FullChannelName}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <label>{lang.short_channel_name_label} *</label>
                                        <input
                                            name={"ShortChannelName"}
                                            type="text"
                                            className='form-control form-control-sm'
                                            onChange={onChangeForm}
                                            value={dataItem.ShortChannelName}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <label>{lang.epg_code_label} *</label>
                                        <input
                                            name={"EPGCode"}
                                            type="text"
                                            className='form-control form-control-sm'
                                            onChange={onChangeForm}
                                            value={dataItem.EPGCode}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <label>{lang.as_run_code_label}</label>
                                        <input
                                            name={"AsRunCode"}
                                            type="text"
                                            className='form-control form-control-sm'
                                            onChange={onChangeForm}
                                            value={dataItem.AsRunCode}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <label>{lang.automation_channel_name_label} *</label>
                                        <input
                                            name={"AutomationChannelName"}
                                            type="text"
                                            className='form-control form-control-sm'
                                            onChange={onChangeForm}
                                            value={dataItem.AutomationChannelName}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <label>{lang.channel_number_label}</label>
                                        <input
                                            name={"ChannelNo"}
                                            type="number"
                                            className='form-control form-control-sm'
                                            onChange={onChangeForm}
                                            value={dataItem.ChannelNo}
                                        />
                                    </div>
                                    <div className='col-2'>
                                        <label>{lang.color_label} *</label>
                                        <input
                                            name={"Color"}
                                            type="color"
                                            className='form-control form-control-sm'
                                            onChange={onChangeForm}
                                            value={dataItem.Color}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    <div className='col-6'>
                                        <label>{lang.automation_label} *</label>
                                        <DropDownList
                                            data={automation}
                                            name="Automation"
                                            textField="Name"
                                            dataItemKey="_id"
                                            value={dataItem.Automation}
                                            onChange={onChangeForm}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <label htmlFor="">{'Asrun Time Difference'}</label>
                                        <TimePickerWithFormat
                                            className="form-control form-control-sm"
                                            name="AsrunTimeDiff"
                                            value={dataItem.AsrunTimeDiff}
                                            onChange={onChangeForm}
                                        />
                                    </div>
                                    <div className='col-3'>
                                        <label>{lang.minimum_gap_label}</label>
                                        <input
                                            className='form-control form-control-sm'
                                            name="MinGap"
                                            type="number"
                                            onChange={onChangeForm}
                                            value={dataItem.MinGap}
                                            min={0}
                                        />
                                    </div>
                                </div>
                                <div className='row mt-2'>
                                    
                                    <div className="col-6">
                                        <label htmlFor="">{lang.playlist_import_buffer_label}</label>
                                        <TimePickerWithFormat
                                            className="form-control form-control-sm"
                                            name="PlaylistImpBuff"
                                            value={dataItem.PlaylistImpBuff}
                                            onChange={onChangeForm}
                                        />
                                    </div>
                                    <div className="col-6 mt-4">
                                        <Field
                                            style={{ border: "1px solid grey" }}
                                            name={"IsNegativePlaylistImpBuff"}
                                            component={Checkbox}
                                            label={lang.is_negative_playlist_import_buffer_label}
                                            onChange={onChangeForm}
                                        />
                                    </div>
                                    
                                </div>
                                <div className='row mt-3'>
                                    <div className="col">
                                        <Field
                                            style={{ border: "1px solid grey" }}
                                            name={"IsOttEnabled"}
                                            component={Checkbox}
                                            label={lang.ott_enabled_label}
                                            onChange={onChangeForm}
                                        />
                                    </div>
                                </div>
                                {dataItem.IsOttEnabled && <div className='row mt-2'>
                                    <div className='col-10'>
                                        <label>{lang.ott_assets_label}</label>
                                        <input
                                            name={"ottAsset"}
                                            type="text"
                                            className='form-control form-control-sm'
                                            onChange={onChangeForm}
                                            value={dataItem.ottAsset.Title ?? ""}
                                            disabled
                                        />
                                    </div>
                                    <div className='col' style={{marginLeft: "-12px", marginTop: "20px"}}>
                                        <RoundButton icon="ellipsis-h" btnColor="success" style={{color: "white"}} title={lang.select_ott_assets_header_dialog} onClick={()=> setShowOttAssets(true)}/>
                                        <RoundButton icon="trash" btnColor="danger" style={{color: "white", marginLeft: '5px'}} title={lang.delete_all_button_tooltip} onClick={()=> setDataItem({...dataItem, ottAsset: {}})}/>
                                    </div>
                                </div>}
                                <div className='row mt-3'>
                                    <div className="col-3">
                                        <Field
                                            style={{ border: "1px solid grey" }}
                                            name={"Archive"}
                                            component={Checkbox}
                                            label={lang.archive}
                                            onChange={onChangeForm}
                                        />
                                    </div>
                                    <div className="col-3 px-0">
                                        <Field
                                            style={{ border: "1px solid grey" }}
                                            name={"ExportBreaksOnly"}
                                            component={Checkbox}
                                            label={lang.ExportBreaksOnly}
                                            onChange={onChangeForm}
                                        />
                                    </div>
                                    <div className="col-3 px-0">
                                        <Field
                                            style={{ border: "1px solid grey" }}
                                            name={"IsNegativeAsrunDiff"}
                                            component={Checkbox}
                                            label={lang.is_negative_asrun_diff_label}
                                            onChange={onChangeForm}
                                        />
                                    </div>
                                    <div className="col px-0">
                                        <Field
                                            style={{ border: "1px solid grey" }}
                                            name={"IsDefault"}
                                            component={Checkbox}
                                            label={lang.is_default_label}
                                            onChange={onChangeForm}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </FormElement>
                )}
            />
        {showOttAssets && <OttAssetCollectionSelection title={lang.select_ott_assets_header_dialog} entityname={ENTITYNAME.OttAsset} closeForm={()=> setShowOttAssets(false)} setDataList={handleSelectedAssets} width="60vw" height="66vh" mode="single" />}
        </BossDialog>
    )
}

export default ChannelEditForm;