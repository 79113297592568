/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader'
import { useTranslation } from '../../locale/useTranslation'
import { Form, FormElement } from '@progress/kendo-react-form'
import { toast } from 'react-toastify'
import { utility } from '../utility/utilityProvider'
import { ENTITYNAME, FONT_WEIGHT, LOGEVENT, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from '../constant/constant'
import * as API from '../API/api';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import socket from '../socket/socket'
import { DropDownList } from '@progress/kendo-react-dropdowns'

const ThemeEditForm = (props) => {

    const lang = useTranslation();
    const navigate = useNavigate();
    const { SID } = useParams();
    const loc = useLocation();

    const blankItemAppBar = {
        iconSelectedColor: '#F7A227',
        bgColor: '#ffffff',
        color: '#ffffff',
        iconColor: '#000000',
        fontFamily: {
            SID: 21,
            Description: 'Roboto'
        },
        fontWeight: {
            _id: 4,
            Description: "w400"
        },
        iconSize: 24,
        fontSize: 18,

    }
    const blankItemMenu = {
        color: '#000000',
        bgColor: '#ffffff',
        iconColor: '#000000',
        iconSelectedColor: '#F7A227',
        fontFamily: {
            SID: 21,
            Description: 'Roboto'
        },
        fontWeight: {
            _id: 6,
            Description: "w600"
        },
        iconSize: 26,
        fontSize: 18
    }


    const blankItemRail = {
        fontSize: 18,
        color: '#000000',
        fontFamily: {
            SID: 5,
            Description: 'Coda'
        },
        fontWeight: {
            _id: 7,
            Description: "w700"
        },
        focusColor: '#000000',
        focusWidth: 10,
    }

    const blankItemGeneral = {
        bgColor: '#ffffff',
        color: '#000000',
        linkColor: '#000000',
        linkHoverColor: '#F7A227',
        pageTitleColor: '#000000',
        blockTitleColor: '#000000',
        boxShadowColor: '#000000',
        pageSkeletonColor: '#000000',
    }

    const blankItemCta = {
        Name: "",
        primary: {
            bgColor: "#ffffff",
            color: "#000000",
            borderColor: "#000000",
            borderWidth: 10
        },
        priHover: {
            bgColor: "#ffffff",
            color: "#000000",
            borderColor: "#000000",
            borderWidth: 10
        },
        secondary: {
            bgColor: "#ffffff",
            color: "#000000",
            borderColor: "#000000",
            borderWidth: 10
        },
        secHover: {
            bgColor: "#ffffff",
            color: "#000000",
            borderColor: "#000000",
            borderWidth: 10
        }
    }

    const [dataItem, setDataItem] = useState(blankItemCta);
    const [general, setGeneral] = useState(blankItemGeneral);
    const [rail, setRail] = useState(blankItemRail);
    const [menu, setMenu] = useState(blankItemMenu);
    const [appBar, setAppBar] = useState(blankItemAppBar)
    const [fontWeights, setFontWeights] = useState([])
    const [fontFamilys, setFontFamilys] = useState([])

    useEffect(() => {
        loadCombo();
    }, []);

    const loadCombo = async () => {
        let fontWeightRes = Object.keys(FONT_WEIGHT).map(x => {
            return { _id: FONT_WEIGHT[x], Description: x }
        })
        setFontWeights(fontWeightRes)
        let fontFamilyRes = await API.getDataLookup(ENTITYNAME.FontFamily);
        setFontFamilys(fontFamilyRes.data);
        if (SID > 0) {
            loadEditData(fontWeightRes, fontFamilyRes.data);
        }
    }

    const loadEditData = async (fontWeightRes, fontFamilyRes) => {
        let res = await API.getEntity(ENTITYNAME.Theme, parseInt(SID));
        console.log(res.data);

        if (res.success) {

            let appBarData = {
                ...res.data.appBar,
                fontFamily: fontFamilyRes.find(X => X.Description == res.data.appBar.fontFamily) ?? { SID: 21, Description: 'Roboto' },
                fontWeight: fontWeightRes.find(X => X._id == res.data.appBar.fontWeight) ?? { _id: 4, Description: "w400" }
            }
            setAppBar(appBarData)

            let menuData = {
                ...res.data.menu,
                fontFamily: fontFamilyRes.find(X => X.Description == res.data.menu.fontFamily) ?? { SID: 21, Description: 'Roboto' },
                fontWeight: fontWeightRes.find(X => X._id == res.data.menu.fontWeight) ?? { _id: 6, Description: "w600" }
            }
            setMenu(menuData)


            let railData = {
                ...res.data.rail,
                fontFamily: fontFamilyRes.find(X => X.Description == res.data.rail.fontFamily) ?? { SID: 5, Description: 'Coda' },
                fontWeight: fontWeightRes.find(X => X._id == res.data.rail.fontWeight) ?? { _id: 7, Description: "w700" }
            }
            setRail(railData)

            let data = {
                ...res.data,
                SID: res.data.SID ?? 0,
                Name: loc.state?.copy ? 'Copy of ' + res.data.Name : res.data.Name,
                cta: {
                    primary: {
                        bgColor: res.data.primary.bgColor,
                        color: res.data.primary.color,
                        borderColor: res.data.primary.borderColor,
                        borderWidth: res.data.primary.borderWidth,
                    },
                    priHover: {
                        bgColor: res.data.priHover.bgColor,
                        color: res.data.priHover.color,
                        borderColor: res.data.priHover.borderColor,
                        borderWidth: res.data.priHover.borderWidth,
                    },
                    secondary: {
                        bgColor: res.data.secondary.bgColor,
                        color: res.data.secondary.color,
                        borderColor: res.data.secondary.borderColor,
                        borderWidth: res.data.secondary.borderWidth,
                    },
                    secHover: {
                        bgColor: res.data.secHover.bgColor,
                        color: res.data.secHover.color,
                        borderColor: res.data.secHover.borderColor,
                        borderWidth: res.data.secHover.borderWidth,
                    }
                },
                primary: {
                    bgColor: res.data.primary.bgColor,
                    color: res.data.primary.color,
                    borderColor: res.data.primary.borderColor,
                    borderWidth: res.data.primary.borderWidth,
                },
                priHover: {
                    bgColor: res.data.priHover.bgColor,
                    color: res.data.priHover.color,
                    borderColor: res.data.priHover.borderColor,
                    borderWidth: res.data.priHover.borderWidth,
                },
                secondary: {
                    bgColor: res.data.secondary.bgColor,
                    color: res.data.secondary.color,
                    borderColor: res.data.secondary.borderColor,
                    borderWidth: res.data.secondary.borderWidth,
                },
                secHover: {
                    bgColor: res.data.secHover.bgColor,
                    color: res.data.secHover.color,
                    borderColor: res.data.secHover.borderColor,
                    borderWidth: res.data.secHover.borderWidth,
                },
            }
            setDataItem(data)

            let genData = {
                ...res.data.general,
            }
            setGeneral(genData)



        }

    }

    const onChangeCta = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }

    const onChangePrimary = (e) => {
        setDataItem({ ...dataItem, primary: { ...dataItem.primary, [e.target.name]: e.target.value } })
    }

    const onChangePriHover = (e) => {
        setDataItem({ ...dataItem, priHover: { ...dataItem.priHover, [e.target.name]: e.target.value } })
    }

    const onChangeSecondary = (e) => {
        setDataItem({ ...dataItem, secondary: { ...dataItem.secondary, [e.target.name]: e.target.value } })
    }

    const onChangeSecHover = (e) => {
        setDataItem({ ...dataItem, secHover: { ...dataItem.secHover, [e.target.name]: e.target.value } })
    }
    const onChangeGeneral = (e) => {
        setGeneral({ ...general, [e.target.name]: e.target.value })
    }

    const onChangeRail = (e) => {
        setRail({ ...rail, [e.target.name]: e.target.value })
    }

    const onChangeMenu = (e) => {
        setMenu({ ...menu, [e.target.name]: e.target.value })
    }
    const onChangeAppBar = (e) => {
        setAppBar({ ...appBar, [e.target.name]: e.target.value })
    }

    const isValid = () => {
        if (dataItem.Name == "" || dataItem.Name == undefined) {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        if (!isValid()) return;
        let saveData = {
            _id: dataItem._id,
            SID: dataItem.SID ?? 0,
            Name: dataItem.Name,
            cta: {
                primary: {
                    bgColor: dataItem.primary.bgColor,
                    color: dataItem.primary.color,
                    borderColor: dataItem.primary.borderColor,
                    borderWidth: dataItem.primary.borderWidth
                },
                priHover: {
                    bgColor: dataItem.priHover.bgColor,
                    color: dataItem.priHover.color,
                    borderColor: dataItem.priHover.borderColor,
                    borderWidth: dataItem.priHover.borderWidth
                },
                secondary: {
                    bgColor: dataItem.secondary.bgColor,
                    color: dataItem.secondary.color,
                    borderColor: dataItem.secondary.borderColor,
                    borderWidth: dataItem.secondary.borderWidth
                },
                secHover: {
                    bgColor: dataItem.secHover.bgColor,
                    color: dataItem.secHover.color,
                    borderColor: dataItem.secHover.borderColor,
                    borderWidth: dataItem.secHover.borderWidth
                },
            },
            primary: {
                bgColor: dataItem.primary.bgColor,
                color: dataItem.primary.color,
                borderColor: dataItem.primary.borderColor,
                borderWidth: dataItem.primary.borderWidth
            },
            priHover: {
                bgColor: dataItem.priHover.bgColor,
                color: dataItem.priHover.color,
                borderColor: dataItem.priHover.borderColor,
                borderWidth: dataItem.priHover.borderWidth
            },
            secondary: {
                bgColor: dataItem.secondary.bgColor,
                color: dataItem.secondary.color,
                borderColor: dataItem.secondary.borderColor,
                borderWidth: dataItem.secondary.borderWidth
            },
            secHover: {
                bgColor: dataItem.secHover.bgColor,
                color: dataItem.secHover.color,
                borderColor: dataItem.secHover.borderColor,
                borderWidth: dataItem.secHover.borderWidth
            },
            general: {
                bgColor: general.bgColor,
                color: general.color,
                linkColor: general.linkColor,
                linkHoverColor: general.linkHoverColor,
                pageTitleColor: general.pageTitleColor,
                blockTitleColor: general.blockTitleColor,
                boxShadowColor: general.boxShadowColor,
                pageSkeletonColor: general.pageSkeletonColor
            },
            menu: {
                iconSelectedColor: menu.iconSelectedColor,
                bgColor: menu.bgColor,
                color: menu.color,
                iconColor: menu.iconColor,
                fontFamily: menu.fontFamily?.Description ?? 'Normal',
                fontWeight: menu.fontWeight?._id ?? 0,
                iconSize: menu.iconSize,
                fontSize: menu.fontSize,
            },
            rail: {
                fontSize: rail.fontSize,
                color: rail.color,
                fontFamily: rail.fontFamily?.Description ?? 'Normal',
                fontWeight: rail.fontWeight?._id ?? 0,
                focusColor: rail.focusColor,
                focusWidth: rail.focusWidth
            },
            appBar: {
                iconColor: appBar.iconColor,
                iconSelectedColor: appBar.iconSelectedColor,
                bgColor: appBar.bgColor,
                color: appBar.color,
                fontFamily: appBar.fontFamily?.Description ?? 'Normal',
                fontWeight: appBar.fontWeight?._id ?? 0,
                iconSize: appBar.iconSize,
                fontSize: appBar.fontSize,
            },
            Archive: dataItem.Archive ?? false,
        }
        if (loc.state?.copy) {
            delete saveData._id;
            delete saveData.SID;
        }
        let res = await API.saveData(ENTITYNAME.Theme, saveData);
        if (res.success) {
            socket.emit(SOCKET_EVENTS.onSocketData, {
                action: SOCKET_ACTION.PLATFORM_THEME_REFRESH,
                module: MODULE.PLATFORM,
                data: "Theme updated",
            });
            let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE_THEME : LOGEVENT.INSERT_THEME, module: MODULE.THEME, data: res.data, message: res.message };
            API.SaveLogs(logData);
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT
            })
            utility.deleteLocalStorageItem(ENTITYNAME.Theme);
            if (loc.state?.copy || saveData?.SID == 0) {
                navigate("/home/theme");
            }
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    const borderStyle = {
        border: "1px solid #ced4da", /* Your existing border styles */
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", /* Add a subtle box shadow for a 3D effect */
        backgroundColor: "#FAFAFA"
    }
    return (
        <Form
            initialValues={""}
            render={(formRenderProps) => (
                <FormElement>
                    <div className='m-2 mb-5'>
                        <EditPageHeader title={`${lang.theme_setting_dialog_header}`} onSubmit={handleSubmit} onCancel={() => navigate("/home/theme")} />
                        <div className='row mb-2 '>
                            <div className='col-3 mt-2' >
                                <label htmlFor="TabView" style={{ fontSize: "17px", fontWeight: 400 }}>{lang.name_label} *</label>
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="Name"
                                    onChange={onChangeCta}
                                    value={dataItem.Name}
                                />
                            </div>
                        </div>

                        {/* appBar */}
                        <span style={{ fontSize: "17px", fontWeight: 400 }}>App Bar</span>
                        <div className="border p-2 form-group with-3d-effect" style={borderStyle}>
                            <div className='row mt-2'>
                                <div className="col-3 ">
                                    <div className="row ml-4">
                                        <div className='col-6'>Color</div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="color"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={appBar.color}
                                                onChange={onChangeAppBar}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Background Color</div>
                                        <div className='col-6'>
                                            <input
                                                style={{ width: "55px" }}
                                                type="color"
                                                name="bgColor"
                                                className="form-control form-control-sm"
                                                value={appBar.bgColor}
                                                onChange={onChangeAppBar}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Icon Color</div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="iconColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={appBar.iconColor}
                                                onChange={onChangeAppBar}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Icon Selector Color</div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="iconSelectedColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={appBar.iconSelectedColor}
                                                onChange={onChangeAppBar}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2'>
                                <div className="col-3 ">
                                    <div className="row ml-4">
                                        <div className='col-6'>Font Family</div>
                                        <div className='col-6'>
                                            <DropDownList
                                                data={fontFamilys}
                                                name="fontFamily"
                                                style={{ backgroundColor: "white" }}
                                                textField='Description'
                                                dataItemKey='_id'
                                                value={appBar.fontFamily}
                                                onChange={onChangeAppBar}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Font Weight</div>
                                        <div className='col-6'>
                                            <DropDownList
                                                data={fontWeights}
                                                name="fontWeight"
                                                style={{ backgroundColor: "white" }}
                                                textField='Description'
                                                dataItemKey='_id'
                                                value={appBar.fontWeight}
                                                onChange={onChangeAppBar}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Icon Size</div>
                                        <div className='col-6'>
                                            <input
                                                type="number"
                                                name="iconSize"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={appBar.iconSize}
                                                onChange={onChangeAppBar}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Font Size</div>
                                        <div className='col-6'>
                                            <input
                                                type="number"
                                                name="fontSize"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={appBar.fontSize}
                                                onChange={onChangeAppBar}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Menu */}
                        <span style={{ fontSize: "17px", fontWeight: 400 }}>Menu</span>
                        <div className="border p-2 form-group with-3d-effect" style={borderStyle}>
                            <div className='row mt-2'>
                                <div className="col-3 ">
                                    <div className="row ml-4">
                                        <div className='col-6'>Color</div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="color"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={menu.color}
                                                onChange={onChangeMenu}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Background Color</div>
                                        <div className='col-6'>
                                            <input
                                                style={{ width: "55px" }}
                                                type="color"
                                                name="bgColor"
                                                className="form-control form-control-sm"
                                                value={menu.bgColor}
                                                onChange={onChangeMenu}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Icon Color</div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="iconColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={menu.iconColor}
                                                onChange={onChangeMenu}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Icon Selector Color</div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="iconSelectedColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={menu.iconSelectedColor}
                                                onChange={onChangeMenu}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className="col-3 ">
                                    <div className="row ml-4">
                                        <div className='col-6'>Font Family</div>
                                        <div className='col-6'>
                                            <DropDownList
                                                data={fontFamilys}
                                                name="fontFamily"
                                                style={{ backgroundColor: "white" }}
                                                textField='Description'
                                                dataItemKey='_id'
                                                value={menu.fontFamily}
                                                onChange={onChangeMenu}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Font Weight</div>
                                        <div className='col-6'>
                                            <DropDownList
                                                data={fontWeights}
                                                name="fontWeight"
                                                style={{ backgroundColor: "white" }}
                                                textField='Description'
                                                dataItemKey='_id'
                                                value={menu.fontWeight}
                                                onChange={onChangeMenu}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Icon Size</div>
                                        <div className='col-6'>
                                            <input
                                                type="number"
                                                name="iconSize"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={menu.iconSize}
                                                onChange={onChangeMenu}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ml-4">
                                        <div className='col-6'>Font Size</div>
                                        <div className='col-6'>
                                            <input
                                                type="number"
                                                name="fontSize"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={menu.fontSize}
                                                onChange={onChangeMenu}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* RailTitle */}
                        <span style={{ fontSize: "17px", fontWeight: 400 }}>Rail Type</span>
                        <div className="border p-2 form-group with-3d-effect" style={borderStyle}>
                            <div className='row ml-3'>
                                <div className="col-4 ">
                                    <div className="row mt-1">
                                        <div className='col-4'>Font Size</div>
                                        <div className='col-4'>
                                            <input
                                                type="number"
                                                name="fontSize"
                                                className="form-control form-control-sm "
                                                style={{ width: "55px" }}
                                                value={rail.fontSize}
                                                onChange={onChangeRail}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className='col-4'>Font Color</div>
                                        <div className='col-4'>
                                            <input
                                                type="color"
                                                name="color"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={rail.color}
                                                onChange={onChangeRail}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="row ml-4 mt-1">
                                        <div className='col-4'>Font Family
                                        </div>
                                        <div className='col-4'>
                                            <DropDownList
                                                data={fontFamilys}
                                                name="fontFamily"
                                                style={{ backgroundColor: "white" }}
                                                textField='Description'
                                                dataItemKey='_id'
                                                value={rail.fontFamily}
                                                onChange={onChangeRail}
                                            />
                                        </div>
                                    </div>
                                    <div className="row ml-4 mt-4">
                                        <div className='col-4'>Font Weight
                                        </div>
                                        <div className='col-4'>
                                            <DropDownList
                                                data={fontWeights}
                                                name="fontWeight"
                                                style={{ backgroundColor: "white" }}
                                                textField='Description'
                                                dataItemKey='_id'
                                                value={rail.fontWeight}
                                                onChange={onChangeRail}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="row ml-4 mt-1">
                                        <div className='col-4'>Focus Color
                                        </div>
                                        <div className='col-4'>
                                            <input
                                                type="color"
                                                name="focusColor"
                                                style={{ width: "50px" }}
                                                className="form-control form-control-sm"
                                                value={rail.focusColor}
                                                onChange={onChangeRail}
                                            />
                                        </div>
                                    </div>
                                    <div className="row ml-4 mt-4">
                                        <div className='col-4'>Focus Width
                                        </div>
                                        <div className='col-4'>
                                            <input
                                                type="number"
                                                name="focusWidth"
                                                style={{ width: "100px" }}
                                                className="form-control form-control-sm"
                                                value={rail.focusWidth}
                                                onChange={onChangeRail}
                                            />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* general */}
                        <span style={{ fontSize: "17px", fontWeight: 400 }}>General</span>
                        <div className="border p-2 form-group with-3d-effect" style={borderStyle}>
                            <div className='row mt-3 '>
                                <div className="col-3">
                                    <div className="row ">
                                        <div className='col-6'>
                                            <label style={{ marginLeft: "40px" }} >{"Background"}</label>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="bgColor"
                                                style={{ width: "50px", marginLeft: "28px" }}
                                                className="form-control form-control-sm "
                                                value={general.bgColor}
                                                onChange={onChangeGeneral}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4 ">
                                        <div className='col-6'>
                                            <label style={{ marginLeft: "40px" }}>{"Page Title"}</label>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="pageTitleColor"
                                                style={{ width: "50px", marginLeft: "28px" }}
                                                className="form-control form-control-sm"
                                                value={general.pageTitleColor}
                                                onChange={onChangeGeneral}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row ">
                                        <div className='col-6'>
                                            <label className='ml-5'>{"Color"}</label>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="color"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={general.color}
                                                onChange={onChangeGeneral}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4">
                                        <div className='col-6'>
                                            <label className='ml-5'>{"Block Title"}</label>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="blockTitleColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={general.blockTitleColor}
                                                onChange={onChangeGeneral}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="row  ">
                                        <div className='col-6'>
                                            <label className='ml-5'>{"Link"}</label>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="linkColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={general.linkColor}
                                                onChange={onChangeGeneral}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4" >
                                        <div className='col-6'>
                                            <label className='ml-5'>{"Link Hover"}</label>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="linkHoverColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={general.linkHoverColor}
                                                onChange={onChangeGeneral}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-3">
                                    <div className="row ">
                                        <div className='col-6'>
                                            <label className='ml-5'>{"Page Skeleton"}</label>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="pageSkeletonColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={general.pageSkeletonColor}
                                                onChange={onChangeGeneral}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-4" >
                                        <div className='col-6'>
                                            <label className='ml-5'>{"Box Shadow"}</label>
                                        </div>
                                        <div className='col-6'>
                                            <input
                                                type="color"
                                                name="boxShadowColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={general.boxShadowColor}
                                                onChange={onChangeGeneral}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* CTA */}
                        <span style={{ fontSize: "17px", fontWeight: 400 }}>CTA</span>
                        <div className="border p-2 form-group with-3d-effect" style={borderStyle}>
                            <div className='row'>
                                <div className="col-3">
                                    <h3 className='mt-1 ml-3'>Primary</h3>
                                    <div className='row'>
                                        <div className='col-6 ml-3'>{"Background"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="bgColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={dataItem.primary.bgColor}
                                                onChange={onChangePrimary}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Text Color"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="color"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={dataItem.primary.color}
                                                onChange={onChangePrimary}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Border Color"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="borderColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={dataItem.primary.borderColor}
                                                onChange={onChangePrimary}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Border Width"}</div>
                                        <div className='col'>
                                            <input
                                                style={{ width: "55px" }}
                                                type="number"
                                                name="borderWidth"
                                                className="form-control form-control-sm"
                                                value={dataItem.primary.borderWidth}
                                                onChange={onChangePrimary}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <h3 className='mt-1 ml-3'>Primary Hover</h3>
                                    <div className='row'>
                                        <div className='col-6 ml-3'>{"Background"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="bgColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={dataItem.priHover.bgColor}
                                                onChange={onChangePriHover}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Text Color"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="color"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={dataItem.priHover.color}
                                                onChange={onChangePriHover}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Border Color"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="borderColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={dataItem.priHover.borderColor}
                                                onChange={onChangePriHover}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Border Width"}</div>
                                        <div className='col'>
                                            <input
                                                style={{ width: "55px" }}
                                                type="number"
                                                name="borderWidth"
                                                className="form-control form-control-sm"
                                                value={dataItem.priHover.borderWidth}
                                                onChange={onChangePriHover}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <h3 className='mt-1 ml-3'>Secondary</h3>
                                    <div className='row'>
                                        <div className='col-6 ml-3'>{"Background"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="bgColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={dataItem.secondary.bgColor}
                                                onChange={onChangeSecondary}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Text Color"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="color"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={dataItem.secondary.color}
                                                onChange={onChangeSecondary}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Border Color"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="borderColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={dataItem.secondary.borderColor}
                                                onChange={onChangeSecondary}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Border Width"}</div>
                                        <div className='col'>
                                            <input
                                                style={{ width: "55px" }}
                                                type="number"
                                                name="borderWidth"
                                                className="form-control form-control-sm"
                                                value={dataItem.secondary.borderWidth}
                                                onChange={onChangeSecondary}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <h3 className='mt-1 ml-3'>Secondary Hover</h3>
                                    <div className='row'>
                                        <div className='col-6 ml-3'>{"Background"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="bgColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={dataItem.secHover.bgColor}
                                                onChange={onChangeSecHover}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Text Color"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="color"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm"
                                                value={dataItem.secHover.color}
                                                onChange={onChangeSecHover}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Border Color"}</div>
                                        <div className='col'>
                                            <input
                                                type="color"
                                                name="borderColor"
                                                style={{ width: "55px" }}
                                                className="form-control form-control-sm "
                                                value={dataItem.secHover.borderColor}
                                                onChange={onChangeSecHover}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-1'>
                                        <div className='col-6 ml-3'>{"Border Width"}</div>
                                        <div className='col'>
                                            <input
                                                style={{ width: "55px" }}
                                                type="number"
                                                name="borderWidth"
                                                className="form-control form-control-sm"
                                                value={dataItem.secHover.borderWidth}
                                                onChange={onChangeSecHover}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </FormElement>
            )}
        />
    )
}

export default ThemeEditForm
