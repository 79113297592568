import React, { useState } from 'react'
import { useTranslation } from '../../locale/useTranslation'
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { toast } from 'react-toastify';
import * as API from '../../framework/API/api_digitalSign';
import { FILEURL } from '../../framework/constant/constant';
import * as APICSV from "../../framework/API/api";
import moment from 'moment';
import { DateOnlyCell } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { TimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import BossDialog from '../../components/BossDialog';


const DigitalSignAsrunReport = (props) => {
    const lang = useTranslation();
    const today = moment();

    const [dataItem, setDataItem] = useState({ fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'), toDate: today.format('YYYY-MM-DD') })
    const [gridData, setGridData] = useState([])
    const [loadingStatus, setLoadingStatus] = useState(lang.no_record_found_error_message)

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }

    const isValid = () => {
        if (dataItem.fromDate == undefined || dataItem.fromDate == "") {
            toast.error(lang.please_enter_from_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        if (dataItem.toDate == undefined || dataItem.toDate == "") {
            toast.error(lang.please_enter_to_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        if(dataItem.toDate < dataItem.fromDate){
            toast.error(lang.to_date_should_be_greater_than_from_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        return true;
    }

    const loadData = async () => {

        if (!isValid()) return;
        setGridData([]);
        setLoadingStatus(lang.data_loading_error_message);
        let filterData = {
            fromDate: moment(dataItem.fromDate).startOf('day').toDate().getTime(),
            toDate: moment(dataItem.toDate).endOf('day').toDate().getTime(),
            isExport: false,
            serialNumber: props?.item?.SerialNumber ?? ""
        };

        const res = await API.downloadDigitalSignAsrun(filterData)

        if (res.success) {
            if (res.data.length == 0) {
                setGridData([]);
                setLoadingStatus(`${lang.no_record_found_error_message}`);
                return;
            }
            setGridData(res.data);
            setLoadingStatus(`${lang.data_load_successfully_message}`);
            return;
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setGridData([]);
            setLoadingStatus(`${lang.no_record_found_error_message}`);
        }
    }

    const downloadExcel = async () => {
        if (gridData.length == 0) {
            toast.info(lang.no_data_found_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        let filterData = {
            fromDate: moment(dataItem.fromDate).startOf('day').toDate().getTime(),
            toDate: moment(dataItem.toDate).endOf('day').toDate().getTime(),
            isExport: true,
            serialNumber: props?.item?.SerialNumber ?? ""
        };

        var res = await API.downloadDigitalSignAsrun(filterData);

        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (
        <BossDialog
            title={'(' + props?.item?.Name + ')' + " - " + lang.asrun_report_dialog_header}
            onClose={props.cancelEdit}
            width={"1000px"}
        >
            <div className="row m-1">
                <div className="col-12">
                    <div className="row">
                        <div className="col-4">
                            <label className="form-label">{lang.from_date_label} *</label>
                            <input type="date" className="form-control form-control-sm" name="fromDate" value={dataItem.fromDate} onChange={onChange} />
                        </div>
                        <div className="col-4">
                            <label className="form-label">{lang.to_date_label} *</label>
                            <input type="date" className="form-control form-control-sm" name="toDate" value={dataItem.toDate} onChange={onChange} />
                        </div>
                        <div className="col-4 mt-4">
                            <ActionButton name={lang.show_button_text} onClick={loadData} style={{ marginLeft: '-10px', marginTop: '-5px' }} />
                            <RoundButton icon='download' onClick={() => downloadExcel()} style={{ marginLeft: '10px' }} title={lang.download_button_tooltip} />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12">
                            <Grid data={gridData} style={{ height: '70vh' }}>
                                <GridNoRecords>{loadingStatus}</GridNoRecords>
                                <Column field="time" cell={DateOnlyCell} title={lang.date_column} width={150} />
                                <Column field="time" cell={TimeCell} title={lang.time_column} width={150} />
                                <Column field="screenGroupName" title={lang.screen_group_column} width={150} />
                                <Column field="contentName" title={lang.content_column} width={150} />
                                <Column field="playlistName" title={lang.playlist_column} width={150} />
                                <Column field="serialNumber" title={lang.serial_number_column} width={150} />
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </BossDialog>

    )
}

export default DigitalSignAsrunReport
