/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api"
import { ENTITYNAME} from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import CollectionSelection from '../../framework/forms/CollectionSelection';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import { utility } from '../../framework/utility/utilityProvider';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import BossDialog from '../../components/BossDialog';

const BreakConfigurationEditForm = (props) => {

    const [mediaCategoryType, setMediaCategoryType] = useState([]);
    const [selectedAllowedMediaCategoryType, setSelectedAllowedMediaCategoryType] = useState([])
    const [openSelectedAllowedMediaCategoryType, setOpenSelectedAllowedMediaCategoryType] = useState(false);
    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        MediaCategoryType: props.item.MediaCategoryType ?? {},
        AllowedMediaCategoryTypes: selectedAllowedMediaCategoryType ?? [],
        Archive: props.item.Archive ?? false,
    }

    useEffect(() => {
        loadcombo();
        if (props.item.SID > 0) {
            loadEditData()
        }
    }, [])

    const [dataItem, setDataItem] = useState(blankDataItem);

    const loadcombo = async () => {
        let MediaCategoryTypeRes = await API.getDataLookup(ENTITYNAME.MediaCategoryType);
        let mediaCategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory);
        let filteredMediaCategory = props.isLong != undefined && mediaCategoryRes.data.filter((item) => item.isLong == props.isLong);
        let filterData = [];
        if (props.isLong != undefined) {
            filteredMediaCategory.map((item) => {
                let tempData = mediaCategoryType.data.filter((obj) => obj.MediaCategorySID == item.SID);
                filterData.push(...tempData);
            })
        }
        else {
            filterData = MediaCategoryTypeRes.data;
        }
        setMediaCategoryType(filterData);
    }

    const loadEditData = async () => {
        let res = await API.getDataLookup(ENTITYNAME.MediaCategoryType);
        let allowedMediaCategoryTypeRes = props.item.AllowedMediaCategoryTypes.map((item) => {
            return res.data.find((a) => a._id == item)
        });
        setSelectedAllowedMediaCategoryType(allowedMediaCategoryTypeRes);

    }


    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (dataItem.Description == undefined || dataItem.Description == "") {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (Object.keys(dataItem.MediaCategoryType).length == 0) {
            toast.error(`${lang.please_select_media_category_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (selectedAllowedMediaCategoryType.length == 0) {
            toast.error(`${lang.please_select_atleast_one_allow_media_category_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        return true
    }

    const onAllowMediaCategoryTypeSelection = (dataList) => {

        console.log(dataList);
        let newData = utility.checkDuplicateInData(dataList, selectedAllowedMediaCategoryType, "Description")
        setSelectedAllowedMediaCategoryType([...selectedAllowedMediaCategoryType, ...newData]);
    }

    const viewCollectionSelection = (data) => {
        console.log(data.target.name)

        if (Object.keys(dataItem.MediaCategoryType).length == 0) {
            toast.error(`${lang.please_select_media_category_type_first_media_episode_group_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        setOpenSelectedAllowedMediaCategoryType(!openSelectedAllowedMediaCategoryType);
    }
    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                SID: dataItem.SID ?? 0,
                Description: dataItem.Description,
                MediaCategoryType: {
                    _id: dataItem.MediaCategoryType._id,
                    SID: dataItem.MediaCategoryType.SID,
                    Description: dataItem.MediaCategoryType.Description,
                    MediaCategorySID: dataItem.MediaCategoryType.MediaCategorySID,
                },
                AllowedMediaCategoryTypes: selectedAllowedMediaCategoryType.length > 0 ? selectedAllowedMediaCategoryType.map(x => x._id) : [],
                Archive: dataItem.Archive ?? false,
                checkDuplicate: true,
                query: [["Description", "=", dataItem.Description]]
            }
            console.log(saveData);

            let res = await API.saveData(ENTITYNAME.BreakConfiguration, saveData);
            if (res.success) {
                props.refresh();
                props.cancelEdit();
                return;
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }
    const deleteSelectedValue = (dataItem) => {
        setSelectedAllowedMediaCategoryType(selectedAllowedMediaCategoryType.filter((obj) => dataItem.SID != obj.SID));
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const MySelectedCommandCell = (props) => (
        <td style={{ padding: "0px" }}><DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteSelectedValue(props.dataItem), () => { })} /></td>
    )

    return (
        <>
            <BossDialog
                title={props.item.Description ? props.item.Description : `${lang.create_break_configuration_dialog_header}`}
                onClose={props.cancelEdit}
                width="1000px"
            >
                <EditPageHeader onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                <Form
                    onSubmit={handleSubmit}
                    initialValues={dataItem}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.description_label} *</label>
                                            <input
                                                name={"Description"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                component={Input}
                                                onChange={onChange}
                                                value={dataItem.Description}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>{lang.media_category_type_label} *</label>
                                            <DropDownList
                                                style={{ backgroundColor: 'white' }}
                                                data={mediaCategoryType}
                                                name="MediaCategoryType"
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.MediaCategoryType}
                                                onChange={onChange}
                                                disabled={props.item.SID > 0}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-6" style={{ marginTop: "25px", marginBottom: "0px" }}  >
                                            <Field
                                                style={{border: "1px solid grey"}}
                                                name={"Archive"}
                                                component={Checkbox}
                                                label={lang.archive}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
                <div className="row mt-2">
                    <div className="col-12">
                        <AddRoundButton title={lang.add_media_episode_button_tooltip} onClick={viewCollectionSelection} />
                        <Grid data={selectedAllowedMediaCategoryType} style={{ height: "35vh", marginTop: "04px", overflow: "hidden" }}>
                            <GridColumn cell={MySelectedCommandCell} width={"40px"} locked={true} />
                            <Column field="Description" title={lang.description_column} editable={false} />
                            <Column field="MediaCategorySID" title={lang.media_category_type_column} editable={false} />
                            <Column field="MaterialType" title={lang.material_type_column} editable={false} />

                        </Grid>
                    </div>
                </div>
            </BossDialog>
            {openSelectedAllowedMediaCategoryType && <CollectionSelection entityname={ENTITYNAME.MediaCategoryType} title={lang.media_category_type_collection_header} closeForm={() => setOpenSelectedAllowedMediaCategoryType(!openSelectedAllowedMediaCategoryType)} wherestatement={['MediaCategorySID', '=', dataItem.MediaCategoryType.MediaCategorySID]} setDataList={onAllowMediaCategoryTypeSelection} width={"50vw"} checkDuplicate={true} />}

        </>
    )
}

export default BreakConfigurationEditForm;