/* eslint-disable */
import React, { useEffect, useState } from 'react'
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ENTITYNAME, ACTIVITY, OFFSET, LOCALSTORAGE_KEY } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { utility } from '../../framework/utility/utilityProvider';
import moment from 'moment';
import { useTranslation } from '../../locale/useTranslation';
import { toast } from 'react-toastify';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import BossDialog from '../../components/BossDialog';

export const CopySchedule = (props) => {

    const preferenceData = utility.getValue(LOCALSTORAGE_KEY.planningpreference);
    const offSetHours = new Date(preferenceData?.data[0]?.value?.TimeZone?.DayStartTime ?? 0).getUTCHours();
    const offsetTime = offSetHours * 3600000;

    const startDate = moment(props.plannerPayload.date).startOf('week').format('YYYY-MM-DD');
    const endDate = moment(props.plannerPayload.date).endOf('week').format('YYYY-MM-DD');
    const currentUser = utility.getValue(LOCALSTORAGE_KEY.userData);

    var defaultDataItem = {
        FromDate: startDate,
        ToDate: endDate,
        CopyTo_FromDate: startDate,
        CopyTo_ToDate: endDate,
        Channel: props.plannerPayload.channels[0],
        Activity: ACTIVITY[0],
        Move: false
    }

    const [channel, setChannel] = useState([]);
    const [dataItem, setDataItem] = useState(defaultDataItem);
    const [statusMessage, setStatusMessage] = useState("");
    const [showInvalidDataGrid, setShowInvalidDataGrid] = useState(false);
    const [inValidShortFormData, setInValidShortFormData] = useState([]);
    const [disable, setDisable] = useState(false);

    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel);
        setChannel(channelRes.data);
    }

    const onChange = (e) => {
        if (e.target.name == 'ToDate') {
            let f = moment(new Date(dataItem.FromDate))
            let t = moment(new Date(e.target.value));
            var dayDifference = t.diff(f, 'days');

            setDataItem({
                ...dataItem,
                CopyTo_ToDate: moment(new Date(dataItem.CopyTo_FromDate)).add(dayDifference, 'days').format('YYYY-MM-DD'),
                [e.target.name]: e.target.value
            });
        } else if (e.target.name == 'Move') {
            setDataItem({ ...dataItem, Move: !dataItem.Move });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const onCopySchedule = async () => {
        setDisable(true);

        var copyTo_FromDate = utility.getLocalDateTimeToUtcMiliseconds(utility.removeTimeFromDateTime(new Date(dataItem.CopyTo_FromDate)));
        var copyTo_ToDate = utility.getLocalDateTimeToUtcMiliseconds(utility.removeTimeFromDateTime(new Date(dataItem.CopyTo_ToDate)));

        const fromDateWithoutTime = utility.removeTimeFromDateTime(new Date(dataItem.FromDate));
        var fromDate = utility.getLocalDateTimeToUtcMiliseconds(fromDateWithoutTime);

        const toDateWithoutTime = utility.removeTimeFromDateTime(new Date(dataItem.ToDate));
        var toDate = utility.getLocalDateTimeToUtcMiliseconds(toDateWithoutTime);

        if (fromDate > toDate) {
            toast.error(`${lang.invalid_date_range_copy_from_planning_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setStatusMessage(`${lang.invalid_date_range_copy_from_planning_error_message}`);
            setDisable(false);
            return;
        }

        if ((toDate - fromDate) / 86400000 >= 7) {
            toast.error(`${lang.date_range_between_seven_day_copy_from_planning_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setStatusMessage(() => `${lang.date_range_between_seven_day_copy_from_planning_error_message}`);
            setDisable(false);
            return;
        }

        if (copyTo_FromDate > copyTo_ToDate) {
            toast.error(`${lang.invalid_date_range_copy_to_planning_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setStatusMessage(`${lang.invalid_date_range_copy_to_planning_error_message}`);
            setDisable(false);
            return;
        }

        if ((copyTo_ToDate - copyTo_FromDate) / 86400000 >= 7) {
            toast.error(`${lang.date_range_between_seven_day_copy_to_planning_error_message}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setStatusMessage(() => `${lang.date_range_between_seven_day_copy_to_planning_error_message}`);
            setDisable(false);
            return;
        }

        //CHECK DAYS DIFF.
        if ((toDate - fromDate) != (copyTo_ToDate - copyTo_FromDate)) {
            toast.error(`${lang.copy_from_and_copy_to_date_range_should_be_same}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setStatusMessage(() => `${lang.copy_from_and_copy_to_date_range_should_be_same}`);
            setDisable(false);
            return;
        }

        setStatusMessage(() => `${lang.data_copy_schedule_please_wait_copyschedule_error_message}`);

        //make CopyToDate into range of fromDate - toDate

        var data = {
            payload: {
                CopyFromDate: fromDate + offsetTime,
                CopyTillDate: utility.addTimeToDateInMilliseconds(toDate, 23, 59, 59, 999) + offsetTime,
                CopyTo_FromDate: copyTo_FromDate + offsetTime,
                CopyTo_ToDate: copyTo_ToDate + offsetTime,
                FromChannel: dataItem.Channel,
                Activity: dataItem.Activity,
                Move: dataItem.Move
            },
            user: currentUser
        }

        // copySchedule
        var res = await API.copySchedule(data);
        console.log(res);
        if (res.success) {
            setStatusMessage(res.message);
            setInValidShortFormData(res.data.invalidShortForms ?? []);
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            //setStatusMessage(() => `${lang.copy_schedule_data_copyschedule_successfully_message}`);
            props.setDoLoad(true);
            props.closeForm();
        } else {
            setStatusMessage(res.message);
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            setInValidShortFormData(res.data.invalidShortForms ?? [])
            // setStatusMessage(() => `${lang.no_data_found_to_copy_schedule_error_message}`);
        }
        setDisable(false);

    }

    return (<div>
        <BossDialog
            title={lang.copy_transfer_schedule_header_dialog}
            onClose={props.closeForm}
            width={"860px"}
        >
            <div className='row' style={{display: 'flex', marginLeft: '-25px'}}>
                <div className="col">
                    <ActionButton title={lang.create_button_tootltip} name={lang.create_button_text} onClick={onCopySchedule} disabled={disable} />
                    <ActionButton style={{marginLeft: '5px', borderRadius: '5px'}} title={lang.cancel_button_tooltip} name={lang.cancel_button_text} onClick={props.closeForm} btnColor={'danger'} disabled={disable} />
                    {inValidShortFormData.length > 0 && <ActionButton style={{marginLeft: '5px', borderRadius: '5px'}} title={lang.invalid_short_forms} name={lang.invalid_tooltip} onClick={() => setShowInvalidDataGrid(true)} btnColor={'info'} disabled={disable} />}
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-12'>
                    <div style={{ margin: "0px 0px 0px 0px", border: '1px solid #c4c2c2', borderRadius: '5px!important' }}>
                        <div className='row mt-2 mr-1 ml-1'>
                            <div className='col-6' >
                                <div style={{ border: '1px solid #c4c2c2', borderRadius: '5px', pading: '5px 10px' }} >
                                    <div className='row m-1'>
                                        <div className='col'>{lang.copy_from_label}</div>
                                        <div className='col text-right' style={{color: 'gray', fontSize: '11px', marginTop: '2px', marginRight: '-10px'}}>
                                            ({lang.selected_days_label} : {(new Date(dataItem.ToDate) - new Date(dataItem.FromDate)) / 86400000 + 1})
                                        </div>
                                    </div>
                                    <div className='row m-1'>
                                        <div className='col-6'>
                                            <div className="form-group">
                                                <label htmlFor="">{lang.from_label}</label>
                                                <input
                                                    type="date"
                                                    className="form-control form-control-sm"
                                                    name="FromDate"
                                                    value={dataItem.FromDate}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className="form-group">
                                                <label htmlFor="">{lang.to_label}</label>
                                                <input
                                                    type="date"
                                                    className="form-control form-control-sm"
                                                    name="ToDate"
                                                    value={dataItem.ToDate}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-6'>
                                <div style={{ border: '1px solid #c4c2c2', borderRadius: '5px', pading: '5px 10px' }}>
                                    <div className='row m-1'>
                                        <div className='col'>{lang.copy_to_label}</div>
                                        <div className='col text-right' style={{color: 'gray', fontSize: '11px', marginTop: '2px', marginRight: '-10px'}}>
                                            ({lang.selected_days_label} : {(new Date(dataItem.CopyTo_ToDate) - new Date(dataItem.CopyTo_FromDate)) / 86400000 + 1})
                                        </div>
                                    </div>
                                    <div className='row m-1'>
                                        <div className='col-6'>
                                            <div className="form-group">
                                                <label htmlFor="">{lang.from_label}</label>
                                                <input
                                                    type="date"
                                                    className="form-control form-control-sm"
                                                    name="CopyTo_FromDate"
                                                    value={dataItem.CopyTo_FromDate}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className="form-group">
                                                <label htmlFor="">{lang.to_label}</label>
                                                <input
                                                    type="date"
                                                    className="form-control form-control-sm"
                                                    name="CopyTo_ToDate"
                                                    value={dataItem.CopyTo_ToDate}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mr-1 ml-1 mt-2'>
                            <div className='col-5'>
                                <div className="form-group">
                                    <label htmlFor="TabView">{lang.from_channel_label}</label>
                                    <DropDownList
                                        style={{
                                            backgroundColor: "white",
                                        }}
                                        data={channel}
                                        name="Channel"
                                        textField="FullChannelName"
                                        dataItemKey="_id"
                                        value={dataItem.Channel}
                                        onChange={onChange}
                                        validator={(value) => value ? "" : "Please select the value"}
                                    />
                                </div>
                            </div>
                            <div className='col-5'>
                                <div className="form-group">
                                    <label htmlFor="TabView">{lang.activity_label}</label>
                                    <DropDownList
                                        style={{
                                            backgroundColor: "white",
                                        }}
                                        data={ACTIVITY}
                                        name="Activity"
                                        textField="Description"
                                        dataItemKey="ID"
                                        value={dataItem.Activity}
                                        onChange={onChange}
                                        validator={(value) => value ? "" : "Please select the value"}
                                    />
                                </div>
                            </div>
                            <div className='col-2' style={{ margin: '25px 0px 0px 0px' }}>
                                <input type="checkbox" name="Move" value={dataItem.Move} checked={dataItem.Move} onChange={onChange} />
                                <label className="pl-1" htmlFor="Move">{'Move'}</label>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12" style={{ textAlign: "left" }}>
                    <div className="form-group">
                        <div style={{ height: "45px", backgroundColor: "#cce5ed" }} className="form-control form-control-sm" name="DisplayName">{statusMessage}</div>
                    </div>
                </div>
            </div>

        </BossDialog>

        {showInvalidDataGrid && <BossDialog
            title={'Expired Short Forms'}
            onClose={() => setShowInvalidDataGrid(false)}
            width={"500px"}
        >
            <div className='row'>
                <div className='col'>
                    <Grid data={inValidShortFormData} style={{ height: "20vh" }} className="mt-2">
                        <GridColumn field="mediaEpisode.Title" title={lang.title_label} />
                        <GridColumn field="mediaEpisode.AssetId" title={lang.asset_id_label} />
                        <GridColumn field="mediaEpisode.MediaCategoryType.Description" title={lang.media_category_type_label} />
                    </Grid>
                </div>
            </div>
        </BossDialog>}
    </div>
    )
}
