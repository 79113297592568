/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column, GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { ENTITYNAME, MODULETYPES, FILEURL, REPORT_TYPE, LOCALSTORAGE_KEY, DATETIMEFORMATS, EXPORT_TEMPLATE_MODULES } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import BossDialog from "../../components/BossDialog";

const ColorCell = (props) => {

    const field = props.field || "";
    let value = '#000000';


    if (field.includes('.')) {
        //For field value coming as example :- MediaCategory.Duration
        let keys = field.split('.');
        value = props.dataItem[keys[0]] ?
            props.dataItem[keys[0]][keys[1]] ? props.dataItem[keys[0]][keys[1]] : '#000000'
            : '';
    } else {
        value = props.dataItem[field];
    }

    return <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...useTableKeyboardNavigation(props.id)}
        //BY COMMENTING WITH LINE FOR ONCLICK EVENT SELECTION FOR GRID BY CLICKING TIME CELL IS NOT WORKING
        {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        // style={{textAlign : 'center'}}
        style={props.style}
    >
        <input type="color" className="form-control form-control-sm" name="TextColor" value={value} onChange={() => { }} disabled={true} />
    </td>;
};

export const ExportTemplate = (props) => {

    const blankDataItem = { ModuleType: props?.type ?? EXPORT_TEMPLATE_MODULES[0], AvailableColumn: {}, Name: '', TextColor: "#000000", gridData: {}, Format: { Type: '' }, DateTime: false, Language: {}, TimeZone: {}, IsAddBlank: false };
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [availableColumn, setAvailableColumn] = useState([]);
    const [timeZones, setTimeZones] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [languages, setLanguages] = useState([]);
    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
        if (props.item.SID > 0) {
            loadEditData();
        }
    }, []);

    const loadcombo = async () => {
        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        console.log(blankDataItem.ModuleType.EntityName)
        let availableColumnRes = await API.getFormData(blankDataItem.ModuleType.EntityName, currentUserId);
        setAvailableColumn(availableColumnRes);
        let langRes = await API.getDataLookup(ENTITYNAME.Language, { query: ['Archive', '=', false] });
        setLanguages(langRes.data);
        let timezoneRes = await API.getDataLookup(ENTITYNAME.TimeZone, { query: ['Archive', '=', false] });
        setTimeZones(timezoneRes.data);
    }

    const borderWithTitle = {
        position: "relative",
        fontWeight: "500",
        fontSize: "12px",
        top: "-0.8em",
        marginLeft: "0.8em",
        display: "inline",
        backgroundColor: "white"
    };

    const onChange = (e) => {
        if (e.target.name == "AvailableColumn") {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value, ["DisplayName"]: e.target.value.label })
        }
        else if (e.target.name == "DateTime") {
            setDataItem({ ...dataItem, DateTime: !dataItem.DateTime })
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };

    const isValidforAddColumn = () => {
        if (!dataItem.AvailableColumn || Object.keys(dataItem.AvailableColumn).length === 0) {
            toast.error(`${lang.please_select_the_value_in_available_column_export_template_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.DisplayName === "") {
            toast.error(`${lang.please_enter_display_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.DateTime && (dataItem.Format == "" || dataItem.Format == undefined)) {
            toast.error(`${lang.please_select_date_format_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const isValid = () => {
        if (dataItem.Name === "" || dataItem.Name == undefined) {
            toast.error(`${lang.please_enter_savetempleteas_export_template_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.ModuleType).length === 0) {
            toast.error(`${lang.please_select_module_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (gridData.length === 0) {
            toast.error(`${lang.please_add_column_atleast_one_item_export_template_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const loadAddData = async (IsAddBlank = false) => {
        if (isValidforAddColumn()) {
            setDataItem({ ...dataItem, AvailableColumn: {}, DisplayName: '', Format: '', TextColor: '#000000', DateTime: false, Language: {}, TimeZone: {} });
            generateColumnData(IsAddBlank);
        }
    }


    const generateColumnData = function (IsAddBlank) {
        const addColumn = {
            AvailableColumn: dataItem.AvailableColumn,
            DisplayName: dataItem.DisplayName,
            Format: dataItem.DateTime ? dataItem.Format?.Type : '',
            DateTime: dataItem.DateTime ?? false,
            Language: dataItem.Language ?? '',
            TimeZone: dataItem.TimeZone ?? '',
            TextColor: dataItem.TextColor ?? '#000000',
            IsAddBlank: IsAddBlank
        };
        console.log(addColumn)
        setGridData((old) => [...old, addColumn]);
    }
    const onSave = async () => {
        if (isValid()) {
            const saveData = {
                SID: props.item.SID > 0 ? props.item.SID : 0,
                ModuleType: dataItem.ModuleType,
                Name: dataItem.Name,
                Columns: gridData,
                Archive: dataItem.Archive ?? false,
            }
            let res = await API.saveData(ENTITYNAME.ExportTemplate, saveData);
            if (!res.success) {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.success(dataItem.Name + `${lang.is_saved_successfully_export_template_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                utility.deleteLocalStorageItem(ENTITYNAME.ExportTemplate);
                setDataItem(blankDataItem);
                setGridData([]);
                props.cancelEdit();
                props.refresh();
            }
        }
    }

    function moveItem(from, to) {

        if (to < 0) {
            return true;
        }
        else {
            // remove from index and store it
            let f = gridData.splice(from, 1)[0];
            // insert stored item into position to Index
            gridData.splice(to, 0, f);
            setGridData((old) => [...gridData]);
        }


    }

    const removeItemFromGrid = function (props) {
        gridData.splice(props.dataIndex, 1);
        setGridData((old) => [...gridData]);
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });

    }

    const loadEditData = async () => {
        setGridData(props.item.Columns);
        setDataItem(props.item);
    }

    const menuModel = (props) => {

        const menu = [
            {
                label: 'Options',
                items: [
                    { label: 'Move Up', icon: 'pi pi-fw pi-arrow-up', command: () => props.onMoveUp() },
                    { label: 'Move Down', icon: 'pi pi-fw pi-arrow-down', command: () => props.onMoveDown() },
                    { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => props.onDelete() },
                ]
            }
        ]

        return menu;
    }

    const MyCommandCell = (props) => (
        <MenuCommandCell
            {...props}
            onMoveUp={() => { moveItem(props.dataIndex, props.dataIndex - 1) }}
            onMoveDown={() => { moveItem(props.dataIndex, props.dataIndex + 1) }}
            onDelete={() => ConfirmDeleteAlert(() => removeItemFromGrid(props), () => { })}
            myMenuModel={menuModel}
        />
    )

    return (<>
        {<BossDialog title={props.item.SID > 0 ? dataItem.Name : `${lang.export_template_page_breadcrumb}`} width={"1100px"} onClose={props.cancelEdit}>
            <EditPageHeader title={`${lang.export_template_editpageheader} -> ` + (dataItem.Name ? dataItem.Name : `${lang.new_title_editpageheader}`)} onSubmit={onSave} onCancel={props.cancelEdit} />
            <div className="col-12 mt-2" style={{ margin: "01px 0px 0px 0px", border: '1px solid black' }}>
                <div className="row mt-2">
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.save_template_as_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name || ""} onChange={onChange} required />
                        </div>
                    </div>
                    <div className="col-4">

                        <div className="form-group">
                            <label htmlFor="TabView">{lang.module_name_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={MODULETYPES}
                                name="ModuleType"
                                textField="Description"
                                dataItemKey="SID"
                                value={dataItem.ModuleType}
                                onChange={onChange}
                                disabled={true}

                            />
                        </div>
                    </div>
                </div>

                <div style={{ height: dataItem.DateTime ? "50vh" : "40vh", margin: "1px 0px 15px 0px", border: '1px solid black', borderRadius: '5px!important' }}>
                    <div style={borderWithTitle}>&nbsp; {lang.selected_Columns_label} &nbsp;</div>
                    <div className="row ml-1">
                        <div className="col-3" style={{ padding: '0px 0px 0px 16px' }}>
                            <div className="form-group">
                                <label htmlFor="TabView">{lang.available_Column_label} *</label>
                                <DropDownList
                                    style={{
                                        backgroundColor: "white",
                                    }}
                                    data={availableColumn}
                                    name="AvailableColumn"
                                    textField="label"
                                    dataItemKey="_id"
                                    value={dataItem.AvailableColumn}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="row">
                                <div style={{ padding: '0px 2px 0px 16px' }} className="col-9 form-group">
                                    <label htmlFor="">{lang.display_name_label} *</label>
                                    <input type="text" className="form-control form-control-sm" name="DisplayName" value={dataItem.DisplayName} onChange={onChange} />
                                </div>
                                <div style={{ padding: '18px 16px 0px 2px' }} className="col-3 form-group">
                                    {/* <label htmlFor="">{lang.text_color_label} *</label> */}
                                    <input type="color" className="form-control form-control-sm" name="TextColor" value={dataItem.TextColor} onChange={onChange} title={lang.text_color_label} />
                                </div>
                            </div>
                            {(dataItem?.AvailableColumn?.label == "Synopsis" || dataItem?.AvailableColumn?.name == "LanguageCode") ?
                                <div className="form-group">
                                    <label htmlFor="">{lang.language_label}</label>
                                    <DropDownList
                                        style={{ backgroundColor: "white" }}
                                        data={languages}
                                        name="Language"
                                        textField="Description"
                                        dataItemKey="SID"
                                        value={dataItem.Language}
                                        onChange={onChange}
                                    />
                                </div> :
                                <> <div className="form-group">
                                    <label htmlFor="">{lang.date_time_label}</label>
                                    <input type="checkbox" style={{ height: '15px', width: '15px', margin: '0px 2px 0px 5px' }} name="DateTime" value={dataItem.DateTime} checked={dataItem.DateTime} onChange={onChange} />
                                </div>
                                    {dataItem.DateTime && <div className="form-group">
                                        <label htmlFor="">{lang.format_label}</label>
                                        <DropDownList
                                            style={{ backgroundColor: "white" }}
                                            data={DATETIMEFORMATS}
                                            name="Format"
                                            textField="Type"
                                            dataItemKey="ID"
                                            value={dataItem.Format}
                                            onChange={onChange}
                                        />
                                        <label className="mt-2" htmlFor="TabView">{lang.time_zone_label}</label>
                                        <DropDownList
                                            style={{
                                                backgroundColor: "white",
                                            }}
                                            data={timeZones}
                                            name="TimeZone"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.TimeZone}
                                            onChange={onChange}
                                        />
                                    </div>} </>}
                            <div className="row">
                                <div className="col-6" style={{ textAlign: "left" }}>
                                    <ActionButton title={lang.add_blank_button_tooltip} name={lang.add_blank_button_text} style={{ margin: '0px', borderRadius: '5px' }} onClick={() => loadAddData(true)} />
                                </div>
                                <div className="col-6" style={{ textAlign: "right" }}>
                                    <ActionButton title={lang.add_column_button_tooltip} name={lang.add_column_button_text} onClick={() => loadAddData(false)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-9">
                            <Grid data={gridData} resizable={true} style={{ height: dataItem.DateTime ? "45vh" : "35vh", margin: "0px 10px 10px 10px", border: '1px solid black', borderRadius: '5px!important' }}>
                                <Column cell={MyCommandCell} width={"60px"} locked={true} />
                                <Column field="AvailableColumn.name" title={lang.available_Column} width={"150px"}/>
                                <Column field="DisplayName" title={lang.display_column}  width={"200px"}/>
                                <Column field="Format" title={lang.format_name_column} width={"130px"}/>
                                <Column field="DateTime" title={lang.date_time_column} width={"100px"}/>
                                <Column field="Language.Description" title={lang.language_column} width={"100px"}/>
                                <Column field="TimeZone.Description" title={lang.time_zone_column} width={"100px"}/>
                                <Column field="TextColor" title={lang.text_color_label} cell={ColorCell} width={"80px"}/>
                                <Column field="IsAddBlank" title={lang.add_blank_label} width={"100px"}/>
                            </Grid>
                        </div>

                    </div>
                </div>
            </div>
        </BossDialog>}
    </>)
}
export default ExportTemplate;