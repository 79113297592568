/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import {ENTITYNAME,FILEURL,LOCALSTORAGE_KEY,} from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn as Column, GridNoRecords,} from "@progress/kendo-react-grid";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import PlanningFilter from "../Planning/PlanningFilter";
export const MediaMasterReport = (props) => {
  const blankDataItem = {
  };

  const lang = useTranslation();
  const [dataItem, setDataItem] = useState(blankDataItem);
  const [gridData, setGridData] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(
    lang.no_record_found_error_message
  );
  const [isMinified, setIsMinified] = useState(
    utility.getValue(LOCALSTORAGE_KEY.isMenuMified)
  );
  const [enableFilter, setEnableFilter] = useState(false);
  const [filterData, setFilterData] = useState(props?.item?.Filter ?? undefined);


  const toGetFilteredData = (data) => {
    console.log(data)
    setEnableFilter(false);
    setFilterData(data);
    loadData(data);
  };

  const onChange = async (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  };
 
  const isValid = () => {
    return true;
  };

  const loadData = async (filterData, download = false) => {
    if (!isValid()) return;

    setLoadingStatus(lang.data_loading_error_message);

    let finalFilterData = []
    console.log(filterData);
    
    if(Object.keys(filterData).length > 0){
        let mediaCategoryFilter = filterData?.MediaCategory.length > 0 ? [["MediaCategory._id", "in", filterData?.MediaCategory.map(x=>x._id)]] : [];
        let channelFilter = filterData?.Channel.length !=0 ? filterData?.Channel.map(x=>x.SID) : [] ;
        let genreFilter = filterData?.Genres?.length > 0 ? [["Genres._id" , "in" , filterData?.Genres.map(x=>x._id)]] : [] ;
        let castAndCrewFilter = Object.keys(filterData.CastType).length > 0 ?
        [['Metadata.CastAndCrew', 'elemMatch', { Name: filterData.CastName, CastType: filterData.CastType }]] : [];
        let releaseYearFilter = filterData?.ReleaseYear && filterData.ReleaseYear != "" ?  [["ReleaseDate", ">=", new Date(`${filterData.ReleaseYear}-01-01`).getTime()], ["ReleaseDate", "<=", new Date(`${filterData.ReleaseYear}-12-31`).getTime()]] : [];
        let languageFilter = filterData?.Languages?.length > 0 ? [["MetaData.Languages._id" , "in" , filterData?.Languages.map(x=> x._id) ]] : [];
        let pgRatingFilter = filterData?.PGRating?.length > 0 ? [["MetaData.PGRating._id", "in", filterData?.PGRating.map(x=> x._id)]] : [];
        // let exibition = filterData?.Exhibitions?.length > 0 && Object.keys(filterData.Exhibitions).length > 0 ?
        // [['PlayTime.Exhibitions', 'elemMatch', ]] : [];
        let createdFromFilter = filterData?.CreatedFrom ? [["addOn", ">=", new Date(`${filterData.CreatedFrom} 00:00:00`).getTime()]] : [];
        let createdToFilter = filterData?.CreatedTo ? [["addOn", "<=", new Date(`${filterData.CreatedTo} 23:59:59`).getTime()]] : [];
       finalFilterData= [...mediaCategoryFilter,...channelFilter, ...genreFilter, ...castAndCrewFilter, ...releaseYearFilter, ...languageFilter, ...pgRatingFilter, ...createdFromFilter, ...createdToFilter] 
    }

    const res = await API.getMediaMasterReport(finalFilterData,download);

    if (res.success) {
      if (download) {
        window.open(FILEURL.BASEURL + 'downloadReport/' + res.fileName)
        toast.success('Successfully Exported!', {
            position: toast.POSITION.TOP_RIGHT
        });  
        return;
      } 

      if (res.data && res.data.length == 0) {
        setGridData([]);
        setLoadingStatus(lang.no_record_found_error_message);
        return;
      }
      
      setGridData(res.data);
      setLoadingStatus(lang.data_load_successfully_message);

      return;
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setGridData([]);
      setLoadingStatus(lang.no_record_found_error_message);
    }
  };

  const downloadExcel = async () => {
    if (gridData.length == 0) {
      toast.info(lang.no_data_found_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    await loadData(filterData, true); 
  }

  const clearFilter = () => {
    setFilterData(undefined)
    setGridData([])
    setLoadingStatus(lang.no_record_found_error_message)
  }
 
  return (
    <>
      <h1 className="page-title txt-color-bludeDark">
        <i
          className="fa fa-table marlr"
          style={{ fontSize: "20px", color: "GrayText" }}
        ></i>
        <span>{lang.media_master_report_page_breadcrumb}</span>
      </h1>
      <div className="row m-1">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <div className="row mt-3">
                <div
                  className="col-10"
                  style={{ padding: "12px 0px 0px 16px" }}
                >
                  <div className="form-group" style={{ marginTop: "-30px" }}>
                    <label htmlFor="TabView">{lang.select_rule_label}</label>
                    <input
                      name="EnableFilter"
                      type="text"
                      className="form-control form-control-sm"
                      value={filterData?.Template?.Name ?? ''}
                        onChange={onChange}
                      disabled={true}
                    />
                  </div>
                </div>
                <div
                  className="col-2 d-flex"
                  style={{ padding: "0px 16px 0px 0px", marginTop: "-11px" }}
                >
                  <ActionButton
                    style={{ marginTop: "18px", height: "30px" }}
                    btnColor={"success"}
                    title={lang.select_button_tooltip}
                    name={"..."}
                    onClick={() => setEnableFilter(true)}
                  />
                  <ActionButton
                    style={{ marginTop: "18px", height: "30px" }}
                    btnColor={"danger"}
                    title={lang.clear_button_tooltip}
                    name={""}
                    icon={"xmark"}
                    onClick={clearFilter}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 mt-4"> 
              <RoundButton
                icon="download"
                onClick={downloadExcel}
                style={{ marginLeft: "-16px" }}
                title={lang.download_button_tooltip}
              />
            </div>
          </div>
          {enableFilter && (
            <PlanningFilter
              toGetFilterDataItem={toGetFilteredData}
              hideChannel={true}
              saveButtonName={lang.search_button_text}
              showDefaultButton={false}
              filterDataItem={filterData}
              setTemplate={setFilterData}
              onClose={setEnableFilter}
            />
          )}
          <div className="row mt-3">
            <div className="col-12">
              <Grid data={gridData} style={{ height: "66vh" }}>
                <GridNoRecords>{loadingStatus}</GridNoRecords>
                <Column field="Title" 
                title={lang.title_column} 
                width={150} 
                />
                <Column
                  field="AssetId"
                  title={lang.assetId_column}
                  width={100}
                />
                <Column
                  field="Description"
                  title={lang.description_column}
                  width={170}
                />
                <Column
                  field="MediaCategory.Description"
                  title={lang.media_category_column}
                  width={150}
                />
                <Column
                  field="MediaCategoryType.Description"
                  title={lang.media_category_type_column}
                  width={150}
                />
                <Column
                  field="Duration"
                  title={lang.duration_column}
                  cell={TimeCell}
                  width={100}
                />
                <Column
                  field="Genres"
                  format={"Description"}
                  title={lang.genres_column}
                  cell={ArrayCell}
                  width={150}
                />
                <Column
                  field="Brand.Name"
                  title={lang.brand_column}
                  width={150}
                />
                <Column
                  field="Product.Name"
                  title={lang.product_column}
                  width={150}
                />
                <Column
                  field="MetaData.Languages"
                  format={"Description"}
                  title={lang.language_column}
                  cell={ArrayCell}
                  width={150}
                />
                <Column
                  field="MetaData.PGRating.Description"
                  title={lang.pg_rating_column}
                  width={150}
                />
                <Column
                  field="MetaData.CastAndCrew"
                  format={"Description"}
                  title={lang.cast_and_crew_column}
                  cell={ArrayCell}
                  width={150}
                />
                <Column
                  field="MetaData.CensorRating.Description"
                  title={lang.censor_rating_column}
                  width={150}
                />
                <Column
                  field="MetaData.ProductionYear"
                  title={lang.production_year_column}
                  width={150}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          isMinified
            ? "scheduleFooter scheduleFooterMinified"
            : "scheduleFooter"
        }
      >
        <div className="flex-container-reverse flex-item-auto p-2">
          <div className="footerText">{loadingStatus}</div>
        </div>
      </div>
    </>
  );
};
export default MediaMasterReport;
