import React, { useEffect, useState } from 'react'
import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import { useTranslation } from '../../../locale/useTranslation';
import { TimeCell } from '../../../framework/forms/helpercomponents/CustomGridCells/TimeCell';

import { SCHEDULECOLORPREFERENCE } from '../../../framework/constant/constant';


export const SchedulingSecondaryEvent = (props) => {
    const lang = useTranslation();
    const [secondaryEvents, setSecondaryEvents] = useState([]);

    useEffect(() => {
        if (props.selectedState[0] != undefined && props.selectedState[0].Prototypes) {
            if (props.selectedState[0].Prototypes.length > 0) {
                setSecondaryEvents(props.selectedState[0].Prototypes.map((x) => {
                    return {
                        ...x,
                        TriggerStartTime: props.selectedState[0].SlotDateTime + x.StartOffset,
                        TriggerEndTime: props.selectedState[0].SlotDateTime + x.EndOffset,
                    }
                }))
            } else {
                setSecondaryEvents([]);
            }
        }

    }, [props.selectedState])


    const GridRowRenderScheduleSecondaryEvents = (tr, props) => {
        var style = SCHEDULECOLORPREFERENCE.lightgrey;
        const trProps = {
            style: style,
            draggable: false,
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    return (
        <div style={{
            position: 'absolute',
            left: 0,
            height: "80%",
            width: '100%',
            padding: '8px',
            marginTop: '-15px',
        }}>

        <Grid
            data={secondaryEvents}
            rowRender={GridRowRenderScheduleSecondaryEvents}
            style={{ height : "100%" }}
            resizable={true}
        >
            <Column field="Name" title={lang.name_column} width="120px" />
            <Column field="ID" title={lang.id_column} width="120px" />
            <Column field="ProtoTypeSource.Name" title={lang.source_column} width="120px" />
            <Column field="StartTriggerType.Description" title={lang.start_type_column} width="120px" />
            <Column field="StartOffset" title={lang.start_offset_column} cell={TimeCell} width="120px" />
            {!props.hideTriggerStartEnd && <Column field="TriggerStartTime" title={lang.trigger_start_time_label} cell={TimeCell} width="120px" />}
            <Column field="EndTriggerType.Description" title={lang.end_type_column} width="120px" />
            <Column field="EndOffset" title={lang.end_offset_column} cell={TimeCell} width="120px" />
            {!props.hideTriggerStartEnd && <Column field="TriggerEndTime" title={lang.trigger_end_time_label} cell={TimeCell} width="120px" />}
            <Column field="Text1" title={lang.text_1_column} width="180px" />
            <Column field="Text2" title={lang.text_2_column} width="180px" />
            <Column field="Text3" title={lang.text_3_column} width="180px" />

        </Grid>
        </div>

    )
}
