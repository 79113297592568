
/* eslint-disable */
import React from "react";
import { Card } from 'react-bootstrap';
import { Divider } from 'primereact/divider';
import { useTranslation } from "../../locale/useTranslation";

const ActiveDashboardTile = (props) => {
    const lang = useTranslation();

    return (

        <Card style={{ borderRadius: "10px"}}>
            <div className="summaryCardContent">
                <div className='row'>
                    <div className='col-3 mt-1 ml-2'>
                        <div className="summaryDashboard_card" style={{ backgroundColor: props?.tileBackColor }}></div>
                        <i className={`fa-sharp fa-solid fa-${props?.icon} summaryDashboardicon_card`} style={props?.style}></i>
                    </div>
                    <div className='col mt-4' style={{ overflow: "hidden" }}>
                        <div className="row" style={{ flexWrap: "nowrap" }}>
                            <div className='ml-4'>
                                <div className="summaryDashboard_Count">{props?.totalCount}</div>
                                <div className="summaryDashboard_text">{props?.name}</div>
                            </div>
                            <Divider layout="vertical" />
                            <div className='ml-4'>
                                <div className="summaryDashboard_Count">{props?.Activecount}</div>
                                <div className="summaryDashboard_text">{lang.active_label}</div>
                                <div className="summaryDashboard_text">{props?.name}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>

    )
}

export default ActiveDashboardTile