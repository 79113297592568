/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, FILEURL } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import BossDialog from "../../components/BossDialog";

const BookingStatusDashboard = (props) => {
     const [isLoading,setIsLoading] = useState(false);
     const [statusMessage,setStatusMessage] = useState('');
     const defaultDates = utility.getFirstAndLastDateOfMonth(); 

     const [dataItem, setDataItem] = useState({ 
        FromDate : defaultDates.FirstDate,
        ToDate : defaultDates.LastDate,
        Channel:{},
     });

     const [endSelectionDate,setEndSelectionDate] = useState(defaultDates.LastDate);

     const timeRangeRef = useRef();
     const setTimeRangeRef = (data) => {
        timeRangeRef.current = data;
     }

     const selectedChannelRef = useRef();
     const setSelectedChannelRef = (data) => {
        selectedChannelRef.current = data;
     }
     const [channel, setChannel] = useState([]);
     const [bookingData, setBookingData] = useState([]);
     const [tableData, setTableData] = useState([]);
     const [headerDates, setHeaderDates] = useState([]);
     const [showCellDetails,setShowCellDetails] = useState(false);
     const [cellCommercials,setCellCommercials] = useState(false);
     const [cellDetails,setCellDetails] = useState({
        UsedPercentage : '0%',
        Date : '',
        TotalSeconds : 0,
        BookedSeconds : 0,
        OverBooked : 0,
        TimeRange : ''
     });

     const lang=useTranslation();

     useEffect(() => { 
        loadcombo();
     }, []);
 
    const loadcombo = async () => {           
         let channelRes = await API.getValidChannels();
         setChannel(channelRes.data);
         setSelectedChannelRef(channelRes.data[0]);
         setDataItem({...dataItem,Channel : channelRes.data[0]});      
         let timeRange = await API.getDataLookup(ENTITYNAME.TimeRange);
         setTimeRangeRef(timeRange.data);
         
         //loading
         await loadBookingData()
    } 

    const isValid = () => {

        setStatusMessage("Fetching data !!")

        if(!utility.isValidDate(dataItem.FromDate)){
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
              });
            return false;
            
        }
        if(!utility.isValidDate(dataItem.ToDate)){
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItem.ToDate <= dataItem.FromDate){
            toast.error(`${lang.please_select_to_date_grater_then_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(Object.keys(selectedChannelRef.current).length==0){
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true
    }

    const loadBookingData = async () => {
        if(isValid()){
            setIsLoading(true);
            let fdate = new Date(dataItem.FromDate); 
            let tdate = new Date(dataItem.ToDate); 
            let fromdate = fdate.getTime();
            let todate = tdate.getTime();

            let query =  [["BookingDate", ">=",fromdate],["BookingDate", "<=",todate],["Channel.SID", "=",selectedChannelRef.current.SID]];

            //create dates for header 
            let days = []
        
            // Get difference from date or to date 
            let daysCalculate = moment(tdate).diff(moment(fdate), 'days') + 1;

                for(let i = 0 ; i < daysCalculate ; i++) {
                    let firstDate = new Date(fdate.getFullYear(), fdate.getMonth(), i+1);
                    let date = moment(firstDate).format("DD-MMM-YYYY");
                    days.push(`${date}`);
                }

            setHeaderDates(days);

        // let response = await API.getData(ENTITYNAME.Booking, {query: query});
        let response = await API.getBookingStatus(query);
        setBookingData(response.data);
        if(response.success){
            setStatusMessage(() => 'Designing Status Dashboard...')
            console.log(statusMessage)
            //set Data from bookingData table according to the UI
            let tempGridData = timeRangeRef.current.map((timeRange) => {

                let sampleDataItem = {};

                days.forEach((date) => {

                    let dataOnDateTimeRange = {};

                    dataOnDateTimeRange = response.data.filter((bookingData) => {
                        if(bookingData.TimeRange == undefined){
                            return response.data.filter((bookingData) => moment(new Date(bookingData.BookingDate)).format('DD-MMM-YYYY') == date && bookingData.TimeRangeFrom == utility.convertShortTimeStringToMilliSeconds(timeRange.TimeIn));
                        }else {
                            return moment(new Date(bookingData.BookingDate)).format('DD-MMM-YYYY') == date && (bookingData.TimeRange != undefined && bookingData.TimeRange.SID == timeRange.SID)
                        }
                    });
                    sampleDataItem = {...sampleDataItem ,[date] : dataOnDateTimeRange} 

                })
                
                return {TimeRange : timeRange,...sampleDataItem}

            })
            //ending

            setTableData(tempGridData);
            setIsLoading(false);
        }    
        }
        
    };

       
    const onChange = (e) => {
        if(e.target.name == 'Channel'){
            setSelectedChannelRef(e.target.value)
            setDataItem({ ...dataItem, [e.target.name]: e.target.value }); 
        } else if (e.target.name == 'FromDate'){
            let endDate = moment(new Date(moment(new Date(e.target.value), "DD-MM-YYYY").add(31,'days'))).format('YYYY-MM-DD')
            setDataItem({ ...dataItem, FromDate : e.target.value , ToDate : endDate });   
            setEndSelectionDate(endDate);
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });   
        }
    }

    const onCellClick = (clickedCell,timeRange,date) => {
        //clickedCell data
        console.log(clickedCell);

        //boolean for popup
        setShowCellDetails(true);

        let uniqueMediaID = [];
        let uniqueMediaEpisode = [];

        //duration value
        let duration = 0;
        let totalAmount = 0;
        if(clickedCell != undefined && clickedCell.length > 0){
            clickedCell.map((obj) => {
                totalAmount = totalAmount + parseInt(obj.SpotRate);
                duration = duration + obj.MediaEpisode.Duration;

                if(!uniqueMediaID.includes(obj.MediaEpisode_Id)){
                    uniqueMediaID.push(obj.MediaEpisode_Id);

                    let data = clickedCell.filter((x) => x.MediaEpisode_Id == obj.MediaEpisode_Id);

                    let TotalAmount = 0;
                    let TotalFCT = 0;
                    let TotalSpot = data.length

                    if(data.length > 0){
                        data.map((item) => {
                            TotalAmount = TotalAmount + parseInt(item.SpotRate);
                            TotalFCT = TotalFCT + item.MediaEpisode.Duration;
                        })
                    }

                    let distinctItem = {
                        Title : obj.MediaEpisode.Title,
                        AssetId : obj.MediaEpisode.AssetId,
                        Duration : obj.MediaEpisode.Duration,
                        CampaignNo : obj.CampaignNo,
                        TotalAmount : TotalAmount,
                        TotalFCT : TotalFCT,
                        TotalSpot : TotalSpot
                    }

                    uniqueMediaEpisode.push(distinctItem);

                }

            })
        }

        setCellCommercials(uniqueMediaEpisode);

        let percentage = duration/timeRange.CommercialLimit * 100;

        let item = {
            UsedPercentage : Math.ceil(percentage) + '%',
            Date : date,
            TotalSeconds : timeRange.CommercialLimit / 1000,
            BookedSeconds : duration /1000,
            TimeRange : timeRange.TimeRange,
            TotalSpots : clickedCell.length,
            TotalAmout : totalAmount
        }

        duration > timeRange.CommercialLimit ? item.OverBooked = (duration - timeRange.CommercialLimit)/1000 : item.AvailableSeconds = (timeRange.CommercialLimit - duration)/1000;
        setCellDetails(item);
    }

    const excelExport = async () => {
        let res = await API.exportBookingStatus(tableData, headerDates);
        console.log(res);
        if (res.success) {
            let filePath = FILEURL.BASEURL + 'downloadReport/' + res.fileName;
            window.open(filePath);
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    return <>
        <div className="row">
            <div className="col-12">
                <div className="row form-group">   
                    <div className="col-3">    
                        <label htmlFor="">{lang.from_date_label} </label>                          
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="FromDate"
                            value={dataItem.FromDate}
                            onChange={onChange}
                        />    
                    </div>
                    <div className="col-3">
                        <label htmlFor="">{lang.to_date_label} </label>                               
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="ToDate"
                            value={dataItem.ToDate}
                            onChange={onChange}
                            min={dataItem.FromDate}
                            max={endSelectionDate}

                        />
                    </div>                    
                    <div className="col-3">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            style={{
                            backgroundColor:"white",
                            }}
                            data={channel}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                            />                                  
                    </div>
                    <div className="col-3" style={{textAlign:"left", marginTop:"20px"}}>               
                        <ActionButton title={lang.show_button_tooltip} name={lang.show_button_text} onClick = {loadBookingData}/>    
                        <ActionButton title={lang.export_excel_button_tooltip} name={lang.export_button_text} onClick = {(e) => {e.preventDefault(); excelExport()}}/>               
                    </div>                                                           
                </div>   
            </div>
            {isLoading ? 
            <Loader height={'73vh'}/> :
            <div className="col-12" style={{overflow:"overlay", overflowY:"auto",height:"73vh", marginRight:"05px",marginBottom:"10px", width:"25vw"}}>
                <table className="table table-striped bookstatusviewtable" >
                    <thead>
                        <tr>
                            <th style={{color: "rgb(15, 15, 15)"}}>
                                {lang.time_range_label}
                            </th>
                            {/* for static header dates */}
                            {headerDates.map((date,index) => {
                                return (
                                    <th key={index} style={{color: "rgb(15, 15, 15)"}}>
                                        {date}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                    {tableData.map((item,index) => {
                        return (
                            <tr key={`${index}-asdf`}>
                                <td >
                                &nbsp;&nbsp;&nbsp;{item.TimeRange.TimeRange}
                                </td>                                
                                {headerDates.map((date) => {
                                    let style = {}
                                    let limit = item.TimeRange.CommercialLimit

                                    //duration value
                                    let duration = 0;
                                    if(item[date] != undefined && item[date].length > 0){
                                        item[date].map((obj) => {
                                            duration = duration + obj.MediaEpisode.Duration;
                                        })
                                    }

                                    //calculate percentage pending
                                    let percentageValue = (duration/limit) * 100;

                                    //convert 6.944 then 7%
                                    let percentage = Math.ceil(percentageValue);

                                    // style = duration >= limit ? {background: '#F94A29'} : 
                                    style = duration > limit ? {background: '#F94A29',height:'22px', fontSize:"14px"} : 
                                    { height:'22px',fontSize:"14px" ,background: `linear-gradient(90deg, rgba(0,172,255,1) 0%, rgba(212,220,227,1) ${percentage}%)`}

                                    return (                                            
                                        <td onClick={() => onCellClick(item[date],item.TimeRange,date)}>                                              
                                            <span className="progress" style={style}> 
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{`${parseInt(percentage)}%`}  
                                            </span>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>}
        </div>

        {/* Booking details popup */}
        {showCellDetails && <BossDialog title={`${cellDetails.Date} (${cellDetails.TimeRange}) `} width={'650px'} height={'420px'} onClose={() => setShowCellDetails(false)}>
            <div style={{}}>

                <div className="row">
                    <div className="col" style={{fontWeight : 'bold' ,fontSize: '40px' ,height : '40px'}}>{cellDetails.UsedPercentage}<label htmlFor={'UsedSlot'} style={{marginTop : '23px', marginLeft: '5px', fontWeight : 'bold'}}>{lang.utilisied_label}</label></div>
                    <div className="col" style={{textAlign : 'left',padding:"5px 0px 0px 260px"}}>
                    <label htmlFor={'TotalSpots'} style={{fontWeight : 'bold' }}>{`${lang.total_spot_label} : ${cellDetails.TotalSpots}`}</label>
                    <label htmlFor={'TotalAmount'} style={{fontWeight : 'bold' }}>{`${lang.total_amount_label} : ${cellDetails.TotalAmout}`}</label>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col" style={{color : 'blue', fontWeight : 'bold' ,fontSize: '20px'}}>{lang.booking_details_label}</div>
                </div>

                <div className="row mt-3 mb-3">
                    <div className="col" style={{textAlign : 'left'}}>
                    <label htmlFor={'TotalSeconds'} style={{fontWeight : 'bold' }}>{`${lang.total_label} ${cellDetails.TotalSeconds}`}</label>
                    </div>
                    <div className="col" style={{textAlign : 'center'}}>
                    <label htmlFor={'BookedSeconds'} style={{fontWeight : 'bold' }}>{`${lang.booked_label}: ${cellDetails.BookedSeconds}`}</label>
                    </div>
                    <div className="col" style={{textAlign : 'right'}}>
                    <label htmlFor={'OverBooked'} style={{fontWeight : 'bold' }}>{cellDetails.OverBooked != undefined ? `${lang.over_booked_label}: ${cellDetails.OverBooked}` : `${lang.available_label} : ${cellDetails.AvailableSeconds}`}</label>
                    </div>
                </div>

                <Grid data = {cellCommercials} style={{ height: "200px" }} resizable={true}>
                    <GridColumn field="Title" title={lang.title_column} editable={false} />
                    <GridColumn field="AssetId" title={lang.assetId_column} editable={false} />
                    <GridColumn field="Duration" title={lang.duration_column} editable={false}  cell={TimeCell}/>
                    <GridColumn field="CampaignNo" title={lang.campaign_no_label} editable={false} />
                    <GridColumn field="TotalSpot" title={lang.spots_column} editable={false} /> 
                    <GridColumn field="TotalFCT" title={lang.fct_column} editable={false} /> 
                    <GridColumn field="TotalAmount" title={lang.amount_column} editable={false} /> 
                </Grid>

            </div>
            </BossDialog>}
        </>
     }         

export default BookingStatusDashboard;