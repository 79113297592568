/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import {ENTITYNAME,AUTOFILLMEDIATYPE,MEDIACATEGORIES } from "../../framework/constant/constant";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { GridColumn } from "@progress/kendo-react-grid";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";


const ScheduleAutofillInterstitial = (props) => {
  const [dataItem, setDataItem] = useState({ Channel : {},  AutoFillMediaType: AUTOFILLMEDIATYPE[0],MediaEpisode:[], Archive: false });
  const [MediaEpisoeOpen, setMediaEpisodeOpen] = useState(false);
  const [gridData, setGridData] = useState([]);
  const navigate = useNavigate();
  const { SID } = useParams();
  const lang = useTranslation();
  const [channel, setChannel] = useState([]);

  useEffect(() => {
    loadcombo();
    if (SID > 0) {
      loadEditData();
    }
  }, []);

  const loadcombo = async () => {
    var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
    setChannel(channelRes.data);
   
  }

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }
  const onMediaEpisodeSelection = () => {
    MediaEpisoeOpen ? setMediaEpisodeOpen(false) : setMediaEpisodeOpen(true);
  }

  const loadEditData = async () => {

    var res = await API.getEntity(ENTITYNAME.ScheduleAutoFillInterstitial, parseInt(SID));
    
    setDataItem(res.data);
    var resdata = res.data.MediaEpisode
    setGridData([...gridData, resdata]);
    console.log(res.data.MediaEpisode)
    console.log(resdata)
  }

  const MyDeleteCommandCell = (props) => (
    <div style={{ display: "flex" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => onDelete(props.dataItem), () => { })} />
    </div>
  );

  const onDelete = (data) => {
    console.log(data);
    var filtered = gridData.filter((obj) => obj._id != data._id);
    setGridData(filtered);
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const onAddMediaEpisode = () => {
    
    setMediaEpisodeOpen(true);
  }

  const gridSave = (dataList) => {
    var duplicate=[]
    var newData=[]
    dataList.map((obj)=>{
    var Temp=gridData.filter((item)=>item.SID==obj.SID)
    if(Temp.length>0){
        duplicate=[...duplicate,...Temp]
    }
    else{
        newData=[...newData,obj]
    }
   })

   if(duplicate.length!=0){
    var msg=""
    duplicate.map((obj)=>{
        msg=msg+obj.Title+" "+", "
    })
    toast.error(msg+`${lang.already_exists_global_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    
   }
    setGridData([...gridData, ...newData]);
  }

  function validationCheckForSave() {
    if (Object.keys(dataItem.Channel).length == 0) {
        toast.error(`${lang.please_select_channel_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    if (gridData.length == 0) {
      toast.error(`${lang.please_attach_atleast_one_media_episode_group_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const onSave = async () => {

    if (validationCheckForSave()) {
      console.log(gridData)
      var saveData = gridData.map(x=> {
        return {
        SID: parseInt(SID),        
        Channel: dataItem.Channel,       
        AutoFillMediaType: dataItem.AutoFillMediaType,
        MediaEpisode: { _id:x._id, Title: x.Title, AssetId: x.AssetId, MediaCategory: x.MediaCategory.Description, MediaCategoryType : x.MediaCategoryType.Description},
        Archive: false
      }});
       var res = await API.saveData(ENTITYNAME.ScheduleAutoFillInterstitial, saveData);
      if (!res.success) {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      setDataItem({});
      setGridData([]);
      if (!res.success) return;
      navigate(-1);
     }
  };

  return <>
    <div className="row m-1">
      <div className="col-12">
            <EditPageHeader title={`${lang.schedule_auto_fill_interstitial_edit_page_header} -> ` + (SID > 0 ? lang.schedule_auto_fill_interstitial_edit_page_header : `${lang.new_title_editpageheader}`)} onSubmit={onSave} onCancel={() => navigate(-1)} />
            <div className="row">
            <div className="col-4">
                <div className="form-group">
                    <label htmlFor="TabView">{lang.channel_label} *</label>
                    <DropDownList
                      data={channel}
                      name={"Channel"}
                      textField="FullChannelName"
                      dataItemKey="_id"
                      value={dataItem.Channel}
                      onChange={onChange}
                    />
                </div>
            </div>
            <div className="col-4">
                <div className="form-group">
                    <label htmlFor="TabView">{lang.segment_position_label} *</label>
                    <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={AUTOFILLMEDIATYPE}
                    defaultValue={AUTOFILLMEDIATYPE[0]}
                    name="AutoFillMediaType"
                    textField="Description"
                    dataItemKey="ID"
                    value={dataItem.AutoFillMediaType}
                    onChange={onChange}
                    />
                </div>
            </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <input name="Archive" type="checkbox" value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange} />
            <label className="ml-1">{lang.archive}</label>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {SID < 1 && <AddRoundButton title={lang.add_media_episode_button_tooltip} onClick={onAddMediaEpisode} />}
            <Grid data={gridData} style={{ height: "67vh", marginTop: "10px", marginBottom: '60px' }}>
            {SID < 1 && <GridColumn field="" title="" editable={false} cell={MyDeleteCommandCell} width={'40px'} />}
              <GridColumn field="Title" title={lang.title_column} editable={false} />
              <GridColumn field="AssetId" title={lang.assetId_column} editable={false} />
              <GridColumn field="MediaCategory.Description" title={lang.media_category_column} editable={false} />
              <GridColumn field="MediaCategoryType.Description" title={lang.media_category_type_label} editable={false} />
            </Grid>
          </div>
        </div>
      </div>
    </div>
    {MediaEpisoeOpen && <CollectionSelection addButtonTitle={lang.select_collection_button_text} title={lang.select_media_episode_collection_header} entityname={ENTITYNAME.MediaEpisode} setDataList={gridSave} wherestatement={['MediaCategory.SID','=', MEDIACATEGORIES.Interstitial]} closeForm={onMediaEpisodeSelection} width={"50vw"} height={"60vh"}/>}
 </>
}
export default ScheduleAutofillInterstitial;