/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../API/api";
import { ACCESS_LEVEL, ASSETTYPE, COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MEDIACATEGORIES, MODULE } from "../constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteErrorDialogBox from "../forms/helpercomponents/alert/DeleteErrorDialogBox";
import { UserPreference } from "../../modules/userPreference/UserPreference";
import { utility } from "../utility/utilityProvider";
import { ArrayCell } from "../forms/helpercomponents/CustomGridCells/ArrayCell";
import { HrefCell } from "../forms/helpercomponents/CustomGridCells/HrefCell";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { EnumCell } from "../forms/helpercomponents/CustomGridCells/EnumCell";
import { PlatformCell } from "../forms/helpercomponents/CustomGridCells/PlatformCell";
import { TimeCell } from "../forms/helpercomponents/CustomGridCells/TimeCell";
import { MenuCommandCell } from "../forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import EditForm from "../forms/helpercomponents/editForm";
import { ImageCell } from "../forms/helpercomponents/CustomGridCells/ImageCell";
import { LookupCell } from "../forms/helpercomponents/CustomGridCells/LookupCell";
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../forms/helpercomponents/CustomGridCells/DateCell";
import BackRoundButton from "../forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../forms/helpercomponents/buttons/PreferenceRoundButton";
import { CheckboxCell } from "../forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { collectionMenuModel } from "../forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { Loader } from "../forms/helpercomponents/Loader";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ProviderCell } from "../forms/helpercomponents/CustomGridCells/ProviderCell";
import OttFilterTemplate from "./OttFilterTemplate";
import { DataHelper } from "../helper/DataHelper";
import { ConfirmAlert } from "../../ConfirmAlert";

export const OttAssetCollection = (props) => {
  //possible props

  let page_take = props.page_take ?? 50;
  let showAdd = props.showAdd ?? true;
  let showBack = props.showBack ?? true;
  let showCommandCell = props.showCommandCell ?? true;
  let showEdit = props.showEdit ?? true;
  let showTitle = props.showTitle ?? true;
  let gridRowRender = props.gridRowRender;
  let CustomEditForm = props.customEditForm ?? EditForm;
  let filterable = props.filterable ?? false;
  let sortable = props.sortable ?? true;
  let isPageable = props.isPageable ?? true;
  let wherestatement = props.wherestatement;
  let andWhereStatement = props.andWhereStatement;
  let sort = props.sort ?? { SID: 1 };
  let customColumns = props.customColumns ?? [];
  let openNewEditForm = props.openNewEditForm ?? false;
  let myTitle = props.title ?? "";
  let myMenuModel = props.menuModel ?? collectionMenuModel;
  let entityName = props.entityname;
  let showPreferenceButton = props.showPreferenceButton ?? true;
  let hideArchiveProps = props.hideArchive ?? false;
  let module = props?.module;
  let showFilterTemplate = props.showFilterTemplate ?? false;

  // example custome columns[]
  const [gridData, setGridData] = useState([]);
  const [total, setTotal] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState({ SID: 1 });
  const [deleteError, setDeleteError] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [showPreferences, setShowPreferences] = useState(false);
  const [dataColumns, setDataColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [hideArchive, setHideArchive] = useState(hideArchiveProps);
  const initialSort = { field: Object.keys(sort)[0], dir: sort[Object.keys(sort)[0]] == 1 ? "asc" : "desc" }
  const [gridSort, setGridSort] = useState([initialSort]);
  const [filterTemplate, setFilterTemplate] = useState([[]]);
  const [assetType, setAssetType] = useState(undefined);

  const [page, setPage] = useState({
    skip: 0,
    take: page_take,
  });

  const lang = useTranslation();

  useEffect(() => {
    setPage({ skip: 0, take: page_take });
    loaddata({ per_page: page_take, current_page: 1 }, searchText, false, filterTemplate, assetType);
  }, []);

  const onSearch = (e) => {
    e.preventDefault();
    let text = e.target.value?.trim();
    setSearchText(text);
  };

  // Search 
  useEffect(() => {
    let sortEvent = gridSort[0] ?? initialSort;
    if (searchText != "") {
      const timeOut = setTimeout(() => {
        setIsLoading(true);
        loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, searchText, showArchived, filterTemplate, assetType);
      }, 700);
      return () => clearTimeout(timeOut);
    } else {
      setIsLoading(true);
      setPage({ skip: 0, take: page_take });
      loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, searchText, showArchived, filterTemplate, assetType);
    }
  }, [searchText]);

  const loaddata = async (pagination, searchText, archive, filters, assetType) => {

    if (props.gridColumns) {
      setDataColumns(props.gridColumns);
      setGridData(props.gridData);
      setIsLoading(false);
    } else {
      try {
        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        let columns = await API.getFormData(entityName, currentUserId);
        let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : sort;
        let json = await API.getAssets(
          { page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: finalSort },
          columns,
          searchText,
          filters, 
          assetType
        );

        setGridData(json.data);
        setTotal(json.pagination.total);
        //if custmoe colum is coming from compemnent level then no need  a column from Server API
        customColumns.length > 0
          ? setDataColumns(customColumns)
          : setDataColumns(columns);

        // console.log(columns)
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);

      }
    }
  };

  const enterEdit = (item) => {
    console.log("onEnterEdit");
    if (openNewEditForm === false) {
      setOpenForm(true);
      setEditItem(item);
    } // need to navigate to full page edit view
    else {
      navigate(`${item.SID}`, { state: { copy: item.copy ? true : false, quickEdit: item.quickEdit ? true : false } });
    }
  };

  //onSubmit from EDIRFORM
  const handleSubmit = async (dataItem, dataToSave) => {
    console.log("handleSUbmit");
    //if datatosave is null then dataitem is datasave
    dataToSave = dataToSave == null ? dataItem : dataToSave;

    let isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

    if (!isDuplicate) {
      setOpenForm(true);
      toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let res = await API.saveData(props.entityname, dataToSave);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    let logData = { event: dataToSave.SID == 0 ? LOGEVENT.CREATE_ASSETS : LOGEVENT.UPDATE_ASSETS, module: MODULE.OTT_ASSETS, data: res.data, message: res.message };
    API.SaveLogs(logData);

    utility.deleteLocalStorageItem(props.entityname)
    //to load grid with new data
    loaddata({ per_page: page_take, current_page: 1 }, searchText, showArchived, filterTemplate, assetType);
    setOpenForm(false);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleArchive = async (dataItem) => {

    let resArchiveUpdated = await API.saveData(entityName, {
      ...dataItem,
      Archive: !dataItem.Archive
    })

    if (resArchiveUpdated.success) {
      console.log(`Archive is been updated to ${!dataItem.Archive}`);
      let logData = { event: !dataItem.Archive ? LOGEVENT.ARCHIVE : LOGEVENT.UNARCHIVE, module: module, data: { ...dataItem, Archive: !dataItem.Archive }, message: resArchiveUpdated.message };
      API.SaveLogs(logData);
      let sortEvent = gridSort[0] ?? initialSort;
      let current_page = page.skip / page.take + 1;
      loaddata({ per_page: page_take, current_page: !dataItem.Archive ? current_page : 1, orderby: sortEvent.field, direction: sortEvent.dir }, searchText, showArchived, filterTemplate, assetType);
      utility.deleteLocalStorageItem(entityName);
    } else {
      console.log(resArchiveUpdated.message);
    }
  }

  const onCloseUserPrefence = () => {
    let sortEvent = gridSort[0] ?? initialSort;
    loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, searchText, showArchived, filterTemplate, assetType);
    setShowPreferences(!showPreferences);

  }

  const handleNavigate = (item) => {
    if (item?.Module) {
      window.open(item.path, "MyTargetWindowName");
    } else {
      navigate(`${item.path}`, { state: item });
    }
  }

  const handleArchiveConfirmation = async (item) => {
      let railsRes = await API.getData(ENTITYNAME.Rails, {query: ["ott_assets", "in", [item._id]]});
      if(!item?.Archive && railsRes.success && railsRes.data.length > 0){
        ConfirmAlert(() => handleArchive(item), ()=>{}, lang.confirm_button_text, `${lang.ott_assets_used_in_archive_confirmation_message} (${utility.subString(railsRes?.data?.map(x => x?.Title).join(', '), 50)}) ${lang.ott_assets_archive_confirmation_message}`);
      }else{
        handleArchive(item);
      }
  }

  //Edit Row
  const MyCommandCell = (props) => (

    <MenuCommandCell
      {...props}
      enterEdit={enterEdit}
      cancelEdit={handleCancelEdit}
      onSubmit={handleSubmit}
      item={editItem}
      module={module}
      dataColumns={dataColumns}
      onArchive={handleArchiveConfirmation}
      showEdit={showEdit}
      myMenuModel={myMenuModel}
      toNavigate={handleNavigate}

    />
  );

  const MyCustomToggleButton = (props) => (
    <MyToggleButton
      {...props}
      entityName={entityName}
      size={"small"}
    />
  );

  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );

  const handleErrorDialogClose = () => {
    setDeleteError(!deleteError);
    setDeleteErrorMessage("");
  }

  const pageChange = async (e) => {
    let current_page = e.page.skip / e.page.take + 1;
    let sortEvent = gridSort[0] ?? initialSort;

    await loaddata({ per_page: e.page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, searchText, showArchived, filterTemplate, assetType);
    setPage(e.page);
  };

  const onArchiveClick = () => {
    setShowArchived(!showArchived);
    let sortEvent = gridSort[0] ?? initialSort;
    if (!showArchived) {
      loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, searchText, true, assetType);
    } else {
      let current_page = page.skip / page.take + 1;
      loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, searchText, false, filterTemplate, assetType);
    }
  }

  const refreshCollection = () => {
    setIsLoading(true);
    let current_page = page.skip / page.take + 1;
    loaddata({ per_page: page.take, current_page: current_page, orderby: "SID", direction: "asc" }, searchText, showArchived, filterTemplate, assetType);
    setGridSort([initialSort]);
  }

  const onSortChange = (e) => {
    setGridSort(e.sort)
    let sortEvent = e.sort[0] ?? sort;
    let current_page = page.skip / page.take + 1;
    loaddata({ per_page: page.take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, searchText, showArchived, filterTemplate, assetType);
  }

  const toGetFilteredData = (data) => {
    let finalFilterQuery = [];
    console.log(data);
    if(Object.keys(data).length != 0){
      let assetTypeFilter = Object.keys(data?.AssetType)?.length > 0 && data?.AssetType?._id != 0 ? [['OttAssetTypeSID', '=', data?.AssetType?._id]] : [];
      let providerFilter = Object.keys(data?.Provider)?.length > 0  && data?.Provider?._id != 0 ? [['Provider', '=', data?.Provider?._id]] : [];
      let accessLevelFilter = data?.AccessLevel && Object.keys(data?.AccessLevel).length != 0 ? [['accessLevel', '=', data?.AccessLevel?._id]] : [];
      let vodTypeFilter = Object.keys(data?.VodType)?.length > 0 && data?.VodType?._id != 0 ? [['VodTypes._id', '=', data?.VodType?._id]] : [];
      let mediaCategorysFilter = data?.MediaCategory && data?.MediaCategory?.length > 0 ? [['Source.MediaCategory._id', "in", data?.MediaCategory?.map(x => x?._id)]] : [];
      let genresFilter = data?.Genres && data?.Genres?.length > 0 ? [['Source.Genres._id', "in", data?.Genres?.map(x => x?._id)]] : [];
      let releaseYearFilter = data?.ReleaseYear && data?.ReleaseYear != "" ? [["Source.ReleaseDate", ">=", new Date(`${data.ReleaseYear}-01-01`).getTime()], ["Source.ReleaseDate", "<=", new Date(`${data.ReleaseYear}-12-31`).getTime()]] : []
      let pgRatingsFilter = data?.PGRating && data?.PGRating?.length > 0 ? [['MetaData.PGRating._id', "in", data?.PGRating?.map(x => x?._id)]] : [];
      let languagesFilter = data?.Languages && data?.Languages?.length > 0 ? [['MetaData.Languages._id', "in", data?.Languages?.map(x => x?._id)]] : [];
      let CastAndCrews = data?.CastAndCrews && data?.CastAndCrews?.length > 0 ? [['CastAndCrews._id', "in", data?.CastAndCrews?.map(x => x?._id)]] : [];
      let createdFromFilter = data?.CreatedFrom && data?.CreatedFrom != "" ? [["addOn", ">=", new Date(`${data.CreatedFrom} 00:00:00`).getTime()]] : [];
      let createdToFilter = data?.CreatedTo && data?.CreatedTo != "" ? [["addOn", "<=", new Date(`${data.CreatedTo} 23:59:59`).getTime()]] : [];
      let segmentsFilter = (data?.Segments && Object.keys(data?.Segments)?.length > 0 && data?.Segments?._id !=0) ? [['Segments._id', "=", data?.Segments?._id]] : [];

      finalFilterQuery = [...assetTypeFilter, ...providerFilter, ...accessLevelFilter, ...vodTypeFilter, ...createdFromFilter, ...createdToFilter, ...releaseYearFilter, ...mediaCategorysFilter, ...genresFilter, ...pgRatingsFilter, ...languagesFilter, ...CastAndCrews, ...segmentsFilter];
    }
    console.log(finalFilterQuery);
    setFilterTemplate(finalFilterQuery);
    let assetTypeValue = data?.AssetType?._id != 0 && data?.AssetType?._id;
    setAssetType(assetTypeValue);
    loaddata({ per_page: page.take, current_page: 1, orderby: "SID", direction: "asc" }, searchText, showArchived, finalFilterQuery, assetTypeValue);
  }

  return (
    <>
      <div>
        {showTitle && (
          <>
            <h1 className="page-title txt-color-bludeDark">
              <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
              ></i>
              <span>{myTitle === "" ? props.entityname : myTitle}</span>
            </h1>
          </>
        )}
        <div className="flex-container">
          <div className="martb mar">
            {showBack && (
              <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
            )}
            {showAdd && (
              <AddRoundButton onClick={() => enterEdit({ SID: 0 })}></AddRoundButton>
            )}
          </div>
          <div className="flex-container-reverse flex-item-auto">
            <div className="input-group input-group w-300 martb" >
              <input
                type="text"
                className="form-control shadow-inset-2 pt-0 pb-0"
                id="searchInput"
                placeholder={lang.search_button_text}
                onChange={onSearch}
                onFocus={(e) => {
                  if (props.setOutSideFocus) {
                    props.setOutSideFocus(true);
                  }
                }}
                onBlur={(e) => {
                  if (props.setOutSideFocus) {
                    props.setOutSideFocus(false);
                  }
                }}
              />
              {showFilterTemplate ? 
                <OttFilterTemplate
                toGetFilterDataItem={toGetFilteredData}
                height={"38px"}
                showTemplate={true}
                onClear={() => { wherestatement = props.wherestatement; setFilterTemplate({}); }}
                /> :
                <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                  <div className="input-group-append">
                    <span className="input-group-text">
                      <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                    </span>
                  </div>
                </button>
              }
            </div>
            {showPreferenceButton && <div className="m-1" style={{ paddingTop: "12px" }}>
              <PreferenceRoundButton
                icon={"gear"}
                title={lang.preference_button_tooltip}
                onClick={() => { setShowPreferences(!showPreferences) }}
              />
            </div>
            }
            <div className="mt-1" style={{ paddingTop: "12px" }}>
              <RefreshButton
                onClick={refreshCollection}
              />
            </div>
            {!hideArchive && <div className="d-inline mt-3 mr-2">
              <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
            </div>}
          </div>
        </div>
        {isLoading && <Loader height={"60vh"} />}

        {!isLoading &&
          <Grid
            style={props.gridstyle ?? {
              height: "74vh",
            }}
            data={gridData}
            sort={gridSort}
            sortable={sortable}
            onSortChange={onSortChange}
            rowRender={gridRowRender}
            filterable={filterable}
            pageable={isPageable}
            skip={page.skip}
            take={page.take}
            onPageChange={pageChange}
            total={total}
            resizable={true}
          >
            {/* Edit Row */}

            {showCommandCell && (
              <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
            )}

            {/* Rows Loop */}
            {dataColumns.map((column, index) => {
              if (column.type === COLUMNSTYPE.date) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={DateOnlyCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
                // }else if (column.name === COLUMNSTYPE.time) {
              } else if (column.type === COLUMNSTYPE.datetime) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={DateCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
                // }else if (column.name === COLUMNSTYPE.time) {
              } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={TimeCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              } else if (column.name === "SID") {
                return;
                //we are not returning any value for SID column
              } else if (column.type === COLUMNSTYPE.image) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={ImageCell}
                    title={column.label}
                    width={column.width ?? 100}
                  />
                );
              } else if (column.type === COLUMNSTYPE.select) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyDatalookupCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.toggle) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyCustomToggleButton}
                    title={column.label}
                    width={85}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.checkbox) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={CheckboxCell}
                    title={column.label}
                    width={column.width ?? 80}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.array) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={ArrayCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format ?? "Description"} //display item in object
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.href) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={HrefCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.enum) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={EnumCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              } else if (column.type === COLUMNSTYPE.platform) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={PlatformCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.localdatetime) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={LocalDateTimeColumn}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.provider) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={ProviderCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else {
                return (
                  column.type !== COLUMNSTYPE.hidden && (
                    <GridColumn
                      key={index}
                      field={column.name}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  )
                );
              }
            })}
          </Grid>}

        {openForm && (
          <CustomEditForm
            {...props} //to get other props from customEditForm
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            refresh={() => loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterTemplate)} //to refresh the collection when data added
            item={editItem}
            dataColumns={dataColumns}
          />
        )}

        {deleteError &&
          <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}
        {showPreferences && <UserPreference entityName={entityName} preferenceColumnENntityName={props.preferenceEntityName} handleClose={onCloseUserPrefence} />}
        {/* only for user collection */}
      </div>
    </>
  );
};
