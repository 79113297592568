/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from "../../framework/API/api";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME, PLATFORM } from "../../framework/constant/constant";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import SaveRoundButton from "../../framework/forms/helpercomponents/buttons/SaveRoundButton";
import CancelRoundButton from "../../framework/forms/helpercomponents/buttons/CancelRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { useRef } from "react";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import BossDialog from "../../components/BossDialog";


function OttAssetVideoEditForm(props) {

  const [ottVideoType, setOttVideoType] = useState([]);
  let initialValue = {
    Description: "",
    Url: "",
    OttVideoType: {},
  };

  const [i, setI] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [dataItem, setDataItem] = useState(initialValue);
  const [toggle, setToggle] = useState(false);
  const [showJobCollection, setShowJobCollection] = useState(false);
  const [showPlatform ,setShowPlatform] =useState(false)

  const lang = useTranslation();
  const selectedIndexRef = useRef(0);
  const setSelectedIndexRef = (data) => {
    selectedIndexRef.current = data;
  };

  useEffect(() => {
    loadcombo();
  }, []);

  const onChange = (e) => {
    if(e.target.name == "OttVideoType"){
      e.target.value.Description == "Deeplink" ? setShowPlatform(true) : setShowPlatform(false)
       setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }else {  
           setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

 

  const loadcombo = async () => {
    var ottVideoType = await API.getDataLookup(ENTITYNAME.OttVideoType, {
      sort: { Description: 1 },
    });
    setOttVideoType(ottVideoType.data);
  };


  

  const isValid = () => {
    if (
      dataItem.OttVideoType == undefined ||
      Object.keys(dataItem.OttVideoType).length == 0
    ) {
      toast.error(
        `${lang.please_select_ottvideo_type_media_episode_video_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return false;
    }
    if (dataItem.Url == "") {
      toast.error(`${lang.ott_assets_video_url_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    console.log([...props.data]);
    var availableVideoType = [...props.data];
    var checkVideoType = availableVideoType.some(
      (x, index) =>
        x.OttVideoType.Description == dataItem.OttVideoType.Description &&
        selectedIndexRef.current != index
    );
    console.log(checkVideoType);
    if (checkVideoType) {
      toast.error(
        `${dataItem.OttVideoType.Description}` +
          " " +
          " " +
          `${lang.already_exists_global_error_message}`,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return false;
    }
    if (showPlatform && (dataItem.Platform == undefined || Object.keys(dataItem.Platform).length == 0)) {
      toast.error(`${lang.please_select_platform_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
      });
      return false;
  }
    return true;
  };

  const addVideo = (index) => {
    if (isValid()) {
      const dataNew = {
        Description: dataItem.Description,
        OttVideoType:
          typeof dataItem.OttVideoType === "string"
            ? JSON.parse(dataItem.OttVideoType)
            : dataItem.OttVideoType,
        Url: dataItem.Url,
        Platform: dataItem?.Platform ? dataItem.Platform : PLATFORM[0]
      };
      console.log(dataNew)

      props.setOttVideosData([...props.data, dataNew]);

      setI(i + 1);
      setDataItem(initialValue);
      clearData();
    }
  };

  const attachJobData = (data) => {
    let dataItem = data.dataItem;
    setSelectedIndexRef(data.dataIndex);
    setDataItem(dataItem);
    setShowJobCollection(true);
  };

  const MyCommandCell = (props) => (
    <div style={{ display: "flex", marginTop: "4px" }}>
      <EditRoundButton onClick={() => editFormGrid(props)} />
      <DeleteRoundButton
        onClick={() =>
          ConfirmDeleteAlert(
            () => removeVideos(props.dataIndex),
            () => {}
          )
        }
      />
    </div>
  );

  const editFormGrid = (data) => {
    let dataItem = data.dataItem;

    console.log(dataItem)
    setSelectedIndexRef(data.dataIndex);
    setDataItem(dataItem);
    setToggle(true);
    setEditMode(true);
    if(dataItem.Platform.SID > 0){
    setShowPlatform(true);
    }
    else{setShowPlatform(false);}
  };

  const removeVideos = (index) => {
    let local = [...props.data];
    local.splice(index, 1);
    props.setOttVideosData(local);
    // clearData();
    toast.success(
      `${lang.delete_successfully_grid_data_success_toast_message}`,
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  };

  const editVideos = () => {
    if (isValid()) {
      let local = [...props.data];
      local[selectedIndexRef.current] = dataItem;
      props.setOttVideosData(local);
      setEditMode(false);
      clearData();
    }
  };

  const clearData = () => {
    setDataItem(initialValue);
    setEditMode(false);
    setToggle(false);
    setShowPlatform(false)
  };

  const handleJobData = (data) => {
    console.log(data);
    setDataItem({
      ...dataItem,
      Url: data[0].HlsUrl,
      OttVideoType: ottVideoType[1],
    });
  };

  return (
    <div
      style={{
        boxShadow: "0px 0px 10px 1px lightgrey",
        backgroundColor: "#EEEEEE",
        borderRadius: "10px",
        padding: "10px 20px 20px 20px",
      }}
    >
      <div className="row">
        <div className={"col-12"}>
          <AddRoundButton onClick={() => setToggle(true)}></AddRoundButton>
          <Grid data={props.data} style={{ height: "20vh" }} className="mt-2">
            <GridColumn cell={MyCommandCell} width={"73px"} locked={true} />
            <GridColumn
              field="Description"
              title={lang.description_column}
              editable={false}
            />
            <GridColumn
              field="OttVideoType.Description"
              title={lang.video_type_column}
              editable={false}
            />
            <GridColumn field="Url" title={lang.url_column} editable={false} />
            <GridColumn field="Platform.Description" title={lang.platform_column} editable={false} />
          </Grid>
        </div>

        {toggle && (
          <BossDialog
            title={dataItem?.OttVideoType?.Description ? dataItem?.OttVideoType?.Description: lang.create_video_dialog_header}
            onClose={() => setToggle(false)}
            width={700}
            height={300}
          >
            <div className="row">
              <div className="col-12">
                <Form
                  render={(formRenderProps) => (
                    <FormElement>
                      <div className="row mt-2">
                        <div className="marlr">
                          {!editMode && (
                            <AddRoundButton
                              onClick={() => addVideo()}
                            ></AddRoundButton>
                          )}
                          {editMode && (
                            <SaveRoundButton
                              onClick={() => {
                                editVideos();
                              }}
                            ></SaveRoundButton>
                          )}
                          <CancelRoundButton
                            onClick={clearData}
                          ></CancelRoundButton>
                        </div>
                      </div>
                      <div className="mt-1" style={{ border: "groove 1px" }}>
                        <div className="row" style={{ margin: "5px" }}>
                          <div className="col-12 mt-2">
                            <div className="row">
                              <div className="col-6">
                                <div className="form-group">
                                  <label htmlFor="TabView">
                                    {lang.video_type_label} *
                                  </label>

                                  <DropDownList
                                    style={{
                                      backgroundColor: "white",
                                    }}
                                    data={ottVideoType}
                                    name="OttVideoType"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.OttVideoType}
                                    onChange={onChange}
                                    validator={(value) =>
                                      value ? "" : "Please select the value"
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="form-group">
                                  <label htmlFor="">
                                    {lang.description_label}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="Description"
                                    value={dataItem.Description}
                                    onChange={(e) => onChange(e)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-5">
                                <label htmlFor="">{lang.url_label} *</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  name="Url"
                                  value={dataItem.Url}
                                  onChange={(e) => onChange(e)}
                                />
                              </div>
                              <div className="col-1 mt-3">
                                <button
                                  type="button"
                                  className="float-right k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                                  style={{
                                    height: "32px",
                                    margin: "3px 9px 0px 3px",
                                  }}
                                  onClick={() => setShowJobCollection(true)}
                                >
                                  {"..."}
                                </button>
                              </div>
                              {/* <div className="form-check" style={{ marginTop: "20px" }}>
                              <Field
                                name={"Archive"}
                                component={Checkbox}
                                label={lang.archive}
                                onChange={(e) => onChange(e)}
                                checked={dataItem.Archive}
                              />
                            </div> */}
                            { showPlatform && <div className="col-6">
                                <label>{lang.platform_label} *</label>
                                <DropDownList
                                  style={{ backgroundColor: "white" }}
                                  data={PLATFORM.filter((x)=>x.SID > 0)}
                                  name="Platform"
                                  textField="Description"
                                  dataItemKey="SID"
                                  value={dataItem.Platform}
                                  onChange={onChange}
                                />
                              </div>}
                            </div>
                          </div>
                        </div>
                      </div>
                    </FormElement>
                  )}
                />
              </div>
            </div>
          </BossDialog>
        )}
        {showJobCollection && (
          <CollectionSelection
            title={lang.select_playback_url_header}
            setDataList={handleJobData}
            entityname={ENTITYNAME.OttTranscodeStatus}
            closeForm={() => setShowJobCollection(false)}
            mode={"single"}
            width={"50vw"}
            height={"64vh"}
          />
        )}
      </div>
    </div>
  );
}

export default OttAssetVideoEditForm;
