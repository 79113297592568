import React, { useEffect, useRef, useState } from "react";
import * as API from "../API/api"
import { ENTITYNAME, LOCALSTORAGE_KEY, MODULE, ROLETYPE } from "../constant/constant";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import DeleteRoundButton from "../forms/helpercomponents/buttons/DeleteRoundButton";
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from "react-router";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { utility } from "../utility/utilityProvider";
import { ConfirmAlert, ConfirmDeleteAlert } from "../../ConfirmAlert";
import RoleModuleCollectionSelection from "./RoleModuleCollectionSelection";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CollectionSelection from "../forms/CollectionSelection";
import RoundButton from "../forms/helpercomponents/buttons/RoundButton";
import UserRestrictionPopup from "./Component/UserRestrictionPopup";

const UserEditForm = (props) => {

  const { SID } = useParams();
  const loc = useLocation();
  const navigate = useNavigate();
  const lang = useTranslation();
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);
  const user = utility.getValue(LOCALSTORAGE_KEY.userData);
  
  const [tabNumber, setTabNumber] = React.useState("1");
  const [companies, setCompanies] = useState([]);

  const [dataItem, setDataItem] = useState({
    SID: SID,
    name: '',
    userName: '',
    password: '',
    ConfirmPassword: '',
    Department: {},
    ReportingTo: {},
    SalesTeam: {},
    PhoneNo: '',
    Email: '',
    POBox: '',
    Address: '',
    Role: [],
    Archive: false,
    Module: [],
    Channels: []
  })

  const [gridData, setGridData] = useState([]);
  const [gridDataChannel, setGridDataChannel] = useState([]);
  const [department, setDepartment] = useState([]);
  const [salesTeam, setSalesTeam] = useState([]);
  const [roleTypes, setRoleTypes] = useState([]);
  const [showCollectionSelectionRole, setShowCollectionSelectionRole] = useState(false);
  const [showCollectionSelectionModule, setShowCollectionSelectionModule] = useState(false);
  const [showChannelCollection, setShowChannelCollection] = useState(false);
  const [filterData, setFilterData] = useState([]);

  //RESTRICTIONS
  const [ restrictionToggle, setRestrictionToggle ] = useState(false);
  const [ selectedModule, setSelectedModule ] = useState(null);
  const [ userRestrictions, setUserRestrictions ] = useState([]);


  const moduleRef = useRef([]);
  const [modules,_setModules] = useState([]);
  const setModules = (data) => {
    _setModules(data);
    moduleRef.current = data
  }

  const loggedInUserRoles = useRef([]);
  const loggedInUserChannels = useRef([]);
  const roleRef = useRef([]);
  const [role, _setRole] = useState([]);
  const setRole = (data) => {
    _setRole(data);
    roleRef.current = data;
    loggedInUserRoles.current = user.Role && user.Role.length > 0 ? data.filter((x) => user.Role.find((y) => y == x._id)) : []
  }

  const reportingToRef = useRef([]);
  const [reportingTo, _setReportingTo] = useState([]);
  const setReportingTo = (data) => {
    _setReportingTo(data);
    reportingToRef.current = data;
  }

  useEffect(() => {
    pageRender();
  }, []);

  const pageRender = async() => {
    await loadcombo();
    if (SID > 0) {
      await loadEditData();
    }
  }

  const loadcombo = async () => {

    const Module = await API.getDataLookup(ENTITYNAME.Module);
    setGridData(Module.data.filter((x) => x.SID == 1));
    setFilterData(Module.data.filter((x) => x.SID == 1));
    setModules(Module.data);

    const channelRes = await API.getDataLookup(ENTITYNAME.Channel);
    loggedInUserChannels.current = channelRes.data && channelRes.data.length > 0 && channelRes.data.filter((x) => 
      user.Channels && user.Channels.length > 0 && user.Channels.some((y) => y == x._id));

    const departmentRes = await API.getDataLookup(ENTITYNAME.Department);
    setDepartment(departmentRes.data);
    const reportingToRes = await API.getDataLookup(ENTITYNAME.Users);
    setReportingTo(reportingToRes.data);
    const salesTeamRes = await API.getDataLookup(ENTITYNAME.SalesTeam, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    setSalesTeam(salesTeamRes.data);
    const roleRes = await API.getDataLookup(ENTITYNAME.Role, { sort: { SID : 1 }, query: ['Archive', '=', false] });
    setRole(roleRes.data);
    const companiesRes = await API.getDataLookup(ENTITYNAME.Company, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    setCompanies(companiesRes.data);

    let rolesTypes = (Object.keys(ROLETYPE).map((item, index) => {
      return { "NAME": item, "ID": index }
    }));

    if (user.RoleType.ID != ROLETYPE.SuperAdmin) {
      rolesTypes.splice(0, 1);
    }
    setRoleTypes(rolesTypes);

    if(SID == 0){
      setDataItem({ ...dataItem, Department: utility.getDefaultItem(departmentRes.data), Company: companiesRes.data?.length > 0 ? companiesRes.data[0] : {} });
    }

    if(props.IsSalesExecutive){
      //x.SID == 22 FOR SETTING UP THE SALE RELATED MODULE TO SALES EXECUTIVE AUTOMATICALLY (NEED TO CHECK THIS)
      let salesExecutiveRole = roleRes.data.find((x) => x.SID == 22)
      console.log(salesExecutiveRole)

      setDataItem({ ...dataItem,RoleType : rolesTypes.find((x) => x.ID == ROLETYPE.User), Role: [salesExecutiveRole], Department : departmentRes.data.find((x) => x.Description == "Sales") });
      setGridData(salesExecutiveRole.Module.map((y) =>  moduleRef.current.find((z) => z.SID == y)))
    }

  }

  const loadEditData = async () => {

    let linkData = {
      link_collection: 'channel',
      link_field: 'Channels'
    }

    const res = await API.getEntity(ENTITYNAME.Users, parseInt(SID), linkData);

    if (res.success) {
      let roles = res.data.Role && res.data.Role.length > 0 ? res.data.Role.map((x) => roleRef.current.find((y) => y._id == x)) : []
      let modules = res.data.Module && res.data.Module.length > 0 ? moduleRef.current.filter((x) => res.data.Module.some((y) => y == x.SID)) : []

      setDataItem({
        ...dataItem, 
        ...res.data,
        name: res.data.SID ? loc.state?.copy ? 'Copy of ' + res.data.name : res.data.name : res.data.name ,
        userName: res.data.SID ? loc.state?.copy ? 'Copy of ' + res.data.userName : res.data.userName : res.data.userName ,
        password : res.data.SID ? loc.state?.copy ? '' : res.data.password : '' ,
        ReportingTo : res.data.ReportingTo,
        Role : roles
      })

      setGridData(() => modules ?? []);
      setFilterData(() => modules ?? []);
      setGridDataChannel(() => res.data.Channels ?? []);
      setUserRestrictions(res.data.Restrictions ?? []);
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const onchange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const isValid = () => {

    if (dataItem.name == undefined || dataItem.name == "") {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.userName == undefined || dataItem.userName == "") {
      toast.error(`${lang.please_enter_user_name_users_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(loc.state?.copy || SID == 0){
      if (dataItem.password == undefined || dataItem.password == "") {
      toast.error(`${lang.please_enter_password_users_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ConfirmPassword == undefined || dataItem.ConfirmPassword == "") {
      toast.error(`${lang.please_enter_confirm_password_users_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.password !== dataItem.ConfirmPassword ) {
      toast.error(`${lang.password_do_not_match_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }}

    if (Object.keys(dataItem.Department).length == 0) {
      toast.error(`${lang.please_select_department_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (props.IsSalesExecutive && Object.keys(dataItem.ReportingTo).length == 0) {
      toast.error(`${lang.please_select_reportingto_user_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (props.IsSalesExecutive && Object.keys(dataItem.SalesTeam).length == 0) {
      toast.error(`${lang.please_select_sales_team_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Email == undefined || dataItem.Email == "" || !emailRegex.test(dataItem.Email)) {

      toast.error(`${lang.please_enter_email_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!props.IsSalesExecutive && Object.keys(dataItem.Role).length == 0) {
      toast.error(`${lang.please_select_role_users_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.RoleType) {
      toast.error(`${lang.please_select_roletype_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!props.IsSalesExecutive && (gridData.length == 0)) {
      toast.error(`${lang.please_add_atleast_one_module_rights_users_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if(user.RoleType.ID == ROLETYPE.SuperAdmin && (!dataItem.Company || Object.keys(dataItem.Company).length == 0)){
      toast.error(lang.please_select_company_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true
  }

  const handleSubmit = async () => {

    if (isValid()) {
      let localData = {
        ...dataItem,
        SID: loc.state?.copy ? 0 : parseInt(dataItem.SID),
        name: dataItem.name,
        Department: { _id : dataItem.Department._id ,SID : dataItem.Department.SID ,Name : dataItem.Department.Name},
        userName: dataItem.userName,
        password: dataItem.password,
        ConfirmPassword: dataItem.ConfirmPassword,
        PhoneNo: dataItem.PhoneNo,
        Email: dataItem.Email?.toLowerCase(),
        address: dataItem.Address,
        POBox: dataItem.POBox,
        ReportingTo: dataItem.ReportingTo ? {_id : dataItem.ReportingTo._id ,name : dataItem.ReportingTo.name} : {},
        SalesTeam: {
          _id : dataItem.SalesTeam._id,
          SID : dataItem.SalesTeam.SID,
          Name : dataItem.SalesTeam.Name,
        },
        Role: dataItem.Role && dataItem.Role.length > 0 ?  dataItem.Role.map((x) => x._id) : [],
        Archive: dataItem.Archive,
        IsSalesExecutive: props.IsSalesExecutive,
        Module: gridData && gridData.length > 0 ?  gridData.map((x) => x?.SID) : [],
        Channels: gridDataChannel.length > 0 ? gridDataChannel.map(x => x?._id) : [],
        Restrictions : userRestrictions ? userRestrictions : [],
        Company: user.RoleType.ID == ROLETYPE.SuperAdmin ? {_id: dataItem.Company._id, Name: dataItem.Company.Name} : { _id: utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id, Name: utility.getValue(LOCALSTORAGE_KEY.COMPANY)?.Name },
      }

      if(loc.state?.copy){
        delete localData._id;
      }
      delete localData.ConfirmPassword;

      if(!loc.state?.copy && SID > 0){
        delete localData.password
      }
      console.log(localData);

      let checkUserExists = await API.checkUserExists(localData?._id, localData.Email);
      if(!checkUserExists.success){
          toast.error(checkUserExists.message);
          return;
      }

      let res = await API.saveData(ENTITYNAME.Users, localData);

      console.log(res);
      if (res.success) {
        //SAVING USER PREFERENCE SAME AS ADMIN FOR NEW USER 
        if ((loc.state?.copy || SID == 0) && !props.IsSalesExecutive) {
          API.saveAdminUserPreferenceToNewUser(res.data._id);
        }
        navigate(-1);
        utility.deleteLocalStorageItem(ENTITYNAME.Users);
        return;
      }
      else {
        toast.error(`${lang.user_name_error_message} ` + res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }

  }

  const viewCollectionSelectionRole = () => {
    setShowCollectionSelectionRole(!showCollectionSelectionRole);
  }

  const viewCollectionSelectionModule = () => {
    setShowCollectionSelectionModule(!showCollectionSelectionModule);
  }

  const deleteAllGridData = () => {
    setGridData(gridData.filter(x=> x?.SID == MODULE.DASHBOARD));
    setFilterData(filterData.filter(x=> x?.SID == MODULE.DASHBOARD));
    toast.success(`${lang.delete_all_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const deleteAllGridDataConfirmAlert = () => {
    if (gridData.length != 0) {
      ConfirmDeleteAlert(() => deleteAllGridData(), () => { })
    }
  }

  const deleteAllChannelGridData = () => {
    setGridDataChannel([]);
    toast.success(`${lang.delete_all_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
  const deleteAllChannelGridDataConfirmAlert = () => {
    if (gridDataChannel.length != 0) {
      ConfirmDeleteAlert(() => deleteAllChannelGridData(), () => { })
    }
  }

  const checkModuleRights = (data, label) => {

    //super Admin has all rights
    if (user.RoleType.ID == ROLETYPE.SuperAdmin) {
      return data;
    }

    //check if user module has right for selected module
    let hasRights = [];
    let hasNoRights = [];
    if (data.length > 0 && user.Module.length > 0) {
      data.map((x) => {
        let filterData = user.Module.filter((y) => y._id == x._id);
        if (filterData.length > 0) {
          hasRights = [...hasRights, ...filterData]
        } else {
          hasNoRights.push(x);
        }
      })
    }
    console.log(hasRights);
    console.log(hasNoRights);

    //if hasNoRights is not empty
    if (hasNoRights.length > 0) {
      var msg = hasNoRights.length > 1 ? `${hasNoRights.length} ${label}` : hasNoRights[0].Name;
      // console.log(`You can't add ${msg}, you do not have rights !!`);

      //message -- `You can't add modules you do not have rights for !!`
      // toast.error(`You can't add ${msg}, you do not have rights !!`, {
      //   position: toast.POSITION.TOP_RIGHT
      // });
    }
    return hasRights;
  }

  const handleGridDataRole = (dataList) => {
    let tempData = [];

    for (const element of dataList) {
      let modules = element.Module.map((x) => moduleRef.current.find((y) => y.SID == x));
      tempData.push(...modules)
    }

    let newData = utility.checkDuplicateInData(tempData,gridData, "Name")

    //check if user module has right for selected module
    let hasRights = checkModuleRights(newData, `modules from ${dataList[0].Name} role`)
    setGridData((old) => [...old, ...hasRights]);
    setFilterData((old) => [...old, ...hasRights]);
  }

  const handleGridDataModule = (dataList) => {

    let newData = utility.checkDuplicateInData(dataList,gridData, "Name")

    //check if user module has right for selected module
    let hasRights = checkModuleRights(newData, 'modules')
    setGridData((old) => [...old, ...hasRights]);
    setFilterData((old) => [...old, ...hasRights]);
  }

  const deleteSelectedGridData = (dataItem) => {
    if(dataItem.SID == 1 ){
      toast.info(`${lang.can_not_delete_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    setGridData(gridData.filter((obj) => dataItem.SID != obj.SID));
    setFilterData(filterData.filter((obj) => dataItem.SID != obj.SID));

    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const deleteSelectedChannelGridData = (dataItem) => {
    setGridDataChannel(gridDataChannel.filter((obj) => dataItem.SID != obj.SID));
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const onAddRestriction = (item) => {
    if(restrictionToggle && selectedModule.SID == item.SID){
      setRestrictionToggle(false);
      setSelectedModule(null);
    } else {
      setRestrictionToggle(true);
      setSelectedModule(item);
    }
  }

  const MyCommandCell = (props) => (
    <td style={{ display: "flex", padding: "0px" }} className="mt-1" >
      <RoundButton icon={"ban"} title={"Add Restriction"} onClick={() => onAddRestriction(props.dataItem)} />
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteSelectedGridData(props.dataItem), () => { })} />
    </td>
  )

  const MyCommandCellChannel = (props) => (
    <td style={{ padding: "3px" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteSelectedChannelGridData(props.dataItem), () => { })} />
    </td>
  )

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const handleGridDataChannel = (dataList) =>{
    let newData = utility.checkDuplicateInData(dataList,gridDataChannel, "FullChannelName")
    setGridDataChannel((old) => [...old, ...newData]);
  }

  let defaultKeys = [];

  const onSearch = (e) => {
    e.preventDefault();
    const keyword = e.target.value?.trim();
    let datakey = Object.keys(gridData[0]);
    datakey?.map((key) => {
        if (key == "Name" && !defaultKeys.includes(key)) {
            defaultKeys.push(key);
        }
    });
    let dataStore = [];
    if (keyword != "") {
        defaultKeys?.map((item) => {
            let filterDataValue = gridData.filter((obj) => obj[item] && obj[item].toLowerCase().includes(keyword.toLowerCase()));
            if (filterDataValue.length != 0) {
                dataStore.push(...filterDataValue);
            }
        });
        const uniqData = [...new Set(dataStore)];
        setFilterData(uniqData);
    } else {
        setFilterData(gridData);
    }
  }

  const onSubmit = () => {
    if(gridDataChannel.length == 0 && !props.IsSalesExecutive){
      ConfirmAlert(()=> handleSubmit() , ()=>{}, lang.warning_label, lang.user_channel_warning_message)
    } else {
      handleSubmit();
    }
  }

  return (<>
    <div className="row m-1">
      <div className="col-12">
        <EditPageHeader title={(props.IsSalesExecutive ? `${lang.sales_executive_editpage_header}  -> ` : `${lang.users_editpage_header}  -> `) + (dataItem.name == "" ? `${lang.new_title_editpageheader}` : dataItem.name)} onSubmit={onSubmit} onCancel={() => navigate(-1)} />
        <div className="row mt-2">
          <div className="col-12">
            <div className="row">
              <div className={(SID == 0 || loc.state?.copy) ? "col-3 form-group" :  "col-6 form-group" }>
                <label htmlFor="TabView">{lang.name_label} *</label>
                <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"name"} onChange={onchange} value={dataItem.name} />
              </div>
              <div className={(SID == 0 || loc.state?.copy) ? "col-3 form-group" :  "col-6 form-group" }>
                <label htmlFor="TabView">{lang.username_label} *</label>
                <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"userName"} onChange={onchange} value={dataItem.userName} />
              </div>
             {(SID == 0 || loc.state?.copy) && <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.password_label} *</label>
                <input className="form-control form-control-sm" type="password" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"password"} onChange={onchange} value={dataItem.password} />
              </div>}
              {(SID == 0 || loc.state?.copy) && <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.confirm_password_label} *</label>
                <input className="form-control form-control-sm" type="password" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"ConfirmPassword"} onChange={onchange} value={dataItem.ConfirmPassword} />
              </div>}
            </div>
            <div className="row">
              <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.department_label} *</label><br />
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={department}
                  name="Department"
                  textField="Name"
                  dataItemKey="SID"
                  value={dataItem.Department}
                  onChange={onchange}
                />
              </div>
              <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.reporting_to_lable} {props.IsSalesExecutive && '*'}</label><br />
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={reportingTo}
                  name="ReportingTo"
                  textField="name"
                  dataItemKey="SID"
                  value={dataItem.ReportingTo}
                  onChange={onchange}
                />
              </div>
              <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.phone_no_label}</label>
                <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"PhoneNo"} onChange={onchange} value={dataItem.PhoneNo} />
              </div>
              <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.email_label} *</label>
                <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Email"} onChange={onchange} value={dataItem.Email} />
              </div>
            </div>
            <div className="row">
              <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.pobox_label}</label>
                <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"POBox"} onChange={onchange} value={dataItem.POBox} />
              </div>
              <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.address_label}</label>
                <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Address"} onChange={onchange} value={dataItem.Address} />
              </div>
              <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.role_label} *</label><br />
                <MultiSelect
                  style={{ backgroundColor: "white" }}
                  data={role}
                  name="Role"
                  textField="Name"
                  dataItemKey="SID"
                  value={dataItem.Role}
                  onChange={onchange}
                  disabled = {props.IsSalesExecutive}
                />
              </div>
              <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.role_type_label} *</label><br />
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={roleTypes}
                  name="RoleType"
                  textField="NAME"
                  dataItemKey="ID"
                  value={dataItem.RoleType}
                  onChange={onchange}
                  disabled = {props.IsSalesExecutive}
                />
              </div>
            </div>
            <div className="row">
              {props.IsSalesExecutive && <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.sales_team_label} {props.IsSalesExecutive && '*'}</label><br />
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={salesTeam}
                  name="SalesTeam"
                  textField="Name"
                  dataItemKey="SID"
                  value={dataItem.SalesTeam}
                  onChange={onchange}
                />
              </div>}
              {user?.RoleType?.ID == ROLETYPE.SuperAdmin && <div className="col-3 form-group">
                <label htmlFor="TabView">{lang.company_label}</label>
                <DropDownList
                  style={{ backgroundColor: "white" }}
                  data={companies}
                  name="Company"
                  textField="Name"
                  dataItemKey="SID"
                  value={dataItem.Company}
                  onChange={onchange}
                />
              </div>}
              <div className="ml-3 mt-4 form-group">
                <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onchange} />
                <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
              </div>
            </div>
          </div>
          {!props.IsSalesExecutive && <div className="row">
            <div className="col-12">
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabNumber}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="tabs example">
                      <Tab label={lang.role_tab_label} value="1" />
                      <Tab label={lang.channel_tab_label} value="2" />
                    </TabList>
                  </Box>
                  <TabPanel value={"1"}>
                    <div className="row">
                      <div className="col">
                        <button className="btn-square btn-success rounded" onClick={viewCollectionSelectionRole} >{lang.add_role_button_text}</button>
                        <button className="btn-square btn-primary ml-2 rounded" onClick={viewCollectionSelectionModule} >{lang.add_module_collection_text}</button>
                        <button className="btn-square btn-danger ml-2 rounded" onClick={deleteAllGridDataConfirmAlert} >{lang.delete_all_button_text}</button>
                        <div className="pull-right">
                          <div className="input-group input-group mb-3" >
                              <input type="text" className="form-control shadow-inset-2 pt-0 pb-0 ml-2" id="searchInput" aria-label="type 2 or more letters" placeholder={lang.search_button_text} onChange={onSearch} />
                              <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                  </span>
                                </div>
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className={restrictionToggle ? "col-6" : "col-12"}>
                      <Grid style={{ height: "40vh", margin: '0px 10px 40px 10px', overflow: "hidden" }} data={filterData} >
                        <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
                        <GridColumn field="Name" title={lang.module_column} editable={false} on />
                      </Grid>
                      </div>
                      {restrictionToggle &&<div className={"col-6"}>
                        <UserRestrictionPopup module = {selectedModule} restrictions = {userRestrictions} setUserRestrictions={setUserRestrictions}/>
                      </div>}
                    </div>
                  </TabPanel>
                  <TabPanel value={"2"}>
                    <button className="btn-square btn-success rounded" onClick={()=>setShowChannelCollection(true)} >{lang.add_channel_button_text}</button>
                    <button className="btn-square btn-danger ml-2 rounded" onClick={deleteAllChannelGridDataConfirmAlert} >{lang.delete_all_button_text}</button>
                    <Grid style={{ height: "40vh", margin: '0px 10px 40px 10px', overflow: "hidden" }} className="mt-2" data={gridDataChannel}>
                      <GridColumn cell={MyCommandCellChannel} width={"40px"} locked={true} />
                      <GridColumn field="FullChannelName" title={lang.channel_column} editable={false} />
                    </Grid>
                  </TabPanel>

                </TabContext>
              </Box>
            </div>
          </div>}
        </div>
      </div>
    </div>

    {showCollectionSelectionRole && <RoleModuleCollectionSelection data={(user.RoleType.ID == ROLETYPE.SuperAdmin || user.RoleType.ID == ROLETYPE.Admin) ? [] : loggedInUserRoles.current} perPage={1000} addButtonTitle={lang.select_collection_button_text} title={lang.select_Role_collection_header} entityname={ENTITYNAME.Role} setDataList={handleGridDataRole} closeForm={viewCollectionSelectionRole} width={"50vw"}/>}

    {showCollectionSelectionModule && <RoleModuleCollectionSelection showProductFilter={true} data={(user.RoleType.ID == ROLETYPE.SuperAdmin || user.RoleType.ID == ROLETYPE.Admin) ? [] : user.Module} perPage={1000} addButtonTitle={lang.select_collection_button_text} title={lang.select_module_collection_header} entityname={ENTITYNAME.Module} setDataList={handleGridDataModule} closeForm={viewCollectionSelectionModule} width={"50vw"}/>}
    
    {showChannelCollection && <CollectionSelection data={user.RoleType.ID == ROLETYPE.SuperAdmin ? [] : loggedInUserChannels.current} perPage={1000} addButtonTitle={lang.select_collection_button_text} title={lang.select_channel_collection_header} entityname={ENTITYNAME.Channel} setDataList={handleGridDataChannel} closeForm={()=>setShowChannelCollection(false)} width={"50vw"} searchByColumn={'FullChannelName'} />}
  </>)
}
export default UserEditForm