/* eslint-disable */
import { useRef, useState } from "react";
import ManageContentHeader from "./manageContentHeader";
import ManageContentFoooter from "./manageContentFooter";
import ManageContentCollection from "./manageContentCollection";

const ManageContent = () => {

    const [showExport, setShowExport] = useState(false);
    const footerRef = useRef();
    const collectionRef = useRef();

    return (<>
        <ManageContentHeader onLoad={(item)=> collectionRef.current.handleLoad(item)} setSearchText={(text) => collectionRef.current.setSearchText(text)} setFilterTemplate={(data)=> collectionRef.current.setFilterTemplate(data)} showExport={showExport} setShowExport={setShowExport} />
        <div style={{ height: "75vh" }}>
            <ManageContentCollection ref={collectionRef} setSelectedItemCount={footerRef.current?.setSelectedItemCount} />
        </div>
        <ManageContentFoooter ref={footerRef} />
    </>)
}

export default ManageContent;