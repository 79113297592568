import React, { useRef, useState } from 'react';
import * as API from "../../framework/API/api";
import HeaderRowAndSheetPopup from '../PartnerIngestion/SubComponents/HeaderRowAndSheetPopup';
import { utility } from '../../framework/utility/utilityProvider';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import BackRoundButton from '../../framework/forms/helpercomponents/buttons/BackRoundButton';
import { useNavigate } from 'react-router-dom';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { ApplicationConfigurationKeys } from '../../framework/constant/constant';

const EPGConvertor = () => {

    const lang = useTranslation();
    const navigate = useNavigate();
    const _export = useRef(null);

    const [fileDataItem, setFileDataItem] = useState({
        sourceEpgFile: "",
        optaFile: "",
        imageUrlFile: ""
    });

    const [inputFiles, setInputFiles] = useState({

        sourceEpgFile: [],
        optaFile: [],
        imageUrlFile: []

    })

    const [data, setData] = useState({

        sourceEpgFile: { data: [], columns: [] },
        optaFile: { data: [], columns: [] },
        imageUrlFile: { data: [], columns: [] },

    })

    const selectedFileRef = useRef(null);

    const [convertedEpgData, setConvertedEpgData] = useState([]);


    const [showHeaderRowSheetIndexPopup, setShowHeaderRowSheetIndexPopup] = useState(false);
    const [selectedExcelSheets, setSelectedExcelSheets] = useState([]);
    const [headerRowSheetIndex, setHeaderRowSheetIndex] = useState({
        HeaderRowIndex: 1,
        SheetIndex: 0,
    });

    const [tabValue, setTabValue] = useState('1');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleFileChange = async (e) => {

        let fileInput = e.target;
        setFileDataItem({ ...fileDataItem, [fileInput.name]: fileInput.value });
        setInputFiles({ ...inputFiles, [fileInput.name]: fileInput.files[0] })

        //ON SELECT FILE SET DATA IN GRID
        let excelSheetsRes = await API.getExcelSheets(fileInput.files[0]);
        if (excelSheetsRes.success && excelSheetsRes.data.length > 0) {
            setSelectedExcelSheets(excelSheetsRes.data);
        }
        setHeaderRowSheetIndex({ HeaderRowIndex: 1, SheetIndex: 0 });
        setShowHeaderRowSheetIndexPopup(true);
        selectedFileRef.current = fileInput.name;

        fileInput.value = ""

    }


    const getDataFromFile = async (files) => {

        let res = await API.readFile(files, headerRowSheetIndex.HeaderRowIndex - 1, headerRowSheetIndex.SheetIndex);
        console.log(res);

        if (res.success && res.data && res.data?.data.length > 0) {
            //CREATE CORRECT DATA 

            let data = [];
            // if (!utility.checkValidImportFile(res.data.columns, mappingKeys)) {
            //   return { success: false, data: [], message: lang.invalid_file_error_message };
            // }

            res.data.data.forEach((x) => {
                let obj = {};
                res.data.columns.forEach((col, i) => {
                    obj = { ...obj, [col]: x[i] }
                });
                data.push(obj);
            });
            return { success: true, data: data, columns: res.data.columns, message: 'File Data' };;
        } else {
            return { success: false, data: [], columns: [], message: res.data?.data.length == 0 ? "Not data found" : "Error While Reading File !!" };
        }

    }

    const loadDataOnFileSelect = async (files) => {

        // if (!fileDataItem.file) {
        //     toast.error(`${lang.please_select_file_first_error_message}`, {
        //         position: toast.POSITION.TOP_RIGHT
        //     });
        //     return
        // }

        let fileData = await getDataFromFile(files);
        console.log(fileData)
        if (fileData.success) {
            console.log(fileData);
            setData({ ...data, [selectedFileRef.current]: { data: fileData.data, columns: fileData.columns } })
        } else {
            toast.error(fileData.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        selectedFileRef.current = null

        setShowHeaderRowSheetIndexPopup(false);

    }

    const handleOnHeaderRowSheetIndexConfirm = () => {
        loadDataOnFileSelect(inputFiles[`${selectedFileRef.current}`]);
    }

    const generateConvertedEPG = () => {


        //VALIDATION
        if (!data.sourceEpgFile.data || data.sourceEpgFile.data.length == 0) {
            toast.error("No Source EPG data found !", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (!data.imageUrlFile.data || data.imageUrlFile.data.length == 0) {
            toast.error("No Image Url data found !", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (!data.optaFile.data || data.optaFile.data.length == 0) {
            toast.error("No OPTA data found !", {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        //NEED TO ASK IF FETCH CHANNEL OR NOT
        //epgCOnvertorBUfferTime;
        let convertedEPG = [];

        data.sourceEpgFile.data.map((x) => {

            let bufferTime = utility.getApplicationConfigurationByKey(ApplicationConfigurationKeys.epgConvertorBufferTime);
            let slotDateTime = utility.convertStringDatetoMilliseconds(x.date) + utility.convertStringTimeToMilliseconds(x.start_time) + bufferTime;
            let startDate = utility.convertMilisecondsToDateString(slotDateTime);
            let startTime = utility.convertMilisecondsToShortTimeString(slotDateTime);
            let imageUrl = data.imageUrlFile.data.find((y) => y.Program.toUpperCase() == x.title1.trim());

            let optaData = null;

            if (x.title1.includes("MATCH HIGHLIGHTS") && x.title2.slice(0, 4).includes("MW")) {
                let [homeTeam, awayTeam] = x.title2.slice(5, x.title2.length).split(" v ");

                optaData = data.optaFile.data.find((z) =>
                    parseInt(z.MatchDay) == parseInt(x.title2.slice(2, 4)) &&
                    (z["Home Team name"].trim().includes(homeTeam) || z["Home Team name"].trim().includes(homeTeam.replace("&", "and"))) &&
                    (z["Away Team name"].trim().includes(awayTeam) || z["Away Team name"].trim().includes(awayTeam.replace("&", "and")))
                )
            }

            let lineObj = {
                Channel_Number: "1221",
                Channel_Name: "Hub Premier 1",
                StartDate: startDate,
                StartTime: startTime,
                Programme_Title: x.title1,
                Programme_Title_Alt_Lang: "",
                Episode_Number: "1",
                Episode_Title: x.title2,
                Episode_Title_Alt_Lang: "",
                Programme_Status_ID: x.source != "SERVER" ? "L" : "",
                Cast: "",
                Cast_Alt_Lang: "",
                Director: "",
                Director_Alt_Lang: "",
                Synopsis: x.synopsis,
                Synopsis_Alt_Lang: "",
                Programme_Genre1: x.genre,
                Programme_Sub_Genre1: "",
                Audio_Track: "eng",
                Programme_Language_ID: "eng",
                ParentalRating: "",
                TotalNumberOfEpisodes: "",
                ETV_display: "",
                IsBlackedOut: "",
                IsCatchUp: x.linkFlag == "1" ? "TRUE" : "",
                IsStartOver: "",
                Short_Display_Title: "",
                Short_Display_Title_Alt_Lang: "",
                ImageURL: imageUrl && imageUrl["Corresponding Image URL"] ? imageUrl["Corresponding Image URL"] : "",
                ImageURL2: "",
                ["Game ID"]: optaData ? optaData?.["Match ID"] : "",
                HomeTeam: optaData ? optaData?.["Home Team ID"] : "",
                AwayTeam: optaData ? optaData?.["Away Team ID"] : "",
                ProviderID: "EPL"
            }

            convertedEPG.push(lineObj);

        })

        setConvertedEpgData(convertedEPG);
        setTabValue('4');

    }

    const excelConverEpgExport = () => {
        setTabValue('4');
        setTimeout(() => {
            if (_export.current !== null) {
                _export.current.save();
            }
        },1000)
    };

    return (<>
        <h1 className="page-title txt-color-bludeDark">
            <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }}></i>
            <span>{"EPG Convertor"}</span>
        </h1>
        <div className="flex-container">
            <div className="mar">
                <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
            </div>
            <div className="flex-container-reverse flex-item-auto">
                {convertedEpgData && convertedEpgData.length > 0 && <ActionButton title={lang.download_button_tooltip} name={lang.download_button_text} btnColor={'warning'} onClick={excelConverEpgExport} />}
                <ActionButton title={lang.converted_epg_tab_label} name={lang.convert_button_label} btnColor={'info'} onClick={generateConvertedEPG} />
            </div>
        </div>

        <div className='row mt-2'>
            <div className='col box-shadow-container' style={{ margin: '0px 15px' }} >

                <div className="row" style={{ margin: "5px", padding: "15px" }}>

                    <div className="col" style={{ padding: '0px' }}>
                        <label className="form-label">{lang.source_epg_file_label}</label>
                        <input type="text" className="form-control form-control-sm" name="filename" value={fileDataItem.sourceEpgFile ?? ""} disabled={true} />
                    </div>

                    <div className="col-md-1 col-lg-1 col-sm-1 col-xs-1" style={{ margin: "5px", padding: '0px' }}>
                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary" style={{ padding: '0px', height: "32px", margin: '20px 0px 0px 3px' }}>
                            <label className="fa-solid fa-ellipsis-h" style={{ padding: '5px 9px', margin: '0px', cursor: 'pointer' }}>
                                <input id="fileupload" name="sourceEpgFile" className='custom-file-updated-input form-control form-control-sm' type="file" hidden="hidden" onChange={handleFileChange} />
                            </label>
                        </button>
                    </div>

                </div>
            </div>
            <div className='col box-shadow-container'>

                <div className="row" style={{ margin: "5px", padding: "15px" }}>

                    <div className="col" style={{ padding: '0px' }}>
                        <label className="form-label">{lang.opta_file_label}</label>
                        <input type="text" className="form-control form-control-sm" name="filename" value={fileDataItem.optaFile ?? ""} disabled={true} />
                    </div>

                    <div className="col-md-1 col-lg-1 col-sm-1 col-xs-1" style={{ margin: "5px", padding: '0px' }}>
                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary" style={{ padding: '0px', height: "32px", margin: '20px 0px 0px 3px' }}>
                            <label className="fa-solid fa-ellipsis-h" style={{ padding: '5px 9px', margin: '0px', cursor: 'pointer' }}>
                                <input id="fileupload" name="optaFile" className='custom-file-updated-input form-control form-control-sm' type="file" hidden="hidden" onChange={handleFileChange} />
                            </label>
                        </button>
                    </div>

                </div>
            </div>

            <div className='col box-shadow-container' style={{ margin: '0px 15px' }} >

                <div className="row" style={{ margin: "5px", padding: "15px" }}>

                    <div className="col" style={{ padding: '0px' }}>
                        <label className="form-label">{lang.image_url_file_label}</label>
                        <input type="text" className="form-control form-control-sm" name="filename" value={fileDataItem.imageUrlFile ?? ""} disabled={true} />
                    </div>

                    <div className="col-md-1 col-lg-1 col-sm-1 col-xs-1" style={{ margin: "5px", padding: '0px' }}>
                        <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-secondary" style={{ padding: '0px', height: "32px", margin: '20px 0px 0px 3px' }}>
                            <label className="fa-solid fa-ellipsis-h" style={{ padding: '5px 9px', margin: '0px', cursor: 'pointer' }}>
                                <input id="fileupload" name="imageUrlFile" className='custom-file-updated-input form-control form-control-sm' type="file" hidden="hidden" onChange={handleFileChange} />
                            </label>
                        </button>
                    </div>
                </div>
            </div>

        </div>
        <div style={{ margin: '10px' }}>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                        <Tab label={lang.source_epg_tab_label} value="1" />
                        <Tab label={lang.opta_tab_label} value="2" />
                        <Tab label={lang.image_url_tab_label} value="3" />
                        <Tab label={lang.converted_epg_tab_label} value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1" style={{ height: "100%", padding: 0 }} >

                    <Grid data={data.sourceEpgFile.data} style={{ width: '83vw', height: "60vh", marginTop: "10px" }}  >
                        {data.sourceEpgFile.columns.map((column, index) => {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column}
                                    title={column}
                                    width={200}
                                />
                            )
                        })}
                    </Grid>

                </TabPanel>

                <TabPanel value="2" style={{ height: "100%", padding: 0 }} >
                    <Grid data={data.optaFile.data} style={{ width: '83vw', height: "60vh", marginTop: "10px" }}  >
                        {data.optaFile.columns.map((column, index) => {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column}
                                    title={column}
                                    width={200}
                                />
                            )
                        })}
                    </Grid>

                </TabPanel>
                <TabPanel value="3" style={{ height: "100%", padding: 0 }} >
                    <Grid data={data.imageUrlFile.data} style={{ width: '83vw', height: "60vh", marginTop: "10px" }}  >
                        {data.imageUrlFile.columns.map((column, index) => {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column}
                                    title={column}
                                    width={200}
                                />
                            )
                        })}
                    </Grid>

                </TabPanel>
                <TabPanel value="4" style={{ height: "100%", padding: 0 }} >
                    <ExcelExport data={convertedEpgData} ref={_export} fileName={`BS_Converted_${inputFiles.sourceEpgFile.name}`}>
                        <Grid data={convertedEpgData} style={{ width: '83vw', height: "60vh", marginTop: "10px" }}  >
                            {convertedEpgData.length > 0 && Object.keys(convertedEpgData[0]).map((column, index) => {
                                return (
                                    <GridColumn
                                        key={index}
                                        field={column}
                                        title={column}
                                        width={200}
                                    />
                                )
                            })}
                        </Grid>
                    </ExcelExport>
                </TabPanel>
            </TabContext>

        </div>
        {showHeaderRowSheetIndexPopup && <HeaderRowAndSheetPopup onConfirm={handleOnHeaderRowSheetIndexConfirm} onClose={() => { setShowHeaderRowSheetIndexPopup(false) }} sheets={selectedExcelSheets} setHeaderRowSheetIndex={setHeaderRowSheetIndex} />}
    </>)
}

export default EPGConvertor