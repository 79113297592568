/* eslint-disable */
import React, { useCallback, useEffect, useState, useRef } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridColumn, getSelectedState } from '@progress/kendo-react-grid';
import moment from 'moment';
import { ENTITYNAME, FILEURL, LOGEVENT, MODULE, TEMPLATENAME } from '../../framework/constant/constant';
import * as API from '../../framework/API/api';
import CollectionSelection from '../../framework/forms/CollectionSelection'
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton'
import BookingEditForm from '../Sales/BookingEditForm'
import CampaignEditForm from '../Sales/CampaignEditForm'
import { CampaignColumns } from '../../framework/utility/customColumns';
import { getter } from "@progress/kendo-react-common";
import { TimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { toast } from 'react-toastify';
import { WrappedTextCell } from '../../framework/forms/helpercomponents/CustomGridCells/WrappedTextCell';
import { useTranslation } from '../../locale/useTranslation';
import { history } from '../../components/history';
import { UpdateBookingLineNumberCommandCell } from './Update Booking Line Number/UpdateBookingCommandCell';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { ConfirmAlert, ConfirmDeleteAlert } from '../../ConfirmAlert';
import { utility } from '../../framework/utility/utilityProvider';
import { bookingSocket } from '../../framework/socket/bookingSocket';
import { Popup } from "@progress/kendo-react-popup";
import ProgressBarPopup from '../../framework/forms/helpercomponents/ProgressBarPopup';
import { TimeRangePicker } from '../../framework/forms/helpercomponents/timepicker/TimeRangePicker';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import BossDialog from '../../components/BossDialog';

const DATA_ITEM_KEY = "LineNumber";
const SELECTED_FIELD = "selected";
const editField = "inEdit";
const idGetter = getter(DATA_ITEM_KEY);

const DateDayCell = (props) => {
    const field = props.field || "";
    const displayValue = field.split(' ');
    let date = moment(new Date(displayValue[0])).format('YYYY-MMM-DD');

    return (
        <div colSpan={props.colSpan} style={{ padding: "0px", fontSize: '13px', textAlign: "center" }}>
            {`(${displayValue[1]})`}<br />
            {date}

        </div>
    );
};

const MySpotCell = (props) => {
    const field = props.field || "";

    const onChange = (e) => {

        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    const style = {
        backgroundColor: field == 'SpotRate' ? '' : "#C9F4AA",
        border: field == 'SpotRate' ? '' : "1px solid lightgrey",
        textAlign: 'center'
    };

    return <td style={style}> {props.dataItem.inEdit ? (<NumericTextBox name={field} value={props.dataItem[field]} onChange={onChange} min={0} style={{ zIndex: 0 }} />) : props.dataItem[field]} </td>
};

const MyTimeRangeEditCell = (props) => {
    const field = props.field || "";

    const onChange = (e) => {

        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });

        }
    };

    const style = {
        textAlign: 'center'
    };

    return <td style={style}> {props.dataItem.inEdit ? (<TimeRangePicker name={field} value={props.dataItem[field]} onChange={onChange} style={{ zIndex: 0 }} />) : props.dataItem[field]} </td>
};

const MyEditCell = (props) => {
    const field = props.field || "";

    const onChange = (e) => {
        if (props.onChange) {
            props.onChange({
                dataIndex: 0,
                dataItem: props.dataItem,
                field: field,
                syntheticEvent: e.syntheticEvent,
                value: e.target.value,
            });
        }
    };

    const style = {
        textAlign: 'center',
    };

    return <td style={style}> {props.dataItem.inEdit ? (<input type='text' name={field} value={props.dataItem[field]} onChange={onChange} style={{ backgroundColor: 'white', padding: '2px', border: "0.5px solid lightgrey", zIndex: 0 }} />) : props.dataItem[field]} </td>
};

const BookingCollection = (props) => {
    const [selectedState, setSelectedState] = useState({});

    const blankDataItem = {
        Channel: {},
        Commercial: {},
        CampaignType: {},
        DealType: {},
        Position: {}
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [openCampaignSelection, setOpenCampaignSelection] = useState(false);
    const [bookingCollection, setBookingCollection] = useState([]);
    const [editItemSwallowCopy, setEditItemSwallowCopy] = useState([]);
    const [openBookingForm, setOpenBookingForm] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState({ CampaignNo: '' });
    const [showCampaignEditForm, setShowCampaignEditForm] = useState(false);
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    const [days, setDays] = useState([]);
    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const [selectedBookings, setSelectedBookings] = useState([]);

    //footer variables
    const [bookedSpots, setBookedSpots] = useState(0);
    const [currentSpots, setCurrentSpots] = useState(0);
    const [bookedSeconds, setBookedSeconds] = useState(0);
    const [currentSeconds, setCurrentSeconds] = useState(0);
    const [bookedAmount, setBookedAmount] = useState(0);
    const [currentAmount, setCurrentAmount] = useState(0);

    // for import files
    const [campaignType, setCampaignType] = useState([]);
    const [dealType, setDealType] = useState([]);
    const [positions, setPositions] = useState([]);
    const [channels, setChannels] = useState([]);
    const [orderTypes, setOrderTypes] = useState([]);
    const fileRef = useRef(null);
    const [maxBookingLineNo, setMaxBookingLineNo] = useState(1);
    const [initialMaxBookingLineNo, setInitialMaxBookingLineNo] = useState(1);
    const [show, setShow] = useState(false);

    const [isBlocking, setIsBlocking] = useState(false);
    const [saved, setSaved] = useState(true);
    const [gridDataCopy, setGridDataCopy] = useState([]);
    const lang = useTranslation();
    const anchor = useRef();

    useEffect(() => {

        loadcombo();

    }, []);

    useEffect(() => {
        let unblock;
        if (!saved && bookingCollection.length > 0) {
            // Block navigation and register a callback that
            // fires when a navigation attempt is blocked.
            unblock = history.block((tx) => {
                // Navigation was blocked! Let's show a confirmation dialog
                // so the user can decide if they actually want to navigate
                // away and discard changes they've made in the current page.
                // if (window.confirm('Changes you made may not be saved.')) {
                //   // Unblock the navigation.
                //   unblock();

                ConfirmAlert(
                    () => { unblock(); tx.retry() }, // on Click yes
                    () => { }, // on click no
                    lang.confirm_saved_label, //title
                    lang.changes_you_made_may_not_be_saved_label // message
                )
            });
        }
        return () => {
            if (typeof unblock === "function") {
                unblock();
            }
        };
    }, [saved]);

    const loadcombo = async () => {
        let campaignType = await API.getDataLookup(ENTITYNAME.CampaignType, { sort: { Description: 1 } });
        setCampaignType(campaignType.data);

        let dealType = await API.getDataLookup(ENTITYNAME.DealType, { sort: { Description: 1 } });
        setDealType(dealType.data);

        let orderTypes = await API.getDataLookup(ENTITYNAME.OrderType, { sort: { Description: 1 } });
        setOrderTypes(orderTypes.data);

        let positions = await API.getDataLookup(ENTITYNAME.Position, { sort: { Description: 1 } });
        setPositions(positions.data);

        let channels = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannels(channels.data);

    }

    const handleImportPdf = async (e) => {
        console.log('entered');
        let file = e.target.files[0];
        //get file extension
        let fileExtension = file.name.split('.').pop();
        if (fileExtension != 'pdf') {
            toast.error('Please select a pdf file');
            return;
        }
        let readBookingPdfRes = await API.readBookingPdf(file);
        if (readBookingPdfRes.success) {
            console.log(readBookingPdfRes.data);

            const allBookingData = readBookingPdfRes.data.data;

            let monthFromExcel = GetMonthNameFromBookingExcel(dataItem.Month);
            let yearFromExcel = dataItem.Year;

            if (monthFromExcel != dataItem.Month) {
                toast.error(`${lang.wrong_month_selected_booking_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            if (yearFromExcel != dataItem.Year) {
                toast.error(`${lang.wrong_year_selected_booking_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            // ACTUAL BOOKING DATA STARTS FROM 6th AARAY

            let bookingsToSave = [];

            // (maxBookingLineNo > 0 && bookingCollection.length == 0 ) ? maxBookingLineNo+1: getMaxBookingLineNoFromBookingGrid(); //maxBookingLineNo
            let lineNo = maxBookingLineNo;

            for (let i = 3; i < allBookingData.length; i++) {


                let item = allBookingData[i];


                if (isValidToImport(item)) {
                    console.log(item);

                    const isRate10Second = (item[5] !== null && item[5] !== undefined && item[5] !== "") ? true : false;

                    let _commercial = GetCommercial(item[1]);

                    let monthNo = parseInt(month.indexOf(dataItem.Month)) + 1;

                    let rowdata = {
                        UniqueLineNumber: (_commercial) ? selectedCampaign.CampaignNo + "-" + dataItem.Year + "-" + monthNo + "-" + _commercial.AssetId : "",
                        LineNumber: lineNo,
                        Campaign: selectedCampaign,
                        Channel: GetChannel(allBookingData[i][0]),
                        Commercial: _commercial,
                        HouseNumber: item[1],
                        CampaignType: GetCampaignType(item[2]),
                        DealType: dealType[1],
                        OrderType: "",
                        Program: item[3],
                        Duration: (_commercial) ? _commercial.Duration : 0,
                        Position: GetPosition(item[4]),
                        From: item[7],
                        To: item[8],
                        FromTime: item[7],
                        ToTime: item[8],
                        TotalSpot: item[10],
                        TotalFCT: item[11],
                        ProductCode: item[13],
                        TotalAmount: (item[6] * item[10]),
                        IsRate10SecSelected: isRate10Second,
                        SpotRate: !isRate10Second ? item[6] : (item[5] / 10) * ((_commercial) ? _commercial.Duration : 0),
                        Rate10Sec: !isRate10Second ? (item[6] / ((_commercial) ? _commercial.Duration : 0)) * 10 : item[5],
                    };
                    days.forEach((date, index) => {

                        if (item[index + 14]) {
                            rowdata[date] = item[index + 14]; // 14 is start index of spots in excel
                        }
                        else {
                            rowdata[date] = 0;
                        }


                    })
                    lineNo++;
                    setMaxBookingLineNo(lineNo);
                    bookingsToSave.push(rowdata);

                }
            }

            setBookingCollection((old) => {
                old = [...old, ...bookingsToSave]
                UpdateFooterValues(old);
                return [...old]
            });
            setShow(false);
        } else {
            console.log(readBookingPdfRes.message);
            toast.error(readBookingPdfRes.message);
            fileRef.current.value = null;
            setShow(false);
        }

    }

    const handleFileChange = (e) => {
        setIsBlocking(false);
        setShow(false);

        importBookingFromExcel(e.target.files[0]);
        // MAKE THE REF STATE NULL SO THAT IT CAN READ THE SAME FILE AGAIN
        fileRef.current.value = null;

    };

    const isValidToImport = (bookingData) => {
        let isValid = false;

        for (let i = 0; i < bookingData.length; i++) {
            //14 IS THE INDEX OF SPOT DETAILS IN EXCEL
            if (bookingData[i + 14] > 0) {
                isValid = true;
                break;
            }
        }
        return isValid;
    }


    const CreateDatesFromCampain = () => {


        let days = []
        let monthNo = month.indexOf(dataItem.Month) + 1;
        let daysInMonth = new Date(dataItem.Year, monthNo, 0).getDate();//daysInMonth to show            
        for (let i = 0; i < daysInMonth; i++) {
            let firstDate = new Date(dataItem.Year, monthNo - 1, i + 1);

            let day = daysOfWeek[firstDate.getDay()];
            let date = moment(firstDate).format("YYYY-MM-DD");

            days.push(`${date} ${day}`);
        }
        setDays(days);
    }

    const GetMonthNameFromBookingExcel = (monthName) => {
        return month.find(element => element.startsWith(monthName));
    }

    const importBookingFromExcel = async (files) => {

        setIsBlocking(false);

        if (typeof selectedCampaign === "undefined" || selectedCampaign.CampaignNo == '') {
            toast.warn(`${lang.please_select_campaign_first_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let res = await API.readBookingTemplateFile(files);

        let allBookingData = res.data.data;
        console.log(allBookingData);

        if (allBookingData == undefined || allBookingData.length == 0) {
            toast.error(`${lang.error_reading_file_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let monthYearData = allBookingData[0];
        let monthFromExcel = GetMonthNameFromBookingExcel(monthYearData[1]);
        let yearFromExcel = monthYearData[3];

        if (monthFromExcel != dataItem.Month) {
            toast.error(`${lang.wrong_month_selected_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (yearFromExcel != dataItem.Year) {
            toast.error(`${lang.wrong_year_selected_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        // ACTUAL BOOKING DATA STARTS FROM 6th AARAY

        let bookingsToSave = [];
        let lineNo = maxBookingLineNo;//(maxBookingLineNo > 0 && bookingCollection.length == 0 ) ? maxBookingLineNo+1: getMaxBookingLineNoFromBookingGrid(); //maxBookingLineNo
        for (let i = 3; i < allBookingData.length; i++) {

            let item = allBookingData[i];

            if (isValidToImport(item)) {

                let _commercial = GetCommercial(item[1]);

                const commercialDuration = (_commercial) ? _commercial.Duration : 0;
                const isSpotRateEmpty = item[6] == null || item[6] == undefined || item[6] == "";
                const isRate10SecEmpty = item[5] == null || item[5] == undefined || item[5] == "";
                //Change time range from 23:59 to 00:00
                let fromTimeChange = item[7] == "23:59" ? "00:00" : item[7];

                let monthNo = parseInt(month.indexOf(dataItem.Month)) + 1;

                let rowdata = {
                    UniqueLineNumber: (_commercial) ? selectedCampaign.CampaignNo + "-" + dataItem.Year + "-" + monthNo + "-" + _commercial.AssetId : "",
                    LineNumber: lineNo,
                    Campaign: selectedCampaign,
                    Channel: GetChannel(allBookingData[i][0]),
                    Commercial: _commercial,
                    HouseNumber: item[1],
                    CampaignType: GetCampaignType(item[2]),
                    DealType: selectedCampaign.DealType,
                    OrderType: "",
                    Program: item[3],
                    Duration: commercialDuration,
                    Position: GetPosition(item[4]),
                    From: fromTimeChange,
                    To: item[8],
                    FromTime: fromTimeChange,
                    ToTime: item[8],
                    TotalSpot: item[10],
                    TotalFCT: item[11],
                    ProductCode: item[13],
                    TotalAmount: (item[6] * item[10]),
                    SpotRate: isSpotRateEmpty ? 0 : parseFloat(item[6]).toFixed(2),
                    Rate10Sec: 0,
                    IsRate10SecSelected: false,
                };

                console.log(rowdata);

                if (rowdata.SpotRate == 0) {
                    rowdata.Rate10Sec = isRate10SecEmpty ? 0 : parseFloat(item[5]).toFixed(2);
                    if (rowdata.Rate10Sec > 0) rowdata.IsRate10SecSelected = true;

                    if (commercialDuration > 0) rowdata.SpotRate = parseFloat((rowdata.Rate10Sec / 10) * (commercialDuration / 1000)).toFixed(2);
                }
                else {
                    if (commercialDuration > 0) rowdata.Rate10Sec = parseFloat((rowdata.SpotRate / (commercialDuration / 1000)) * 10).toFixed(2);
                }


                days.forEach((date, index) => {
                    if (item[index + 14]) {
                        rowdata[date] = item[index + 14]; // 14 is start index of spots in excel
                    }
                    else {
                        rowdata[date] = 0;
                    }
                })
                lineNo++;
                setMaxBookingLineNo(lineNo);
                bookingsToSave.push(rowdata);

            }
        }

        setBookingCollection((old) => {
            old = [...old, ...bookingsToSave]
            UpdateFooterValues(old);
            return [...old]
        });

        // var dates = res.data[3].slice(13)
    }

    const UpdateFooterValues = (bookingData) => {

        let totalAmount = bookingData.reduce(function (prev, current) {
            return prev + +current.TotalAmount
        }, 0);

        let totalSpots = bookingData.reduce(function (prev, current) {
            return prev + +current.TotalSpot
        }, 0);


        let totalSeconds = bookingData.reduce(function (prev, current) {
            return prev + + current.TotalFCT
        }, 0);

        console.log(totalAmount);
        console.log(totalSpots);
        console.log(totalSeconds);

        setCurrentAmount(totalAmount);
        setCurrentSpots(totalSpots);
        setCurrentSeconds(totalSeconds);

    }

    const onSelection = (selectedState) => {
        let selectedDataList = [];
        //loop for setting the selected obj in array
        bookingCollection.forEach((obj) => {
            let key = obj.LineNumber;
            //if true means checked
            if (selectedState[key]) {
                selectedDataList.push(obj);
            }
        });

        setSelectedBookings(selectedDataList);
    }

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);
            onSelection(newSelectedState);

        }
    );

    const onHeaderSelectionChange = useCallback((event) => {

        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;

        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            // console.log(item);
            newSelectedState[idGetter(item)] = checked;

        });
        setSelectedState(newSelectedState);

        onSelection(newSelectedState);

    });

    // TO CHECK IF THERE ARE BLANK OR 0 SPOTS DETAILS ARE THERE THEN THAT ROW SHOULD NOT BE IMPORTED

    const GetChannel = (channelName) => {

        if (channelName == undefined || channelName.length == 0) return null;

        let channel = channels.find(x => x.FullChannelName.trim().toLowerCase() == channelName.trim().toLowerCase());
        return channel;
    }

    const GetCampaignType = (_campaignType) => {

        if (_campaignType == undefined || _campaignType.length == 0) return null;

        let campaigntype = campaignType.find(x => x.Description.trim().toLowerCase() == _campaignType.trim().toLowerCase());
        return campaigntype;
    }

    const GetPosition = (_position) => {

        if (_position == undefined || _position.length == 0) return null;

        let postion = positions.find(x => x.Description.trim().toLowerCase() == _position.trim().toLowerCase());
        return postion;
    }

    const GetCommercial = (assetId) => {

        if (assetId == undefined || assetId.length == 0) return null;

        let commercial = selectedCampaign.Commercials.find(x => x.AssetId.trim().toLowerCase() == assetId.trim().toLowerCase());
        return commercial;
    }


    const DownloadBookingTemplate = () => {
        try {
            let filePath = FILEURL.BASEURL + 'downloadTemplate/' + TEMPLATENAME.BookingImportTemplate;
            console.log(filePath);
            window.open(filePath);
            setShow(false);

        } catch (error) {
            toast.error(`${lang.error_downloading_template_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setShow(false);

        }

    }

    //here line number
    const onDeleteBooking = async () => {

        if (selectedBookings.length == 0) {
            toast.error(`${lang.please_select_a_value_to_delete_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let lineNo = await GetFreshMaxLineNo();

        selectedBookings.forEach(booking => {
            let index = bookingCollection.findIndex((obj) => obj.LineNumber !== booking.LineNumber);
            bookingCollection.splice(index, 1);
        });

        // UPDATING LINENO

        bookingCollection.forEach(booking => {
            console.log(booking);
            lineNo = lineNo + 1;
            booking.LineNumber = lineNo;
            console.log(lineNo);
            setMaxBookingLineNo(lineNo + 1);
        });


        //console.log(bookingCollection);
        // FILTERING TO SHOW THE CHANGES ON UI AS IT WAS NOT UPDATING UNTIL ANY SELECTION MADE 
        setBookingCollection((currentItem) => currentItem.filter((val) => val === val));

        // TO REMOVE THE SELECTION 

        let totalCurrentAmount = 0;
        let totalCurrentSpots = 0;
        let totalCurrentSeconds = 0;

        if (bookingCollection.length > 0) {
            bookingCollection.forEach((item) => {
                totalCurrentAmount = totalCurrentAmount + item.TotalAmount;
                totalCurrentSpots = totalCurrentSpots + item.TotalSpot;
                totalCurrentSeconds = totalCurrentSeconds + item.TotalFCT;
            })
        }

        setCurrentSpotsAmountSeconds(totalCurrentAmount, totalCurrentSpots, totalCurrentSeconds);
        setSelectedState({});
        setSelectedBookings([]);
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });

    };

    const onUpdateBooking = () => {
        // UPDATING CHANNEL
        if (selectedBookings.length == 0) {
            toast.error(`${lang.please_select_a_value_to_update_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if(Object.keys(dataItem.Channel).length == 0 && Object.keys(dataItem.Commercial).length == 0 && Object.keys(dataItem.CampaignType).length == 0 && Object.keys(dataItem.DealType).length == 0 && Object.keys(dataItem.Position).length == 0){
            toast.error(`${lang.please_select_a_value_to_update_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let updatedArray = [];

        if (dataItem.Channel || dataItem.Commercial || dataItem.CampaignType || dataItem.DealType || dataItem.Position) {

            selectedBookings.forEach((booking) => {
                console.log(booking);
                const dur = dataItem.Commercial && dataItem.Commercial.Duration ? dataItem.Commercial.Duration : booking.Duration;
                let updated = {
                    ...booking,
                    Channel: dataItem.Channel.SID ? dataItem.Channel : booking.Channel,
                    Commercial: dataItem.Commercial.SID ? dataItem.Commercial : booking.Commercial,
                    HouseNumber: dataItem.Commercial.AssetId ? dataItem.Commercial.AssetId : booking.HouseNumber,
                    Duration: dur,
                    TotalFCT: dataItem.Commercial && dataItem.Commercial.Duration ? (dataItem.Commercial.Duration / 1000) * booking.TotalSpot : booking.TotalFCT,
                    CampaignType: dataItem.CampaignType.SID ? dataItem.CampaignType : booking.CampaignType,
                    DealType: dataItem.DealType.SID ? dataItem.DealType : booking.DealType,
                    Position: dataItem.Position.SID ? dataItem.Position : booking.Position,
                };

                // if commercial is selected for update then only rate will change
                if (dataItem.Commercial.SID) {
                    updated.SpotRate = booking.IsRate10SecSelected ? parseFloat((booking.Rate10Sec / 10) * (dur / 1000)).toFixed(2) : booking.SpotRate;
                    updated.Rate10Sec = booking.IsRate10SecSelected ? booking.Rate10Sec : parseFloat((booking.SpotRate / (dur / 1000)) * 10).toFixed(2);
                }

                updatedArray.push(updated);
            });
            // https://stackoverflow.com/questions/37585309/replacing-objects-in-array

            //UPDATING WITH UPDATED ARRAY TO SHOW ON UI
            setBookingCollection(old => {
                old = old.map(obj => updatedArray.find(o => o.LineNumber === obj.LineNumber) || obj);
                UpdateFooterValues(old);
                return [...old];
            });
            setSelectedBookings([]);
            setSelectedState({});
            setDataItem(blankDataItem);
            toast.success(`${lang.update_successfully_booking_success_toast_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });

        } else {
            toast.error(`${lang.please_select_a_value_to_update_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    function validateSpotsBudgetSeconds() {

        // CHECKING IF CHANNELS ARE BLANK
        let emptlyChannels = bookingCollection.filter((val) => {
            return val.Channel == undefined;
        });

        if (emptlyChannels.length > 0) {
            let lineNo = "";
            emptlyChannels.forEach(element => {
                lineNo = lineNo + element.LineNumber + " , ";

            });
            toast.error(`${lang.channels_not_updated_in_booking_error_message} - ` + lineNo, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }


        let invalidBookingSpots = getBookingsWithNegativeSpotsValue();
        if (invalidBookingSpots.length > 0) {
            let lineNo = "";
            invalidBookingSpots.forEach(booking => {
                lineNo = lineNo + booking.LineNumber + " , ";

            });
            toast.error(`${lang.invalid_spots_value_line_no_booking_error_message} : ` + lineNo, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;

        }

        let totalAmount = bookedAmount + currentAmount;
        let totalSpots = bookedSpots + currentSpots;
        let totalSeconds = bookedSeconds + currentSeconds;

        if (totalSpots > parseInt(selectedCampaign.TargetSpots)) {
            toast.error(`${lang.exceding_target_spots_limit_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (totalAmount > parseInt(selectedCampaign.TargetBudget)) {
            toast.error(`${lang.exceding_target_budget_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (totalSeconds > parseInt(selectedCampaign.TargetSeconds)) {
            toast.error(`${lang.exceding_target_seconds_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        // CHECKING IF COMMERCIALS ARE BLANK
        let emptlyCommercials = bookingCollection.filter((val) => {
            return val.Commercial == undefined;
        });

        if (emptlyCommercials.length > 0) {
            let lineNo = "";
            emptlyCommercials.forEach(element => {
                lineNo = lineNo + element.LineNumber + " , ";

            });
            toast.error(`${lang.commercials_not_updated_in_line_no_booking_error_message} - ` + lineNo, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }


        //VALIDATING BOOKING DATE AND CAMPAIGN DATE
        let isSpotsValidInDateRange = utility.checkSpotsInCampaignDateRange(bookingCollection, days, selectedCampaign);
        if (!isSpotsValidInDateRange) {
            return false;
        }
        return true;

    }

    const getBookingsWithNegativeSpotsValue = () => {
        let bookingsWithInvalidSpot = [];
        bookingCollection.forEach(booking => {
            // to prevent adding current booking object multitimes as break statement was not working in 
            let currenBookingIsAdded = false;
            days.forEach(day => {
                if (!currenBookingIsAdded && booking[day] < 0) {
                    //console.log(booking.LineNumber + " has invalid spots on " + day );
                    bookingsWithInvalidSpot.push(booking);
                    currenBookingIsAdded = true;
                }

            });
        });

        return bookingsWithInvalidSpot;
    }

    Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });

        if (e.target.name == "Month" || e.target.name == "Year") {

            if (dataItem.Year == undefined) {
                //need to check
                console.log("in");
            }

            let days = [];
            let year = e.target.name == "Year" ? e.target.value : dataItem.Year;
            let monthNo = e.target.name == "Month" ? month.indexOf(e.target.value) + 1 : month.indexOf(dataItem.Month) + 1;
            let daysInMonth = new Date(year, monthNo, 0).getDate(); //daysInMonth to show  

            for (let i = 0; i < daysInMonth; i++) {
                let firstDate = new Date(year, monthNo - 1, i + 1);
                let day = daysOfWeek[firstDate.getDay()];
                let date = moment(firstDate).format("YYYY-MM-DD");
                days.push(`${date} ${day}`);
            }

            setDays(days);

        }

    };

    const onSelectCampaign = () => {
        setYears([]);
        setOpenCampaignSelection(true)
    }

    const onEditSelectedCampaign = () => {

        if (selectedCampaign.CampaignNo == '') {
            toast.warn(`${lang.please_select_campaign_first_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            setShowCampaignEditForm(true);
        }
    }

    const onSaveSelectedCampaign = (updatedData) => {
        console.log(updatedData);
        setSelectedCampaign(updatedData);
    }

    let tempStore = useRef([]);

    const onSetSelectedCampaign = async (dataList) => {

        const selectedCampaign = dataList[0];



        setSelectedCampaign(selectedCampaign);
        tempStore.current = [...tempStore.current, selectedCampaign.CampaignNo]
        if ((tempStore.current[tempStore.current.length - 2 == -1 ? 0 : tempStore.current.length - 2]) != selectedCampaign.CampaignNo) {
            setBookingCollection([]);
        }
        setChannels(selectedCampaign.Channels)

        const monthsYears = await getValidMonthsAndYears(selectedCampaign);
        setMonths(monthsYears.months);
        setYears(monthsYears.years);

        CreateDatesFromCampain();

        // UpdateEditItem null on selected campaign
        setDataItem({ ...dataItem, Year: monthsYears.years[0], Month: monthsYears.months[0], Channel: {}, Commercial: {}, CampaignType: {}, DealType: {}, Position: {} });

        let existingBookings = await API.getCampaignsExistingBookingDetails(selectedCampaign._id);

        if (existingBookings.success) {

            setBookedAmount(existingBookings.data.BookedAmount);
            setBookedSpots(existingBookings.data.BookedSpots);
            setBookedSeconds(existingBookings.data.BookedSeconds);
            setMaxBookingLineNo(existingBookings.data.MaxLineNo + 1);
            setInitialMaxBookingLineNo(existingBookings.data.MaxLineNo + 1);
        }
    }

    async function getValidMonthsAndYears(campaign) {

        let yearTemp = [];
        let monthTemp = [];

        let tempDate = new Date(campaign.PeriodFrom);
        let periodTo = new Date(campaign.PeriodTo);

        while (tempDate <= periodTo) {

            //for year insert
            if (!yearTemp.includes(tempDate.getFullYear())) {
                yearTemp.push(tempDate.getFullYear());
            }

            //for month insert
            if (!monthTemp.includes(month[tempDate.getMonth()])) {
                monthTemp.push(month[tempDate.getMonth()]);
            }

            tempDate = tempDate.addDays(28);

            if (tempDate > periodTo) {
                if (!yearTemp.includes(periodTo.getFullYear())) {
                    yearTemp.push(periodTo.getFullYear());
                }
                if (!monthTemp.includes(month[periodTo.getMonth()])) {
                    monthTemp.push(month[periodTo.getMonth()]);
                }
            }



            // setting firstyear and month flrmo list as default
            if (yearTemp.length > 0) dataItem.Year = yearTemp[0];
            if (monthTemp.length > 0) dataItem.Month = monthTemp[0];
        }

        return { years: yearTemp, months: monthTemp };

    }

    async function GetFreshMaxLineNo() {

        let maxLineNo = 0;
        let res = await API.getCampaignMaxLineNo(selectedCampaign._id);

        if (res.success && res.data) {
            maxLineNo = res.data;
        }
        setInitialMaxBookingLineNo(maxLineNo + 1);
        return maxLineNo;
    }

    async function SaveBookingData() {

        if (bookingCollection.length > 0) {

            //CHECK IF LINE IS IN EDIT MODE
            let editModeData = bookingCollection.filter((x) => x.inEdit);
            if (editModeData.length > 0) {
                toast.warning(`Either cancel or save your changes for line number ${editModeData[0].LineNumber} first !!`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            //CHECK IF LINE HAS SPOTS
            if (!utility.checkLinesWithZeroSpots(bookingCollection, days)) return;

            console.log(bookingCollection);
            console.log(days);

            //Check Credit limit
            let checkCreditLimit = await API.isCreditAvaliable(selectedCampaign, currentAmount);
            console.log(checkCreditLimit);
            if (!checkCreditLimit.success) {
                toast.error(checkCreditLimit.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            console.log("Saving Booking Here");

            let bookingRes = await API.saveBooking(bookingCollection, days, selectedCampaign.CampaignNo, { currentAmount: currentAmount, currentSeconds: currentSeconds, currentSpots: currentSpots });
            console.log(bookingRes)
            console.log("Saving Booking DONE");
            if (bookingRes.success) {
                if(bookingCollection.length > 0){
                    bookingCollection.forEach((booking) => {
                        let logCapture = { event: LOGEVENT.SAVE_BOOKING, module: MODULE.BOOKING, data: booking, message: "Save Booking", _id:  booking.Campaign._id + '_' + booking.LineNumber };
                        API.SaveLogs(logCapture);
                    })
                }
                setBookingCollection([]);
                setBookedAmount((old) => old + currentAmount);
                setBookedSeconds((old) => old + currentSeconds);
                setBookedSpots((old) => old + currentSpots);
                setCurrentSpotsAmountSeconds(0, 0, 0);

                setMaxBookingLineNo(parseInt(bookingCollection[bookingCollection.length - 1].LineNumber) + 1);
                setInitialMaxBookingLineNo(parseInt(bookingCollection[bookingCollection.length - 1].LineNumber) + 1);

                toast.success(`${lang.booking_saved_booking_error_message}`, {
                    position: toast.POSITION.TOP_RIGHT
                });

                bookingSocket.onAddBookings(bookingCollection, days);
            } else {
                toast.error(bookingRes.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        } else {
            toast.error(`${lang.no_data_found_booking_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const onSaveBooking = async () => {


        if (validateSpotsBudgetSeconds()) {
            SaveBookingData();
            setSaved(true);
        };


    };

    //copy of bookingCollection for updating current details in footer

    const handleBookingCollection = (addBookingItems) => {
        setIsBlocking(true);

        let bookingLocal = [...bookingCollection];
        bookingLocal = [...bookingLocal, ...addBookingItems]
        setBookingCollection(bookingLocal);

        console.log(addBookingItems);
        //last item line + 1
        setMaxBookingLineNo(parseInt(addBookingItems[addBookingItems.length - 1].LineNumber) + 1);

        UpdateFooterValues(bookingLocal)
        setSaved(false);
    }

    //get value of current calculated spots,amount and seconds
    const setCurrentSpotsAmountSeconds = (amount, spots, seconds) => {
        setCurrentAmount(amount);
        setCurrentSpots(spots);
        setCurrentSeconds(seconds);
    }

    const onRotateBooking = async () => {

        if (selectedBookings.length == 0) {
            toast.error(`Please select booking line first`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let tempBookingCollectionData = [...bookingCollection];

        selectedBookings.forEach((line, i) => {

            let TotalSpotsActualLine = 0;
            let TotalSpotsNewLine = 0

            let actualLine = { ...line };
            let newLine = {
                ...line,
                LineNumber: maxBookingLineNo + i
            };
            setMaxBookingLineNo(maxBookingLineNo + i + 1)

            days.forEach((day, index) => {

                //CHECK ODD EVEN
                if (line[day] % 2 == 0) {
                    actualLine[day] = line[day] / 2
                    TotalSpotsActualLine = TotalSpotsActualLine + line[day] / 2
                    newLine[day] = line[day] / 2
                    TotalSpotsNewLine = TotalSpotsNewLine + line[day] / 2

                } else {
                    if (index % 2 == 0) {
                        actualLine[day] = Math.round(line[day] / 2)
                        TotalSpotsActualLine = TotalSpotsActualLine + Math.round(line[day] / 2)
                        newLine[day] = Math.trunc(line[day] / 2)
                        TotalSpotsNewLine = TotalSpotsNewLine + Math.trunc(line[day] / 2)

                    } else {
                        actualLine[day] = Math.trunc(line[day] / 2)
                        TotalSpotsActualLine = TotalSpotsActualLine + Math.trunc(line[day] / 2)
                        newLine[day] = Math.round(line[day] / 2)
                        TotalSpotsNewLine = TotalSpotsNewLine + Math.round(line[day] / 2)

                    }
                }

            })

            //Total Spots
            actualLine["TotalSpot"] = TotalSpotsActualLine;
            //Total Amount
            actualLine["TotalAmount"] = actualLine.SpotRate * TotalSpotsActualLine;
            // Total FCT
            actualLine["TotalFCT"] = TotalSpotsActualLine * actualLine.Duration / 1000;

            //Total Spots
            newLine["TotalSpot"] = TotalSpotsNewLine;
            //Total Amount
            newLine["TotalAmount"] = newLine.SpotRate * TotalSpotsNewLine;
            // Total FCT
            newLine["TotalFCT"] = TotalSpotsNewLine * newLine.Duration / 1000;

            let index = tempBookingCollectionData.findIndex((x) => x.LineNumber == line.LineNumber);
            tempBookingCollectionData[index] = actualLine;
            tempBookingCollectionData = [...tempBookingCollectionData, newLine];

        })

        setBookingCollection(tempBookingCollectionData);
        setSelectedBookings([]);
        setSelectedState({});


    }

    const campaignDetailHeaderStyle = { fontSize: "12px", fontWeight: "bold" };
    const campaignDetailValueStyle = { color: "blue", fontSize: "15px", padding: 0 };
    const MyTimeCell = (props) => <TimeCell {...props} style={{ textAlign: 'center' }} />;

    // Functions to modify grid data
    const onInLineRemoveBooking = async (dataItem) => {
        let lineNo = await GetFreshMaxLineNo();
        let filteredGridData = bookingCollection.filter((obj) => obj.LineNumber != dataItem.LineNumber)
        let bookingData = filteredGridData.map((booking, index) => {
            return { ...booking, LineNumber: lineNo + index + 1 }
        });

        UpdateFooterValues(bookingData);
        setBookingCollection(bookingData);
        setSelectedBookings([]);
        setSelectedState({});
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });

        setMaxBookingLineNo(parseInt(bookingData[bookingData.length - 1].LineNumber) + 1);

    };

    const onInLineUpdateBooking = (dataItem) => {

        //CHECK IF SPOT THAT ARE FILLED ARE NOT IN CAMPAIGN DATE RANGE
        if (!utility.checkSpotsInCampaignDateRange([dataItem], days, selectedCampaign)) return;

        dataItem.inEdit = false;
        let index = bookingCollection.findIndex((record) => record.LineNumber === dataItem.LineNumber);

        let updatedTotalSpots = 0;
        let uniqDaysOfLine = [];

        days.forEach((day) => {
            let spot = 0;
            if (dataItem[day] != undefined || dataItem[day] == null) {
                spot = dataItem[day] == null || dataItem[day] < 0 ? 0 : dataItem[day];
                dataItem[day] = spot;
                let tempDay = day.split(' ')[1]
                if (spot > 0 && !uniqDaysOfLine.includes(tempDay)) {
                    uniqDaysOfLine.push(tempDay)
                }
            }
            updatedTotalSpots = updatedTotalSpots + parseInt(spot);
        })

        //updating total spot, fct and amount
        dataItem.TotalSpot = updatedTotalSpots;
        dataItem.TotalAmount = parseInt(dataItem.SpotRate) * updatedTotalSpots
        dataItem.TotalFCT = updatedTotalSpots * dataItem.Duration / 1000
        dataItem.DaysOfWeek = uniqDaysOfLine.map((x) => x.substring(0, 1)).join('/')


        bookingCollection[index] = dataItem
        setBookingCollection(bookingCollection);
        UpdateFooterValues(bookingCollection);
        setGridDataCopy([]);
        inEditOff(dataItem);

    };

    const inEditOff = (dataItem) => {
        const originalItem = bookingCollection.find(
            (p) => p.LineNumber === dataItem.LineNumber
        );
        const newData = bookingCollection.map((item) =>
            item.LineNumber === originalItem.LineNumber ? originalItem : item
        );
        setBookingCollection(newData);
    };

    const onInLineEditBooking = (dataItem) => {

        let gridData = [...bookingCollection];

        if (gridDataCopy.length > 0) {
            gridData = [...gridDataCopy];
        }

        setEditItemSwallowCopy(() => dataItem);
        let newData = gridData.map((item) =>
            item.LineNumber === dataItem.LineNumber
                ? {
                    ...item, inEdit: true,
                }
                : {
                    ...item, inEdit: false,
                }
        );
        setBookingCollection(newData);
        setGridDataCopy(newData);
    };

    const onInLineCancelBooking = (dataItem) => {
        dataItem.inEdit = false;
        let index = bookingCollection.findIndex((record) => record.LineNumber === dataItem.LineNumber);
        bookingCollection[index] = editItemSwallowCopy;
        inEditOff(dataItem);
    }

    const UpdateBookingCommandCell = (props) => (
        <UpdateBookingLineNumberCommandCell
            {...props}
            onEdit={onInLineEditBooking}
            onRemove={() => ConfirmDeleteAlert(() => onInLineRemoveBooking(props.dataItem), () => { })}
            onUpdate={onInLineUpdateBooking}
            onCancel={onInLineCancelBooking}
            editField={editField}
        />
    )

    const itemChange = (event) => {

        let field = event.field || "";

        const newData = bookingCollection.map((item) =>
            item.LineNumber === event.dataItem.LineNumber ? {
                ...item, [field]: event.value,
            }
                : item);
        setBookingCollection(newData);
    };

    const ExportSavedBooking = async (isSavedBookingExport) => {
        try {

            if (Object.keys(selectedCampaign).length <= 1) {
                toast.warn(lang.please_select_campaign_first_booking_error_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            if (bookingCollection.length == 0 && !isSavedBookingExport) {
                toast.warn(lang.please_add_booking_first_booking_error_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            let bookingData = {};
            bookingData.IsSavedBookingExport = isSavedBookingExport;
            bookingData.Bookings = bookingCollection;
            bookingData.Days = days;
            bookingData.Campaign = selectedCampaign;
            // console.log(bookingCollection);
            let res = await API.exportBooking(bookingData, [dataItem.Month], [dataItem.Year]);
            if (res.success) {
                let filePath = FILEURL.BASEURL + 'downloadReport/' + res.fileName;
                window.open(filePath);
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }


        } catch (error) {
            toast.error(lang.error_downloading_template_booking_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });

        }

    }

    const onClick = () => {
        setShow(!show);
    };

    return (<>

        <h1 className="page-title txt-color-bludeDark">
            <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }}></i>
            <span>{lang.booking_label}</span>
        </h1>

        <div className='row'>
            <div className='col-7' style={{ paddingRight: '20px' }}>
                <div style={{ display: 'flex' }}>
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.campaign_label} *</label>
                        <input name="Title" type="text" className="form-control form-control-sm" value={selectedCampaign.CampaignNo} onChange={() => ""} disabled={true} />
                    </div>
                    <RoundButton icon="ellipsis-h" btnColor="success" style={{ color: "white", marginTop: "25px",marginLeft: "5px", height: "30px", }} title={lang.select_button_text} name={"..."} onClick={onSelectCampaign} />
                   {selectedCampaign.CampaignNo != "" && <> 
                    <RoundButton icon="pencil" style={{ marginLeft: "5px", marginTop: "25px", height: "30px" }} title={lang.edit_campaign_collection_text} name={lang.edit_label} onClick={onEditSelectedCampaign} /> 
                    <div style={{ margin: '0px 5px 0px 5px' }} className="form-group">
                        <label htmlFor="TabView">{lang.year_label}</label><br />
                        <DropDownList
                            data={years}
                            name="Year"
                            value={dataItem.Year}
                            onChange={onChange}
                            style={{ width: "120px" }}
                            validator={(value) => value ? "" : "Please select value"}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.months_label}</label><br />
                        <DropDownList
                            data={months}
                            name="Month"
                            style={{ width: "120px" }}
                            value={dataItem.Month}
                            onChange={onChange}
                            validator={(value) => value ? "" : "Please select value"}
                        />
                    </div>

                    </>}

                </div>
                {openCampaignSelection && <CollectionSelection addButtonTitle={lang.select_button_text} title={lang.booking_collection_header} customColumns={CampaignColumns} entityname={ENTITYNAME.Campaign} closeForm={() => setOpenCampaignSelection(!openCampaignSelection)} wherestatement={[['Archive', '=', false],['IsDraft', '!=', true]]} setDataList={(dataList) => onSetSelectedCampaign(dataList)} mode={'single'} width={"50vw"} height={"64vh"} />}

            </div>
            <div className='col-5 '>
                {selectedCampaign.CampaignNo != "" &&
                    <div style={{ display: "flex" }} className='float-right'>

                        <div className="form-group marlr">
                            <label htmlFor="TabView" style={campaignDetailHeaderStyle}>{lang.start_date_label}</label><br />
                            <p style={campaignDetailValueStyle}>{moment(selectedCampaign.PeriodFrom).format("DD-MM-YYYY")}</p>
                        </div>

                        <div className="form-group marlr">
                            <label htmlFor="TabView" style={campaignDetailHeaderStyle}>{lang.end_Date_label}</label><br />
                            <p style={campaignDetailValueStyle}>{moment(selectedCampaign.PeriodTo).format("DD-MM-YYYY")}</p>
                        </div>

                        <div className="form-group marlr">
                            <label htmlFor="TabView" style={campaignDetailHeaderStyle}>{lang.total_spot_label}:</label><br />
                            <p style={campaignDetailValueStyle}>{selectedCampaign.TargetSpots}</p>
                        </div>

                        <div className="form-group marlr">
                            <label htmlFor="TabView" style={campaignDetailHeaderStyle}>{lang.total_seconds_label}</label><br />
                            <p style={campaignDetailValueStyle}>{selectedCampaign.TargetSeconds}</p>
                        </div>

                        <div className="form-group marlr">
                            <label htmlFor="TabView" style={campaignDetailHeaderStyle}>{lang.total_budget_label}</label><br />
                            <p style={campaignDetailValueStyle}>{selectedCampaign.TargetBudget}</p>
                        </div>

                    </div>
                }
            </div>
        </div>

        {/* Update Booking Selection Section */}
        <div className='row'>
            <div className='col-md'>
                {selectedBookings.length > 0 && <div style={{ display: 'flex' }}>
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.channel_label}</label><br/>
                        <DropDownList
                            style={{width: "100px"}}
                            data={channels}
                            name="Channel"
                            textField="FullChannelName"
                            // dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                    <div className="form-group ml-3">
                        <label htmlFor="TabView">{lang.commercial_label}</label><br/>
                        <DropDownList
                            style={{width: "150px"}}
                            data={selectedCampaign.Commercials}
                            name="Commercial"
                            textField="Title"
                            // dataItemKey="_id"
                            value={dataItem.Commercial}
                            onChange={onChange}
                        />
                    </div>
                    <div className="form-group ml-3">
                        <label htmlFor="TabView">{lang.campaign_type_label}</label><br/>
                        <DropDownList
                            style={{width: "100px"}}
                            data={campaignType}
                            name="CampaignType"
                            textField="Description"
                            // dataItemKey="_id"
                            value={dataItem.CampaignType}
                            onChange={onChange}
                        />
                    </div>
                    <div className="form-group ml-3">
                        <label htmlFor="TabView">{lang.deal_type_label}</label><br/>
                        <DropDownList
                            style={{width: "100px"}}
                            data={dealType}
                            name="DealType"
                            textField="Description"
                            // dataItemKey="_id"
                            value={dataItem.DealType}
                            onChange={onChange}
                        />
                    </div>
                    <div className="form-group ml-3">
                        <label htmlFor="TabView">{lang.position_label}</label><br/>
                        <DropDownList
                            style={{width: "100px"}}
                            data={positions}
                            name="Position"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.Position}
                            onChange={onChange}
                        />
                    </div>
                    <div style={{marginTop: '22px'}}>
                        <ActionButton title={lang.update_button_tooltip} btnColor="success" style={{ color: "white", borderRadius: "5px" }}  name={lang.update_button_text} onClick={onUpdateBooking} />
                        <ActionButton title={lang.delete_button_tooltip} btnColor="danger" style={{ color: "white", borderRadius: "5px" }} name={lang.delete_button_text} onClick={() => ConfirmDeleteAlert(() => onDeleteBooking(), () => { })} />
                    </div>

                </div>}
            </div>

            <div className='col-md-3' style={{maxWidth: "22%"}}>

                <div className='mt-4 pull-right form-group'>
                    <button type="button" title={lang.add_button_tooltip} onClick={() => {

                        if (selectedCampaign.CampaignNo == "") {
                            toast.warn(`${lang.please_select_campaign_first_booking_error_message}`, {
                                position: toast.POSITION.TOP_RIGHT
                            });
                            return;
                        }
                        else if (days.length == 0) {
                            return;
                        }
                        else setOpenBookingForm(true);


                    }} className={`btn btn-default bg-primary btn-circle btn-my ml-2`} >
                        <i className={`fa fa-plus fa-fw img-circle`} style={{ color: "white" }} ></i>
                    </button>
                    <button type="button" title={lang.save_button_tooltip} onClick={onSaveBooking} className={`btn btn-default bg-primary btn-circle btn-my ml-2`} >
                        <i className={`fa fa-save fa-fw img-circle`} style={{ color: "white" }} ></i>
                    </button>
                    <button type="button" title={'Rotate Booking Line'} onClick={onRotateBooking} className={`btn btn-default bg-secondary btn-circle btn-my ml-2`} >
                        <i className={`fa fa-shuffle fa-fw img-circle`} style={{ color: "white" }} ></i>
                    </button>


                    <button ref={anchor} type="button" title={lang.import_button_tooltip} onClick={onClick} className={`btn btn-default bg-info btn-circle btn-my ml-2`} >
                        <i className={`fa fa-ellipsis-v img-circle`} style={{ color: "white" }} ></i>
                    </button>

                    <Popup show={show} anchor={anchor.current} popupClass={"popup-content"} style={{ width: '13vw', marginTop: '14px' }}>
                        <div className='cursor-pointer ml-2 mt-3' onClick={() => { ExportSavedBooking(false); setShow(false) }}>
                            <i className={`fa fa-file-excel fa-fw img-circle`} style={{ color: 'green' }} ></i>
                            {lang.export_current_booking_label}
                        </div><hr />
                        <div className='cursor-pointer ml-2' onClick={() => { ExportSavedBooking(true); setShow(false) }}>
                            <i className={`fa fa-file-excel fa-fw img-circle`} style={{ color: 'teal' }}></i>
                            {lang.export_saved_booking_label}
                        </div><hr />
                        <div className='cursor-pointer ml-2' onClick={DownloadBookingTemplate}>
                            <i className={`fa fa-download fa-fw img-circle`} ></i>
                            {lang.download_template_label}
                        </div><hr />
                        <label className='cursor-pointer ml-2'>
                            <input ref={fileRef} type="file" accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' value={''} name={lang.file_tooltip} className="custom-file-updated-input" hidden="hidden" id="fileupload" onChange={handleFileChange} />
                            <i className={`fa fa-file-excel-o fa-fw img-circle`} ></i>
                            {lang.import_booking_label}
                        </label><hr />
                        <label className='cursor-pointer ml-2 mb-2'>
                            <input ref={fileRef} type="file" accept="application/pdf" name={lang.file_tooltip} value={''} className="custom-file-updated-input" hidden="hidden" id="fileupload" onChange={handleImportPdf} />
                            <i className={`fa fa-file-pdf-o fa-fw img-circle`} > </i>
                            {lang.import_pdf_booking_file}
                        </label>
                    </Popup>

                </div>
            </div>
        </div>

        {/* grid */}
        <div className='row'>
            <div className='col-lg-12 col-md-12'>
                <Grid data={bookingCollection.map((item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                    resizable={true}
                    onSelectionChange={onSelectionChange}
                    onItemChange={itemChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                    style={{ height: "55vh" }}>
                    <GridColumn
                        field={SELECTED_FIELD}
                        width="50px"
                        headerSelectionValue={
                            bookingCollection.findIndex((item) => !selectedState[idGetter(item)]) === -1
                        }
                        locked={true}
                    />
                    <GridColumn cell={UpdateBookingCommandCell} width="100px" locked={true} />
                    <GridColumn field="LineNumber" title={lang.line_no_column} editable={false} width={"80px"} cell={WrappedTextCell} />
                    <GridColumn field="Channel.FullChannelName" title={lang.channel_column} editable={false} width={"150px"} cell={WrappedTextCell} />
                    <GridColumn field="Commercial.Title" title={lang.commercial_column} editable={false} width={"200px"} cell={WrappedTextCell} />
                    <GridColumn field="Program.Title" title={lang.program_column} editable={false} width={"150px"} cell={WrappedTextCell} />
                    <GridColumn field="HouseNumber" title={lang.house_number_column} editable={false} width={"150px"} cell={WrappedTextCell} />
                    <GridColumn field="Duration" title={lang.duration_column} editable={false} width={"100px"} cell={MyTimeCell} />
                    <GridColumn field="CampaignType.Description" title={lang.type_column} editable={false} width={"80px"} cell={WrappedTextCell} />
                    <GridColumn field="DealType.Description" title={lang.deal_type_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="Rate10Sec" title={lang.rate10sec_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="SpotRate" title={lang.spot_rate_column} editable={false} width={"100px"} cell={MySpotCell} />
                    <GridColumn field="DaysOfWeek" title={lang.days_of_week_column} editable={false} width={"100px"} />
                    <GridColumn field="Position.Description" title={lang.position_column} editable={false} width={"100px"} cell={WrappedTextCell} />

                    <GridColumn field="FromTime" title={lang.from_time_column} editable={true} width={"80px"} cell={MyTimeRangeEditCell} />
                    <GridColumn field="ToTime" title={lang.to_time_column} editable={true} width={"80px"} cell={MyTimeRangeEditCell} />
                    <GridColumn field="ProductCode" title={lang.product_code_column} editable={true} width={"100px"} cell={MyEditCell} />

                    <GridColumn field="TotalSpot" title={lang.total_spot_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="TotalFCT" title={lang.total_fct_column} editable={false} width={"100px"} cell={WrappedTextCell} />
                    <GridColumn field="TotalAmount" title={lang.total_amount_column} editable={false} width={"100px"} cell={WrappedTextCell} />

                    {/* days of the month by looping */}
                    {days.map((day, index) => {
                        return (<GridColumn key={index} field={day} title={`${day}`} editable={true} width={"100px"} headerCell={DateDayCell} cell={MySpotCell} />)
                    })}
                </Grid>
            </div>
        </div>

        {/* footer */}
        <div className='row'>
            <div className='col-8' style={{ display: "flex" }}>
                <div style={{ marginLeft: "20px" }}><p className='mb-0'>{lang.booked_spots_lable}</p><p style={campaignDetailValueStyle}> {bookedSpots}</p></div>
                <div style={{ marginLeft: "20px" }}><p className='mb-0'>{lang.current_spots_label}</p><p style={campaignDetailValueStyle}> {currentSpots}</p></div>
                <div style={{ marginLeft: "20px" }}><p className='mb-0'>{lang.booked_seconds_label}</p><p style={campaignDetailValueStyle}> {bookedSeconds}</p></div>
                <div style={{ marginLeft: "20px" }}><p className='mb-0'>{lang.current_seconds_label}</p><p style={campaignDetailValueStyle}> {currentSeconds}</p></div>
                <div style={{ marginLeft: "20px" }}><p className='mb-0'>{lang.booked_amount_label}</p><p style={campaignDetailValueStyle}>  {bookedAmount}</p></div>
                <div style={{ marginLeft: "20px" }}><p className='mb-0'>{lang.current_amount_label}</p><p style={campaignDetailValueStyle}>  {currentAmount}</p></div>
            </div>
            <div className='col text-right'><p className='text-muted' style={{ fontSize: '11px' }}>{'* Select lines to update / delete multiple lines'}</p></div>
        </div>


        {openBookingForm && (
            <BookingEditForm
                {...props} //to get other props from customEditForm
                cancelEdit={() => setOpenBookingForm(false)}
                selectedCampaign={selectedCampaign}
                setCurrentSpotsAmountSeconds={setCurrentSpotsAmountSeconds}
                data={bookingCollection}
                setData={handleBookingCollection}
                campaignDays={days}
                bookingDataItem={dataItem}
                maxLineBookingNo={initialMaxBookingLineNo + bookingCollection.length}
            />
        )}

        {showCampaignEditForm && (
            <BossDialog title={selectedCampaign.CampaignNo} open={showCampaignEditForm} onClose={() => setShowCampaignEditForm(false)} width={'90%'} height={'90%'}>
                <CampaignEditForm entityname={ENTITYNAME.Campaign} fromBooking={true} data={selectedCampaign} onCloseEdit={() => setShowCampaignEditForm(false)} setCampaignData={onSaveSelectedCampaign} />
            </BossDialog>
        )
        }

        <ProgressBarPopup ModuleID={MODULE.BOOKING} />
    </>)
}

export default BookingCollection
