/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api"
import { ENTITYNAME } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import BossDialog from '../../components/BossDialog';

function InvoiceContentEditForm(props) {

    const [channel, setChannel] = useState([]);
    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Channel: props.item.Channel ?? {},
        Prefix: props.item.Prefix ?? "",
        NextNumber: props.item.NextNumber ?? 1,
        NumberLength: props.item.NumberLength ?? 5,
    }

    useEffect(() => {
        loadcombo();
    }, [])

    const [dataItem, setDataItem] = useState(blankDataItem);

    const loadcombo = async () => {
        let ChannelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { Name: 1 } });
        setChannel(ChannelRes.data);
    }

    const onChangeForm = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Channel == undefined || Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Prefix == undefined || dataItem.Prefix == "") {
            toast.error(`${lang.please_enter_prefix_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.NextNumber == undefined || dataItem.NextNumber == "") {
            toast.error(`${lang.please_enter_next_number_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
          if(dataItem.NextNumber <= 0){
            toast.error(`${lang.next_number_can_not_be_negative_or_zero_error_meessage}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
          if(!dataItem.NumberLength ||  dataItem.NumberLength <= 0 || dataItem.NumberLength > 10){
            toast.error(`${lang.number_length_should_be_between_1_to_10_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
          }
        return true;
    }


    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                _id: dataItem._id,
                SID: dataItem.SID,
                Name: dataItem.Name,
                Channel: {
                    _id: dataItem.Channel._id,
                    SID: dataItem.Channel.SID,
                    FullChannelName: dataItem.Channel.FullChannelName,
                },
                Prefix: dataItem.Prefix,
                NextNumber: dataItem.NextNumber,
                NumberLength: dataItem.NumberLength,
                Archive: dataItem.Archive ?? false,
                checkDuplicate: true,
                query: [["Prefix", "=", dataItem.Prefix]]

            }
            let res = await API.saveData(ENTITYNAME.InvoiceContent, saveData);
            if (res.success) {
                utility.deleteLocalStorageItem(ENTITYNAME.InvoiceContent);
                props.refresh();
                props.cancelEdit();
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }


    const cancelEdit = () => {
        props.cancelEdit();
    }

    return (
        <>
            <BossDialog
                title={props.item.SID > 0 ? props.item.Name : `${lang.create_invoice_content_dialog_header}`}
                onClose={cancelEdit}
                width={"740px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                <Form
                    onSubmit={handleSubmit}
                    initialValues={dataItem}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.name_label} *</label>
                                            <input
                                                name={"Name"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                onChange={onChangeForm}
                                                value={dataItem.Name}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label htmlFor="TabView">{lang.channel_label} *</label>
                                            <DropDownList
                                                style={{ backgroundColor: "white" }}
                                                data={channel}
                                                name="Channel"
                                                textField="FullChannelName"
                                                dataItemKey="_id"
                                                value={dataItem.Channel}
                                                onChange={onChangeForm}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.prefix_label} *</label>
                                            <input
                                                name={"Prefix"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                onChange={onChangeForm}
                                                value={dataItem.Prefix}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>{lang.next_number_label} *</label>
                                            <input
                                                name={"NextNumber"}
                                                type="number"
                                                className='form-control form-control-sm'
                                                onChange={onChangeForm}
                                                value={dataItem.NextNumber}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.number_length_label} *</label>
                                            <input
                                                name={"NumberLength"}
                                                type="number"
                                                className='form-control form-control-sm'
                                                onChange={onChangeForm}
                                                value={dataItem.NumberLength}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <Field
                                                name={"Archive"}
                                                component={Checkbox}
                                                label={lang.archive}
                                                onChange={onChangeForm}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </BossDialog>
        </>
    )
}
export default InvoiceContentEditForm;