import React, { useEffect, useState } from 'react';
import { getMyPlansWithAllPlans, getRazorpayKeyID, razorpayCreateOrder, updateTransactionHistory, verifyPayment } from '../../framework/API/api_digitalSign';
import { toast } from 'react-toastify';
import { utility } from '../../framework/utility/utilityProvider';
import { DIGITALSIGN_TRANSACTION_STATUS, ENTITYNAME, FILEURL, LOCALSTORAGE_KEY } from '../../framework/constant/constant';
import SaveButton from '../../framework/forms/helpercomponents/buttons/SaveButton';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import CancelButton from '../../framework/forms/helpercomponents/buttons/CancelButton';
import { getData } from '../../framework/API/api';
import moment from 'moment';
import * as API from '../../framework/API/api_digitalSign'
import { useTranslation } from '../../locale/useTranslation';
import BossDialog from '../../components/BossDialog';

const MyPlansPopup = ({ onClose }) => {

    const [loading, setLoading] = useState(false);
    const [myPlans, setMyPlans] = useState([]);
    const [allPlans, setAllPlans] = useState([]);
    const [userData, setUserData] = useState(null);
    const [transactionHistoryData, setTransactionHistoryData] = useState([]);
    const [showTransactionHistory, setShowTransactionHistory] = useState(false);
    const [showPurchasePlan, setShowPurchasePlan] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);

    const lang = useTranslation();

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);
        let userData = utility.getValue(LOCALSTORAGE_KEY.userData);
        setUserData(userData);
        let res = await getMyPlansWithAllPlans();
        if (!res.success) {
            toast.error(res.message);
        } else {
            setMyPlans(res.data?.myPlans ?? []);
            setAllPlans(res.data?.allPlans ?? []);
            setSelectedPlan(res.data?.allPlans[0] ?? null);
        }
        setLoading(false);
    }

    const showTransactionHistoryPopup = async () => {
        setShowTransactionHistory(true);
        let res = await getData(ENTITYNAME.DigitalSignTransactionHistory, { query: ["userID", "=", userData._id] });
        if (res.success) {
            setTransactionHistoryData(res.data);
        } else {
            setShowTransactionHistory(false);
            toast.error(res.message);
        }
    }

    const confirmPayment = async (response) => {
        console.log(response);
        let res = await verifyPayment(response.razorpay_order_id, response.razorpay_payment_id, response.razorpay_signature);
        console.log(res);
        if (!res.success) {
            await updateTransactionHistory(response.razorpay_order_id, DIGITALSIGN_TRANSACTION_STATUS.Cancelled);
            toast.error("Payment Failed");
        } else {
            let transactionRes = await updateTransactionHistory(response.razorpay_order_id, DIGITALSIGN_TRANSACTION_STATUS.Confirmed);
            if (!transactionRes.success) {
                toast.error("Your payment is successful but unable to update transaction history. Please contact us.");
            }
            else {
                toast.success("Payment Success\n Plan has been added.");
                setShowPurchasePlan(false);
                await loadData();
            }
        }
    }

    const handlePayment = async () => {
        const amount = selectedPlan.Price; // Example amount in INR

        const response = await razorpayCreateOrder(amount, selectedPlan);

        if (!response.success) {
            toast.error(response.message);
            return;
        }

        const { orderID, currency, amount: orderAmount, keyID } = response.data;

        const options = {
            key: keyID, // Replace with your Razorpay key_id
            amount: orderAmount,
            currency: currency,
            name: 'AutomateB',
            description: 'Test Transaction',
            order_id: orderID,
            handler: confirmPayment,
            modal: {
                ondismiss: async function () {
                    // This function will be called when the user cancels the payment
                    await updateTransactionHistory(orderID, DIGITALSIGN_TRANSACTION_STATUS.Cancelled);
                    toast.error("Payment Canceled");
                }
            },
            prefill: {
                name: userData.name,
                email: userData.Email,
                contact: userData.PhoneNo
            },
            notes: {
                address: 'Razorpay Corporate Office'
            },
            theme: {
                color: 'orange'
            }
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
    };

    const downloadInvoice = async (trHistory) => {
        let res = await API.paymentInvoice(trHistory)
        if (res.success) {
            window.open(FILEURL.BASEURL + "downloadReport/" + res.data);
            toast.success(`${lang.successfully_download_success_message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
    };
    

    return (
        <BossDialog            
            onClose={onClose}
            showHeader={false}
            style={{ background: "transparent" }}
            width={showTransactionHistory ? "800px" : showPurchasePlan ? "500px" : "600px"}
            // height={"500px"}
            height={loading ? "350px" : showPurchasePlan ? "auto" : "510px"}

        >
            {
                loading ?
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }
                    } >
                        <i className={"fa fa-spinner fa-spin fa-3x"} />
                    </div >
                    : showPurchasePlan ?
                        <div>
                            <h1 style={{ fontWeight: "bold", fontSize: "32px" }}>Choose Plan</h1>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div className='col-9 pl-0'>
                                    <DropDownList
                                        data={allPlans}
                                        value={selectedPlan}
                                        dataItemKey="_id"
                                        textField="Name"
                                        onChange={(e) => {
                                            setSelectedPlan(e.value);
                                        }}
                                        style={{
                                            backgroundColor: "white", fontWeight: "bold", height: "40px", borderRadius: "10px"
                                        }}
                                    />
                                </div>
                                <div>
                                    <span style={{ fontSize: "32px", fontWeight: "bold", display: "block", textAlign: "center" }}> {selectedPlan?.screens}</span>
                                    <span className='ml-2' style={{ fontWeight: "bold", paddingRight: "5px", fontSize: "12px", color: "grey" }}>Screens</span>
                                </div>
                            </div>
                            <div className='mt-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    <span style={{ fontSize: "32px", fontWeight: "bold", textAlign: "center" }}> {selectedPlan?.storage}</span>
                                    <span className='ml-1' style={{ fontWeight: "bold", paddingRight: "5px", fontSize: "12px", color: "grey", verticalAlign: "bottom", position: "relative", bottom: "7px" }}>mb</span>
                                </div>
                                <div>
                                    <span className='ml-1' style={{ fontWeight: "bold", fontSize: "14px", color: "grey", verticalAlign: "bottom", position: "relative", bottom: "4px" }}>{userData?.Company?.SignUpData?.currency ?? "INR"}</span>
                                    <span style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}> {selectedPlan?.Price}</span>
                                </div>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-12 d-flex justify-content-end'>
                                    <CancelButton onClick={() => {
                                        setShowPurchasePlan(false);
                                    }} />
                                    <SaveButton
                                        title="Continue to Payment"
                                        tooltip="Continue to Payment"
                                        onClick={() => {
                                            handlePayment();
                                        }} />
                                </div>
                            </div>

                        </div>
                        : showTransactionHistory ?
                            <div style={{ position: "relative", overflow: "hidden", height: "100%" }}>
                                <h1 style={{ fontWeight: "bold", fontSize: "32px" }}>Transaction History</h1>
                                <div className='mt-4' style={{ height: "392px", overflowY: "auto", overflowX: "hidden" }}>
                                    <table className="table border solid 2px">
                                        <thead className='border solid 2px'>
                                            <tr>
                                                <th style={{width: "30px"}}></th>
                                                <th scope="col" style={{width: "105px"}}>Date</th>
                                                <th scope="col">Order ID</th>
                                                <th scope="col">Plan</th>
                                                <th scope="col">Screens</th>
                                                <th scope="col">Storage</th>
                                                <th scope="col">Tenure(Days)</th>
                                                <th scope="col">Transaction Status</th>
                                                <th scope="col">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody className='border solid 2px'>
                                            {transactionHistoryData.map(trHistory => <tr key={trHistory._id}>
                                                <td>{trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Confirmed && <i className='fa fa-download cursor-pointer' onClick={() => downloadInvoice(trHistory)}></i>}</td>
                                                <td style={{width: "30px"}}>{moment(trHistory.orderCreatedOn).format("ll")}</td>
                                                <td>{trHistory.orderID.replace("order_", "")}</td>
                                                <td>{trHistory.plan.Name}</td>
                                                <td>{trHistory.plan.screens}</td>
                                                <td>{trHistory.plan.storage}</td>
                                                <td>{trHistory.plan.Tenure}</td>
                                                <td style={{ textAlign: "center", color: "white" }}><div style={{ backgroundColor: trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Cancelled ? "#f4511e" : trHistory.status == DIGITALSIGN_TRANSACTION_STATUS.Pending ? "#deb633" : "#03a782", display: "inline-block", padding: "2px 8px", borderRadius: "5px", borderColor: "white", fontSize: "12px" }}>{Object.keys(DIGITALSIGN_TRANSACTION_STATUS)[Object.values(DIGITALSIGN_TRANSACTION_STATUS).indexOf(trHistory.status)]}</div></td>
                                                <td>{trHistory.currency + " " + trHistory.amount}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ position: "absolute", bottom: 0, right: 0}}>
                                    <SaveButton tooltip="OK" title="OK" onClick={() => { setShowTransactionHistory(false) }} style={{ borderRadius: "5px", borderColor: "white" }} />
                                </div>
                            </div>
                            : <div style={{ position: "relative", overflow: "hidden", height: "100%" }}>
                                <h1 style={{ fontWeight: "bold", fontSize: "32px" }}>My Plan</h1>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div>
                                        <span style={{ fontWeight: "bold", paddingTop: "5px", fontSize: "18px", display: "block" }}>{utility.convertFirstLetterToUpperCase(userData?.name ?? "")}</span>
                                        <span style={{ fontWeight: "bold", paddingTop: 0, marginTop: 0, fontSize: "14px", color: "grey", display: "block" }}>{utility.convertFirstLetterToUpperCase(userData?.Company.Name ?? "")}</span>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <span style={{ fontSize: "32px", fontWeight: "bold", display: "block", textAlign: "center" }} className='text-success'> {parseInt(myPlans.reduce((acc, plan) => acc + plan.plan.screens, 0)) - parseInt(myPlans.reduce((acc, plan) => acc + plan.balanceScreens, 0))}</span>
                                            <span className='ml-2' style={{ fontWeight: "bold", paddingRight: "5px", fontSize: "12px", color: "grey" }}>Active  Screens</span>
                                        </div>
                                        <div>
                                            <span style={{ fontSize: "32px", fontWeight: "bold", display: "block", textAlign: "center" }}> {myPlans.reduce((acc, plan) => acc + plan.balanceScreens, 0)}</span>
                                            <span className='ml-2' style={{ fontWeight: "bold", paddingRight: "5px", fontSize: "12px", color: "grey" }}>Balance  Screens</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-4' style={{ height: "280px", overflowY: "auto", overflowX: "hidden" }}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Plan</th>
                                                <th scope="col">Total Screens</th>
                                                <th scope="col">Screens Left</th>
                                                <th scope="col">Storage</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Tenure</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {myPlans.map(plan => <tr key={plan._id}>
                                                <td>{plan.plan.Name}</td>
                                                <td>{plan.plan.screens}</td>
                                                <td>{plan.balanceScreens}</td>
                                                <td>{plan.plan.storage}MB</td>
                                                <td className={`${plan.balanceScreens > 0 ? "text-success" : "text-danger"}`}>{plan.balanceScreens > 0 ? "Active" : "Inactive"}</td>
                                                <td>{userData?.Company?.SignUpData?.currency ?? 'INR' + " " + plan?.plan?.Price}</td>
                                                <td>{plan.plan.Tenure}</td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                </div>
                                <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                                    <CancelButton onClick={onClose} style={{ borderColor: "white", marginLeft: 0, }} />
                                    <SaveButton tooltip="Transaction History" title="Transaction History" onClick={showTransactionHistoryPopup} style={{ borderRadius: "5px", backgroundColor: "#03a782", borderColor: "white" }} />
                                    <SaveButton tooltip="Purchase Plan" title="Purchase Plan" onClick={() => {
                                        setShowPurchasePlan(true);
                                    }} style={{ borderRadius: "5px", borderColor: "white" }} />
                                </div>
                            </div>}
        </BossDialog>
    );
}


export default MyPlansPopup;
