/* eslint-disable */
import React, { useState } from 'react'
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api"
import { ENTITYNAME } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import BossDialog from '../../components/BossDialog';

const LanguageEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        ISOCode: props.item.ISOCode ?? "",
        GoogleApiCode: props.item.GoogleApiCode ?? "",
        Archive: props.item.Archive ?? false,
        IsDefault: props.item.IsDefault ?? false
    }

    const [dataItem, setDataItem] = useState(blankDataItem);

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (dataItem.Description == "" || dataItem.Description == undefined) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                _id: dataItem._id,
                SID: dataItem.SID ?? 0,
                Description: dataItem.Description,
                ISOCode: dataItem.ISOCode,
                GoogleApiCode: dataItem.GoogleApiCode,
                Archive: dataItem.Archive ?? false,
                IsDefault: dataItem.IsDefault ?? false,
                checkDuplicate: true,
                query: [["Description", "=", dataItem.Description]]

            }
            var res = await API.saveData(ENTITYNAME.Language, saveData);
            console.log(saveData);
            if (res.success) {
                props.refresh();
                props.cancelEdit();
                utility.deleteLocalStorageItem(ENTITYNAME.Language);
                return;
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    return (
        <>
            <BossDialog
                title={props.item.Description ?? `${lang.create_language_dialog_header}`}
                onClose={props.cancelEdit}
                width={"600px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                <Form
                    initialValues={dataItem}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label>{lang.description_label} *</label>
                                            <input
                                                name={"Description"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                onChange={onChange}
                                                value={dataItem.Description}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>{lang.iso_code_label}</label>
                                            <input
                                                name={"ISOCode"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                onChange={onChange}
                                                value={dataItem.ISOCode}
                                            />
                                            <p style={{ marginBottom: '-8px' }}>{lang.note_label}: <a target="_blank" href={'https://www.loc.gov/standards/iso639-2/php/code_list.php'}>{lang.click_here_to_check_ISO_code_error_message} </a></p>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label>{lang.google_api_code_label}</label>
                                            <input
                                                name={"GoogleApiCode"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                onChange={onChange}
                                                value={dataItem.GoogleApiCode}
                                            />
                                        </div>
                                        <div className='col-3 mt-4'>
                                            <Field
                                                style={{border: "1px solid grey"}}
                                                name={"Archive"}
                                                component={Checkbox}
                                                label={lang.archive}
                                                onChange={onChange}
                                            />
                                        </div>
                                        <div className='col-3 mt-4'>
                                            <Field
                                                style={{border: "1px solid grey"}}
                                                name={"IsDefault"}
                                                component={Checkbox}
                                                label={lang.is_default_label}
                                                onChange={onChange}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </BossDialog>
        </>
    )
}

export default LanguageEditForm;