/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME } from "../../framework/constant/constant";
import { useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";

const SecondaryEventDynamicRule = (props) => {

  const lang = useTranslation();
  const [dataItem, setDataItem] = useState({
    ...props.item,
    Name: props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
    Formula: props.item.copy ? "Copy of " + props.item.Formula : props.item.Formula ?? "",
    Archive: props.item.Archive ?? false
  });
  const { SID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (SID > 0) {
      loadEditData();
    }
  }, []);

  const loadEditData = async () => {
    let res = await API.getEntity(ENTITYNAME.SecondaryEventDynamicRule, parseInt(SID));
    setDataItem(res.data);
    console.Consolelog(res.data);
  }
  const onChange = (e) => {

    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  function isValid() {
    if (dataItem.Name == '' || dataItem.Name == undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Formula== '' || dataItem.Formula == undefined) {
      toast.error(`${lang.please_enter_formula_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const onSave = async () => {

    if(isValid()){

      const saveData = {
        SID : dataItem.SID,
        Name: dataItem.Name,
        Formula: dataItem.Formula,
        checkDuplicate: true,
        query: [["Name", "=", dataItem.Name]]
      };
      console.log(saveData);
      delete dataItem.copy;
      let res = await API.saveData(ENTITYNAME.SecondaryEventDynamicRule, saveData);
      console.log(res);
      if (!res.success) {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      setDataItem({});
      props.refresh();
      props.cancelEdit();
    }
  };

  return (<>
    {<BossDialog
      title={(props.item.SID > 0 ? dataItem.Name : `${lang.create_secondary_event_dynamic_rule_dialog_header}`)}
      onClose={props.cancelEdit}
      width={"600px"}
      height={"230px"}
    >
      <EditPageHeader title={""} disableSave={false} onSubmit={onSave} onCancel={props.cancelEdit} showTitle={false} />

      <div className="row">
        <div className="col-12">
          <div className="row mt-2">
            <div className="col-6 form-group" >
              <label htmlFor="">{lang.name_label} *</label>
              <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name} onChange={onChange} required />
            </div>
            <div className="col-6 form-group">
              <label htmlFor="">{lang.formula_label} *</label>
              <input type="text" className="form-control form-control-sm" name="Formula" value={dataItem.Formula} onChange={onChange} required />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <input name="Archive" type="checkbox" value={dataItem.Archive} checked={dataItem.Archive}
                onChange={onChange}
              />
              <label className="ml-1">{lang.archive}</label>
            </div>
          </div>
        </div>
      </div>
    </BossDialog>}
  </>)
}


export default SecondaryEventDynamicRule;