/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, MEDIA_TYPE } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import { Checkbox } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";

function ScheduleConstraintsEditForm(props) {

    const { SID } = useParams();
    const loc = useLocation();

    const [channel, setChannel] = useState([]);
    const navigate = useNavigate();
    const lang = useTranslation();
    const [enabledConstraints,_setEnableConstraints] = useState([]);
    const enabledConstraintsRef = useRef();
    const setEnabledConstraintsRef = (data) => {
        _setEnableConstraints(data);
        enabledConstraintsRef.current = data;
    }

    let blankDataItem = {
        SID: SID,
        Name: "",
        Channel: {},
        Type: MEDIA_TYPE[0],
        EnabledConstraints: [],
        Archive: false
    }

    const [dataItem, setDataItem] = useState(blankDataItem)

    useEffect(() => {
        pageRender();
    }, []);

    const pageRender = async () => {
        await loadcombo()
        if (SID > 0) {
            await loadEditData();
        }
    }

    const loadcombo = async () => {
        let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
        let getConstraintsData = await API.getDataLookup(ENTITYNAME.Constraints);
        setEnabledConstraintsRef(getConstraintsData.data);
        setDataItem({...blankDataItem,EnabledConstraints : getConstraintsData.data})
    }

    const loadEditData = async () => {
        let res = await API.getEntity(ENTITYNAME.ScheduleConstraints, parseInt(SID));
        enabledConstraintsRef?.current?.filter((item0 => {
            res.data?.EnabledConstraints?.filter((item1 => {
                if (item0.SID == item1.SID) {
                    item0.isSelected = true;
                }
            }))
        }))
        if (res.success) {
            setDataItem({
                ...res.data,
                Name: res.data.SID ? loc.state?.copy ? 'Copy of ' + res.data.Name : res.data.Name : res.data.Name ,
                EnabledConstraints: enabledConstraintsRef.current
            })
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }


    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        }
        else if (e.target.name == 'SelectAll') {
            setDataItem({
                ...dataItem, [e.target.name]: e.target.value, 
                EnabledConstraints: dataItem.EnabledConstraints.map(item => {
                    if (item.Type.ID == dataItem.Type.ID) return { ...item, isSelected: e.target.value }
                    else { return { ...item, isSelected: false } }
                })
            });
            setEnabledConstraintsRef(dataItem.EnabledConstraints.map(item => {
                if (item.Type.ID == dataItem.Type.ID) return { ...item, isSelected: e.target.value }
                else { return { ...item, isSelected: false } }
            }))
        }
        else if (e.target.name == "Type") {
            setDataItem({
                ...dataItem, [e.target.name]: e.target.value, SelectAll: false, EnabledConstraints: dataItem.EnabledConstraints.map(item => {
                    return { ...item, isSelected: false }
                })
            })
        }

        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const onChangeEnabledConstraints = (e) => {

        let temp = dataItem.EnabledConstraints.map(item => {
            if (item.Type.ID == dataItem.Type.ID && e.target.name == item.Name) return { ...item, isSelected: e.target.value }
            else {
                return { ...item }
            }
        })
        let tempSelected = []
        let tempUnSelected = []
        temp.map((item) => {
            if (item.Type.ID == dataItem.Type.ID && item.isSelected) {
                tempSelected.push(item)
            } else {
                tempUnSelected.push(item)
            }
        });

        if (tempSelected.length == dataItem.EnabledConstraints.filter((item) => item.Type.ID == dataItem.Type.ID).length) {
            setDataItem({ ...dataItem, SelectAll: true, EnabledConstraints: temp });
        } else {
            setDataItem({ ...dataItem, SelectAll: false, EnabledConstraints: temp });
        }
        setEnabledConstraintsRef(temp)
    }

    const isValid = () => {
        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (Object.keys(dataItem.Type).length == 0) {
            toast.error(`${lang.please_select_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        return true
    }

    const onSave = async () => {
console.log(dataItem)
        if (isValid()) {
            const saveData = {
                SID : loc.state?.copy ? 0 : dataItem.SID,
                Name: dataItem.Name,
                Channel:{_id: dataItem.Channel._id, SID:dataItem.Channel.SID, FullChannelName:dataItem.Channel.FullChannelName } ,
                Type: { ID:dataItem.Type.ID, Type:dataItem.Type.Type },
                EnabledConstraints: dataItem.EnabledConstraints.filter(item => item.Type.ID == dataItem.Type.ID && item.isSelected),
                Archive: dataItem.Archive ?? false,
                SelectAll:dataItem.SelectAll ?? false,
                checkDuplicate: true,
                query: [["Channel", "=", dataItem.Channel], ["Type", "=", dataItem.Type]]
            }
            if(loc.state?.copy){
                delete saveData._id;
            }

            console.log(saveData)
            let res = await API.saveData(ENTITYNAME.ScheduleConstraints, saveData);
            console.log(res.data);
            if (res.success) {
                navigate(-1);
            } else {
                toast.error(res.message + ' ' + dataItem.Channel.FullChannelName + ',' + dataItem.Type.Type, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const borderWithTitle = {
        position: "relative",
        fontWeight: "500",
        fontSize: "18px",
        top: "-0.8em",
        marginLeft: "0.2em",
        display: "inline",
        backgroundColor: "#f7f5f5",
    };


    return (<>
        <div className="row m-1">
            <div className="col-12">
                <EditPageHeader title={dataItem.Name ? `${lang.schedule_constraints_error_message} -> ${dataItem.Name}` : `${lang.create_schedule_constraints_editpage_header}`} onSubmit={onSave} onCancel={() => navigate(-1)} />
                <div className="row mt-2">
                    <div className="col-4">
                        <label>{lang.name_label} *</label>
                        <input
                            className="form-control form-control-sm"
                            type="text"
                            name={"Name"}
                            onChange={onChange}
                            value={dataItem.Name}
                        />
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.channel_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={channel}
                                name="Channel"
                                textField="FullChannelName"
                                dataItemKey="_id"
                                value={dataItem.Channel}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.type_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={MEDIA_TYPE}
                                name="Type"
                                textField="Type"
                                dataItemKey="ID"
                                value={dataItem.Type}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 form-group">
                        <input type={"checkbox"} name={"Archive"}
                            value={dataItem.Archive}
                            checked={dataItem.Archive}
                            onChange={onChange} />
                        <label className='ml-1'>{lang.archive}</label>
                    </div>
                </div>
                <div className="row" style={{ margin: "8px 0px 15px 0px", border: '1px solid LightSeaGreen', borderRadius: '5px!important' }}>
                    <div className='col-12'>
                        <div style={borderWithTitle}>&nbsp; {lang.constraints_lable} &nbsp;</div>
                        <div className="row mt-2">
                            <div className="col-3">
                                <Checkbox name={"SelectAll"} label={lang.select_all_label} value={dataItem.SelectAll} onChange={onChange} />
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-12">
                                <div className="row mt-3">
                                    <div className="col-4">
                                        <label style={{ fontSize: '20px', fontWeight: '500' }}>{lang.name_label}</label>
                                    </div>
                                    <div className="col">
                                        <label style={{ fontSize: '20px', fontWeight: '500' }}>{lang.description_label}</label>
                                    </div>
                                </div>
                                {enabledConstraints.map((item) => {
                                    return item.Type.ID == dataItem.Type.ID && <div className="row mt-2">

                                        <div className="col-4">
                                            <Checkbox
                                                name={item.Name}
                                                label={item.Name}
                                                onChange={onChangeEnabledConstraints}
                                                value={item.isSelected}
                                            />
                                        </div>
                                        <div className="col">
                                            {item.Description}
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default ScheduleConstraintsEditForm;