/* eslint-disable */
import { useEffect, useRef } from "react";
import ActionButton from "../framework/forms/helpercomponents/buttons/ActionButton"
import CancelButton from "../framework/forms/helpercomponents/buttons/CancelButton";
import SaveButton from "../framework/forms/helpercomponents/buttons/SaveButton";
import { useTranslation } from "../locale/useTranslation";
import { utility } from "../framework/utility/utilityProvider";


export const EditPageHeader = (props) => {

  const lang = useTranslation();

  let showTitle = props.showTitle ?? true;
  let saveButtonTitle = props.saveButtonTitle ?? lang.save_button_text
  let showSpots = props.showSpots ?? false;
  let showSaveAndCreateWorkOrder = props.showSaveAndCreateWorkOrder ?? false;
  let showDraft = props.showDraft ?? false;
  let showPreview = props.showPreview ?? false;
  let showStatusCell = props.showStatusCell ?? false;
  let status = props.status ?? '';
  let statusCellColor = props.statusCellColor ?? '#ffffff';
  let showMediaTemplate = props.showMediaTemplate ?? false;

  const handleKeyDown = (event) => {
    if (event.key == "Escape") {
      props.onCancel();
    } else if (event.keyCode == 83 && event.ctrlKey) {
      props.onSubmit();
    } else {
      // console.log(event.key);
    }
  }


  useEffect(() => {
    //subscribe event
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      // unsubscribe event
      window.removeEventListener('keydown', handleKeyDown)
    };
  }, [])

  const onSubmit = () => {
    props.onSubmit();
  }

  const onCancel = () => {
    props.onCancel();
  }

  return <div className="flex-container wrap">
    <div className="row mt-1 mb-1" style={{ marginLeft: '-25px' }}>
      <div className="col">
        <SaveButton tooltip={saveButtonTitle} title={saveButtonTitle} btnColor={props.saveBtnColor} onClick={onSubmit}></SaveButton>
        {showDraft && <ActionButton title={lang.save_draft_button_tooltip} name={lang.save_draft_button_title} btnColor={'info'} onClick={props.onSaveDraft} />}
        {showSpots && <ActionButton title={lang.show_spots_button_tooltip} name={lang.show_spots_button_text} btnColor={'warning'} onClick={props.onShowSpots} />}
        {showSaveAndCreateWorkOrder && <ActionButton title={lang.save_and_create_work_order_button_tooltip} name={lang.save_and_create_work_order_button_text} btnColor={'info'} onClick={props.onSaveAndCreateWorkOrder} />}
        {showPreview && <ActionButton title={lang.preview_button_text} name={lang.preview_button_tooltip} btnColor={'info'} onClick={props.onPreview} />}
        <CancelButton onClick={onCancel}></CancelButton>
        {showMediaTemplate && <ActionButton title={lang.save_and_create_media_template_button_tooltip} name={lang.save_as_media_template_button_text} btnColor={'info'} onClick={props.onSaveAndCreateMediaTemplate} />}
        {showMediaTemplate && <ActionButton title={lang.select_media_template_button_tooltip} name="..." btnColor={'info'} onClick={props.onSelectMediaTemplate} />}
        
       </div>
    </div>
    <div className="mr-2" style={{ marginTop: '-10px' }}>

      {showTitle && <h1 className="page-edit-title txt-color-bludeDark">
        <i
          className="fa fa-pencil marlr"
          style={{ fontSize: "15px", color: "GrayText" }}
        ></i>
        <span>{utility.subString(props?.title ?? "", 30)}</span>
      </h1>}
      {showStatusCell && status.length > 0 &&
      <h1 style={{ margin : '10px 0px 0px 0px',padding : '10px 0px 0px 0px'}}>
        <span title={status}
        style={{ 
            fontSize : '13px',
            fontWeight : 'normal',
            color : 'white',
            letterSpacing : "1px",
            backgroundColor: statusCellColor,
            padding : '0px 10px',
            border : `1px solid ${statusCellColor}`,
            borderRadius : '5px'
        }}>{status}</span>
      </h1>}
    </div>

  </div>
}