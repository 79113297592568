/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import * as API from "../../framework/API/api";
import { ENTITYNAME } from "../../framework/constant/constant";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import BossDialog from "../../components/BossDialog";

export const GenreEditForm = (props) => {
  const lang = useTranslation();

  const blankDataItem = {
    SID: props.item.SID ?? 0,
    Description: props.item.copy
      ? "copy of " + props.item.Description
      : props.item.Description ?? "",
    Code: props.item.Code ?? "",
    MediaCategory: props.item.MediaCategory ?? {},
    Archive: props.item.Archive ?? false,
    IsDefault: props.item.IsDefault ?? false,
  };
  console.log(props.item);

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [mediaCategory, setmediaCategory] = useState([]);

  useEffect(() => {
    loadcombo();
  }, []);

  const loadcombo = async () => {
    let mediaCategoryRes = await API.getDataLookup(
      ENTITYNAME.MediaCategory
    );
    setmediaCategory(mediaCategoryRes.data);
  };

  //function to check the validation in form
  function validationCheck() {
    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (dataItem.Code == "" || dataItem.Code == undefined) {
      toast.error(`${lang.please_enter_code}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    if (validationCheck()) {
      const saveData = {
        SID: dataItem.SID ?? 0,
        Description: dataItem.Description,
        Code: dataItem.Code,
        MediaCategory: {
          _id: dataItem.MediaCategory._id,
          SID: dataItem.MediaCategory.SID,
          Description: dataItem.MediaCategory.Description,
        },
        Archive: dataItem.Archive ?? false,
        IsDefault: dataItem.IsDefault ?? false,
        checkDuplicate: true,
        query: [["Description", "=", dataItem.Description]],
      };

      let res = await API.saveData(ENTITYNAME.Genre, saveData);
      if (res.success) {
        utility.deleteLocalStorageItem(ENTITYNAME.Genre);
        props.cancelEdit();
        props.refresh();
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const onChangeForm = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else if (e.target.name == "IsDefault") {
      setDataItem({ ...dataItem, IsDefault: !dataItem.IsDefault });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  return (
    <BossDialog
      title={props.item.SID > 0 ? props.item.Description : "Create Genre"}
      onClose={props.cancelEdit}
      width={"770px"}
    >
      <EditPageHeader
        title={""}
        onSubmit={handleSubmit}
        onCancel={props.cancelEdit}
        showTitle={false}
      />
      <Form
        initialValues={dataItem}
        render={(formRenderProps) => (
          <FormElement>
            <div className="row mt-2">
              <div className="col-12">
                <div className="row mt-2">
                  <div className="col-6">
                    <label>{lang.description_label} *</label>
                    <input
                      name={"Description"}
                      type="text"
                      style={{ border: "solid lightgrey 1px", height: "35px" }}
                      onChange={onChangeForm}
                      value={dataItem.Description}
                    />
                  </div>
                  <div className="col-6">
                    <label>{lang.code_label} *</label>
                    <input
                      name={"Code"}
                      type="text"
                      style={{ border: "solid lightgrey 1px", height: "35px" }}
                      value={dataItem.Code}
                      onChange={onChangeForm}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-6">
                    <label>{lang.media_category_label}</label>
                    <DropDownList
                      style={{ backgroundColor: 'white' }}
                      data={mediaCategory}
                      name="MediaCategory"
                      textField="Description"
                      dataItemKey="_id"
                      value={dataItem.MediaCategory}
                      onChange={onChangeForm}
                    />
                  </div>
                </div>
                <div className="row mt-2 mb-1">
                  <div className="col-2">
                    <input
                      type={"checkbox"}
                      name={"Archive"}
                      style={{ marginTop: "10px" }}
                      onChange={(e) => onChangeForm(e)}
                      value={dataItem.Archive}
                      checked={dataItem.Archive}
                    />
                    <label className="ml-1">{lang.archive}</label>
                  </div>
                  <div className="col-2">
                    <input
                      type={"checkbox"}
                      name={"IsDefault"}
                      style={{ marginTop: "10px" }}
                      onChange={(e) => onChangeForm(e)}
                      value={dataItem.IsDefault}
                      checked={dataItem.IsDefault}
                    />
                    <label className="ml-1">{lang.is_default_label}</label>
                  </div>
                </div>
              </div>
            </div>
          </FormElement>
        )}
      />
    </BossDialog>
  );
};
