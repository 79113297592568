/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";

export const WorkflowEditForm = (props) =>  {
    const lang = useTranslation();
    const [dataItem, setDataItem] = useState({ 
        SID : props.item.SID,
        Name:  props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Description: props.item.Description??'',
        StartingRole : props.item.StartingRole ?? {},
        Roles: props.item.Roles??[],
        Archive : props.item.Archive ?? false,
    });
    const [role, setRole] = useState([]);

    useEffect(() => {
        loadcombo();
    },[]);

    const isValid = () => {
      if(dataItem.Name=="" || dataItem.Name==undefined){
        toast.error(`${lang.please_enter_name_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if(dataItem.Description=="" || dataItem.Description==undefined){
        toast.error(`${lang.please_enter_description_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if(Object.keys(dataItem.StartingRole).length==0){    
        toast.error(`${lang.please_select_starting_role_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });                        
        return false;
      }
      if(dataItem.Roles.length==0){    
        toast.error(`${lang.please_select_atleast_one_role_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });                        
        return false;
      }
      return true;
    }
    
    const loadcombo = async () => {
        var roles = await API.getDataLookup(ENTITYNAME.Role, {sort: { Description: 1 }});
        setRole(roles.data);
    }

    const onchange = (e) => {
      if(e.target.name == "Archive"){
        setDataItem({ ...dataItem, Archive : !dataItem.Archive });
      }else{
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
      }
    }

    const handleSubmit = async () => {

      if(isValid()){ 

        var localData = {...dataItem,
            SID : dataItem.SID,
            Archive : dataItem.Archive ?? false,
            checkDuplicate:true,
            query:[["Name", "=", dataItem.Name]]
        }

        var res = await API.saveData(ENTITYNAME.Workflow, localData);
        if(res.success){
            props.cancelEdit();
            props.refresh();
            utility.deleteLocalStorageItem(ENTITYNAME.Workflow);
            return;
        }else{
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        }
    }

  return (
    <>
        {<BossDialog
            title={props.item.SID > 0 ? props.item.Name : `${lang.workflow_dialog_header}`}
            onClose={props.cancelEdit}
            width={"60%"}
        >
          <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false}/>
            <Form
            render={(formRenderProps) => (
                <FormElement>
                    <div className="row mt-3">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 form-group">
                                <label htmlFor="TabView">{lang.name_label} *</label>
                                <input className="pl-2" type = "text" style={{border : 'solid lightgrey 1px' , height : "35px"}} name= {"Name"} onChange={onchange} value={dataItem.Name}/>
                            </div> 
                            
                            <div className="col-12 form-group">
                                <label htmlFor="TabView">{lang.description_label} *</label>
                                <input className="pl-2" type = "text" style={{border : 'solid lightgrey 1px' , height : "35px"}} name= {"Description"} onChange={onchange} value={dataItem.Description}/>
                            </div>

                            <div className="col-6 form-group">
                                <label htmlFor="TabView">{lang.starting_role_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor:"white" }}
                                    data={role}
                                    name="StartingRole"
                                    textField="Name"
                                    dataItemKey="_id"
                                    value={dataItem.StartingRole}
                                    onChange={onchange}
                                />                  
                            </div>

                            <div className="col-6 form-group">
                                <label htmlFor="TabView">{lang.roles_label} *</label>
                                <MultiSelect
                                    style={{ backgroundColor:"white" }}
                                    data={role}
                                    name="Roles"
                                    textField="Name"
                                    dataItemKey="_id"
                                    value={dataItem.Roles}
                                    onChange={onchange}
                                />                  
                            </div>

                            <div className="col-3  form-group">
                                <input type = "checkbox" name= {"Archive"} value={dataItem.Archive} checked= {dataItem.Archive} onChange={onchange}/>  
                                <label className="pl-2" htmlFor="TabView">{lang.archive}</label> 
                            </div> 
                        </div>
                    </div>
                    </div>
                </FormElement>
            )} />
        </BossDialog>}
    </>
  )
}