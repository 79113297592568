/* eslint-disable */
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { memo, useEffect, useState } from "react";
import * as API from '../../../framework/API/api'
import { ENTITYNAME } from "../../../framework/constant/constant";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import { useTranslation } from "../../../locale/useTranslation";
import PlanningFilter from "../../Planning/PlanningFilter";
import { ScheduleFilter } from "../../Scheduling/SchedulingFilter";
import { toast } from "react-toastify";
import { utility } from "../../../framework/utility/utilityProvider";

const ManageContentHeader = (props) => {

    const lang = useTranslation();

    const textStyle = { padding: "0 5px", display: "inline-block", fontSize: "13px", fontWeight: '400', marginBottom: "0px" }
    const buttonStyle = { padding: "0 5px", display: "inline-block", backgroundColor: "white", height: '30px', width: "150px" }

    const [mediaCategories, setMediaCategories] = useState([]);
    const [mediaCategory, setMediaCategory] = useState({});
    const [showFilterDropdown, setShowFilterDropDown] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [templates, setTemplates] = useState([]);
    const [filterPopupOpen, setFilterPopupOpen] = useState(false);
    const [showTemplateDropDown, setShowTemplateDropDown] = useState(false);
    const [isFilterCleared, setIsFilterCleared] = useState(false);
    const [planningFilterTemplate, setPlanningFilterTemplate] = useState([]);
    const [schedulingFilterTemplate, setSchedulingFilterTemplate] = useState([]);

    useEffect(() => {
        loadCombo(); 
    }, [])

    const loadCombo = async () => {

        let mediaCategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { SID: 1 } });
        if (mediaCategoryRes.success && mediaCategoryRes.data.length > 0) {
            setMediaCategories(mediaCategoryRes.data);
            setMediaCategory(mediaCategoryRes.data[0]);
            // filter DropDown
            let planningFilterTemplateRes = await API.getDataLookup(ENTITYNAME.PlanningFilterTemplate, { sort: { Name: 1 } });
            setPlanningFilterTemplate(planningFilterTemplateRes.data);
            let schedulingFilterTemplateRes = await API.getDataLookup(ENTITYNAME.SchedulingFilterTemplate, { sort: { Name: 1 } });
            setSchedulingFilterTemplate(schedulingFilterTemplateRes.data);

            let temp = mediaCategoryRes.data[0].isLong ? planningFilterTemplateRes.data : schedulingFilterTemplateRes.data;
            let filteredTemplate = temp.filter((x) => x.FilterModel.MediaCategory.some((y) => y.SID == mediaCategoryRes.data[0].SID))
            setTemplates(filteredTemplate);
        } else {
            setMediaCategories([]);
            setMediaCategory({});
            setTemplates([]);
        }
    }

    const onLoad = () => {
        props.onLoad(mediaCategory);
    }

    const onChange = async (e) => {
        setMediaCategory(e.target.value);
        let temp = e.target.value?.isLong ? planningFilterTemplate : schedulingFilterTemplate;
        let filteredTemplate = temp.filter((x) => x.FilterModel.MediaCategory.some((y) => y.SID == e.target.value?.SID))
        setTemplates(filteredTemplate);
        setSelectedTemplate({});
    }

    const onExport = () => {
        // props.setShowExport({ show: true, filterData: { ...filterData } });
    }

    const onEditFilterTemplate = () => {
        setFilterPopupOpen(true);
    }

    const onShowFilterTemplate = () => {
        setSelectedTemplate({});
        setShowFilterDropDown(!showFilterDropdown);
        showFilterDropdown && props.setFilterTemplate([]);
    }

    const onFilterTemplateChange = (e) => {
        setSelectedTemplate(e.target.value);
        if (mediaCategory.isLong) {
            toGetFilteredData(e.target.value.FilterModel);
        } else {
            toGetFilteredDataShortMedia(e.target.value.FilterModel);
        }
    }

    const toGetFilteredData = (data) => {
        console.log(data);
        let finalFilterQuery = [];
        if (Object.keys(data).length != 0) {
            let genresFilter = data.Genres.length != 0 ? [['Genres._id', 'in', data.Genres.map((item) => item._id)]] : [];
            let isLiveFilter = data.isLive ? ["IsLive", '=', true] : [];
            let releaseYearFilter = data.ReleaseYear != "" ? [["ReleaseDate", ">=", new Date(`${data.ReleaseYear}-01-01`).getTime()], ["ReleaseDate", "<=", new Date(`${data.ReleaseYear}-12-31`).getTime()]] : [];
            let channelFilter = data.Channel.length != 0 ? [['Publishings.Channel.SID', 'in', data.Channel.map((item) => item.SID)]] : [];
            let castAndCrewFilter = data.CastName.length > 0 && Object.keys(data.CastType).length > 0 ? [['Metadata.CastAndCrew', 'elemMatch', { Name: data.CastName, CastType: data.CastType }]] : [];
            let createdFromFilter = data?.CreatedFrom ? [["addOn", ">=", utility.convertStringDatetoMilliseconds(data.CreatedFrom)]] : [];
            let createdToFilter = data?.CreatedTo ? [["addOn", "<=", utility.convertStringDatetoMilliseconds(data.CreatedTo)]] : [];
            let publishingStartDateFilter = data?.publishingStartDate ? [["Publishings.PublishStartDate", "<=", utility.convertStringDatetoMilliseconds(data.publishingStartDate)]] : [];
            let publishingEndDateFilter = data?.publishingEndDate ? [["Publishings.PublishEndDate", ">=", utility.convertStringDatetoMilliseconds(data.publishingEndDate)]] : [];
            let languagesFilter = data?.Languages && data?.Languages?.length > 0 ? [['MetaData.Languages._id', "in", data?.Languages?.map(x => x?._id)]] : [];
            let pgRatingsFilter = data?.PGRating && data?.PGRating?.length > 0 ? [['MetaData.PGRating._id', "in", data?.PGRating?.map(x => x?._id)]] : [];
            let contentCategoryFilter = data?.contentCategory && data?.contentCategory?.length > 0 ? [['MetaData.ContentCategory._id', "in", data?.contentCategory?.map(x => x?._id)]] : [];
            let CastAndCrews = data?.CastAndCrews && data?.CastAndCrews?.length > 0 ? [['CastAndCrews._id', "in", data?.CastAndCrews?.map(x => x?._id)]] : [];
            let platformsFilter = (data?.isNonLinear && data?.platforms && data?.platforms?.length > 0) ? [['Publishings.Platforms._id', "in", data?.platforms?.map(x => x?._id)]] : [];
            let regionsFilter = (data?.isNonLinear && data?.regions && data?.regions?.length > 0) ? [['Publishings.Regions._id', "in", data?.regions?.map(x => x?._id)]] : [];

            finalFilterQuery = [...genresFilter, ...releaseYearFilter, isLiveFilter, ...castAndCrewFilter, ...createdFromFilter, ...createdToFilter, ...channelFilter, ...publishingStartDateFilter, ...publishingEndDateFilter, ...languagesFilter, ...pgRatingsFilter, ...contentCategoryFilter, ...CastAndCrews, ...platformsFilter, ...regionsFilter];
        }
        console.log(finalFilterQuery);
        props?.setFilterTemplate(finalFilterQuery);
    }

    const onClickAdvancedFilter = (edit = false) => {
        if (edit && selectedTemplate && Object.keys(selectedTemplate).length == 0) {
            toast.error(lang.please_select_template_to_edit_error_message, { position: toast.POSITION.TOP_RIGHT });
            return;
        }
        setFilterPopupOpen(true);
        setShowTemplateDropDown(edit);
        setSelectedTemplate(edit ? selectedTemplate : {});
    }

    const onSearch = (e) => {
        const timeOut = setTimeout(() => {
            props?.setSearchText(e.target.value)
        }, 1200);
        return () => clearTimeout(timeOut);
    }

    const toGetFilteredDataShortMedia = (data) => {
        console.log(data);
        let finalFilterQuery = [];
        if (Object.keys(data).length != 0) {
            let mediaCategoryFilter = data.MediaCategory.length != 0 ? [["MediaCategory._id", 'in', data.MediaCategory.map((item) => item._id)]] : [];
            let mediaCategoryTypeFilter = data.MediaCategoryType.length != 0 ? [["MediaCategoryType._id", 'in', data.MediaCategoryType.map((item) => item._id)]] : [];
            let productFilter = data.Product.length != 0 ? [["Product._id", 'in', data.Product.map((item) => item._id)]] : [];
            let brandFilter = data.Brand.length != 0 ? [["Brand._id", 'in', data.Brand.map((item) => item._id)]] : [];
            let durationFilter = data.Duration.Condition > 0 ? ["Duration", `${data.Duration.Operator}`, data.Duration.Condition] : []
            let createdFromFilter = data?.CreatedFrom != "" ? [["addOn", ">=", utility.convertStringDatetoMilliseconds(data.CreatedFrom)]] : [];
            let createdToFilter = data?.CreatedTo != "" ? [["addOn", "<=", utility.convertStringDatetoMilliseconds(data.CreatedTo)]] : [];

            finalFilterQuery = [...mediaCategoryFilter, ...mediaCategoryTypeFilter, ...productFilter, ...brandFilter, durationFilter, ...createdFromFilter, ...createdToFilter];
        }
        props?.setFilterTemplate(finalFilterQuery);
    }

    return (
        <div style={{ display: "flex", alignItems: "center", backgroundColor: "#F4F5FA", border: "1px solid #ededed", overflow: 'auto', padding: '10px', height: "80px", overflowY: "hidden" }}>
            <div className="flex-container">
                <div style={{ marginRight: "15px" }}>
                    <h5 style={textStyle}>Media Category :</h5><br />
                    <DropDownList
                        style={buttonStyle}
                        data={mediaCategories}
                        dataItemKey="_id"
                        textField="Description"
                        value={mediaCategory}
                        onChange={onChange}
                        name="mediaCategory"
                    />
                </div>
                <RoundButton btnColor={'primary'} style={{ alignSelf: "end", margin: "16px 10px", color: "whitesmoke" }} icon={'eye'} title={'Load'} name={'Load'} onClick={onLoad} />
                <RoundButton btnColor={'warning'} style={{ alignSelf: "end", margin: "16px 0px", color: "whitesmoke" }} icon={'download'} title={'Export'} name={'Export'} onClick={onExport} />
                <div className="flex-container-reverse flex-item-auto mt-3">
                    {showFilterDropdown && <div className="input-group w-300 martb">
                        <DropDownList
                            style={{ width: selectedTemplate && Object.keys(selectedTemplate).length > 0 ? '200px' : '250px' }}
                            data={templates}
                            name="Template"
                            textField="Name"
                            dataItemKey="_id"
                            value={selectedTemplate}
                            onChange={onFilterTemplateChange}
                            title="Filter Template"
                        />
                        {mediaCategory?.isLong ?
                            <PlanningFilter
                                filterDataItem={selectedTemplate}
                                setSelectedTemplate={setSelectedTemplate}
                                toGetFilterDataItem={toGetFilteredData}
                                mediaCategory={mediaCategory?.SID}
                                disableMediaCategory={true}
                                showTemplate={false}
                                hideFields={false}
                                isManageContentFields={true}
                                onClear={() => { }}
                                showEditButton={true}
                            /> :
                            <>
                                {selectedTemplate && Object.keys(selectedTemplate).length > 0 && <button title="Edit Filter Template" style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={onEditFilterTemplate} aria-label="Edit Template">
                                    <div className="input-group-append" >
                                        <span className="input-group-text" style={{ backgroundColor: "white" }}>
                                            <i style={{ margin: "5px 0px", color: "black" }} className="fa fa-pencil" />
                                        </span>
                                    </div>
                                </button>}
                                <button title="Advanced Search" style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={() => onClickAdvancedFilter()} aria-label="Advanced Search">
                                    <div className="input-group-append" >
                                        <span className="input-group-text" style={isFilterCleared ? { backgroundColor: "white" } : { backgroundColor: "black" }}>
                                            <i style={{ margin: "5px 0px", color: isFilterCleared ? "black" : "white" }} className="fa fa-list" />
                                        </span>
                                    </div>
                                </button> 
                            </>}
                    </div>}
                    <div className="input-group w-300 martb" style={{ margin: '10px 5px 10px 0px' }}>
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button title={showFilterDropdown ? "Clear Filters" : "Filters"} style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "0px" }} onClick={onShowFilterTemplate} aria-label="Advanced Search">
                            <div className="input-group-append" >
                                <span className="input-group-text" style={!showFilterDropdown ? { backgroundColor: "white" } : { backgroundColor: "black" }}>
                                    <i style={{ margin: "4px 0px", color: !showFilterDropdown ? "black" : "white" }} className="fa fa-filter" />
                                </span>
                            </div>
                        </button>
                    </div>

                </div>
            </div>
            {filterPopupOpen && <ScheduleFilter
                data={selectedTemplate?.FilterModel}
                showTemplate={showTemplateDropDown}
                toGetFilterDataItem={toGetFilteredDataShortMedia}
                isFilterCleared={setIsFilterCleared}
                closeFilterPopup={() => { setFilterPopupOpen(false); }}
                height={"38px"}
                disableMediaCategory={true}
                onClear={() => { }}
                mediaCategory={mediaCategory?.SID}
                templateData={templates}
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate._id == 0 ? null : selectedTemplate}
            />}
        </div>
    );
}

export default memo(ManageContentHeader);